import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NavBar2 from '../../components/navigation/NavBar2';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Input from '@material-ui/core/Input';
import { styles } from './TemplatesPageStyles.js';
import SearchIcon from '@material-ui/icons/Search';
import Directions from '../../components/directions/Directions';

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

class Template extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 10,
      templates:[],
      searchValue: "",
      firstLoadAttempted: false

    };
  }

   changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`
    this.props.history.push(path);
 };

 handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
  };

  nextPage = (event) => {
    this.loadTemplates(Number(this.state.page)+1, Number(this.state.rowsPerPage))
    this.setState({
    page: this.state.page+1
    });
  }

  previousPage = (event) => {
    this.loadTemplates(Number(this.state.page)-1, Number(this.state.rowsPerPage))
    this.setState({
    page: this.state.page-1
    });
  }


componentDidMount(event){
  console.log(this.state)
  this.loadTemplates(Number(this.state.page), Number(this.state.rowsPerPage))
}

loadTemplates = (page, per_page)=>{
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/org/templates?page=${page}&per_page=${per_page}`,
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      self.setState({
      templates: response.data,
      finished: true,
      firstLoadAttempted: true
      });
    }})
  .catch(function (error) {
    console.log(error);
    self.setState({
      messageError:"Error retrieving templates",
      firstLoadAttempted: true
    })
  });
}

searchTemplates = ()=>{
  var self = this;
  if (!this.state.searchValue) {
    this.loadTemplates(Number(this.state.page), Number(this.state.rowsPerPage))
  }else{
  axios({
   method: 'POST',
   url: `${apiBaseUrl}/v1/org/templates/search`,
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
   data: {
     searchValue: this.state.searchValue
   }
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      if (response.data.length > 0) {
      self.setState({
      templates: response.data,
      finished: true
      });
    }
    }})
  .catch(function (error) {
    console.log(error);
    self.setState({messageError:"Error retrieving templates"})
  });
}
}

render() {
  const { classes, ...other } = this.props;
  const templates = []
  console.log(this.state)

  for (let template of this.state.templates) {
    templates.push(
      <TableRow key={template._id}>
        <TableCell >{template.name}</TableCell>
        <TableCell >{template.kind}</TableCell>
        <TableCell >{template.email_subject}</TableCell>
        <TableCell >{moment(new Date(template.createdAt)).format('MM/DD/YYYY')}</TableCell>
        <TableCell >
        <Button size="small" variant="outlined" color="primary" href={`/notifications/templates/update/${template._id}`} >
        Edit Template
        </Button>
        </TableCell>

      </TableRow>
    )}

    return (

      <NavBar2 header="Templates" {...this.props}>
      <Directions
      message="Your templates stored in Stripe are listed here. Each template has a unique card update URL that can be found by going to the Update Page"
      />
      {this.state.firstLoadAttempted ?
        <div>
          <div className={classes.toolbar} />
          {this.state.templates.length > 0 ?
          <Paper>
          <div>
          <AppBar position="static">
        <Toolbar>
        <Typography variant="h6" color="inherit" className={classes.grow}>
        Create or manage your email templates.
        </Typography>
        <Button variant="outlined" color="inherit" href="/notifications/templates/new">Create a Template</Button>
        </Toolbar>
        </AppBar>
    </div>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Subject</TableCell>
                <TableCell >Created At</TableCell>
                <TableCell >Preview/Update</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

            {templates}

            </TableBody>
            <TableFooter>
         <TableRow>
         <TableCell>
           Page {this.state.page}
         </TableCell>
        <TableCell/>
        <TableCell/>
        <TableCell/>
           <TableCell>
             <Button
             size="small"
             variant="contained"
             color="primary"
             onClick={(event) => this.previousPage(event)}
             className={classes.paginationButton}
             disabled={this.state.page <= 1}>
             Previous
             </Button>
             <Button
             size="small"
             variant="contained"
             color="primary"
             onClick={(event) => this.nextPage(event)}
             className={classes.paginationButton}
             disabled={this.state.templates.length < 10}>
             Next
             </Button>
           </TableCell>
         </TableRow>
       </TableFooter>
          </Table>
        </Paper>
          :
                <div>
                <Grid container justify={"center"}>
                <Card className={classes.card}>
           <CardActionArea>
             <CardContent>
               <Typography gutterBottom variant="headline" component="h2">
                 You don't have a template yet!
               </Typography>
               <Typography component="p">
               Templates are used to send notifications to yourself and your customers via email. 
               </Typography>
             </CardContent>
           </CardActionArea>
           <CardActions>
             <Button
             variant="contained"
             color="primary"
             href="/notifications/templates/new"
             >
               Create a Template
             </Button>
           </CardActions>
         </Card>
         </Grid>
         </div>
                }
          </div>
          :
          <Grid container justify={"center"}>
          <div className={classes.loader}>
          <CircularProgress size={100} className={classes.progress} />
          </div>
          </Grid>
        }

        </NavBar2>
    );
  }
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Template);
