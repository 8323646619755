import react from "react";
import queryString from "qs";
import {
  Button,
  Grid,
  Card,
  IconButton,
  Typography,
  CardActions,
  CardContent,
  CardActionArea,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FileCopy } from "@material-ui/icons";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  // root: {
  //   background: "linear-gradient(45deg, #9013FE 15%, #a794ff 90%)",
  //   minWidth: "100%",
  //   minHeight: "100vh",
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "center",
  // },
  card: {
    width: "100%",
    height: "100vh",
    //display: "flex",
    //alignItems: "center",
    padding: -20,
  },
});
const truncateString = (string = "", maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;
const HubspotLink = () => {
  const { enqueueSnackbar } = useSnackbar();
  const query = queryString.parse(window?.location?.search, {
    ignoreQueryPrefix: true,
  });

  let classes = useStyles();

  const handleCopy = () => {
    navigator.clipboard.writeText(query.link);
    enqueueSnackbar("Link copied to clipboard", { variant: "success" });
  };

  return (
    <Grid
      className={classes.root}
      spacing={0}
      alignItems="center"
      justify="center"
    >
      <Card className={classes.card}>
        {/* <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item container justify="center" xs={12}>
            <Typography
              className={classes.title}
              //color="textSecondary"
              gutterBottom
            >
              Card Update URL
            </Typography>
          </Grid>
          <Grid item xs={12} container justify="center">
            <IconButton onClick={handleCopy}>
              <FileCopy />
            </IconButton>
          </Grid>
        </Grid> */}

        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Card Update URL{" "}
            <Typography variant="caption" color="textSecondary">
              Secured by Stripe
            </Typography>
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Copy this URL to give your customer {query.email} a form to update
            their card on file.
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <code style={{ backgroundColor: "#f2f2f2", padding: 10 }}>
              {/* <a target="_blank" rel="noopener noreferrer" href={query.link}> */}
              {query.link}
              {/* </a> */}
            </code>
          </Typography>
        </CardContent>

        {/* <CardActions>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            endIcon={<FileCopy />}
            onClick={handleCopy}
          >
            Copy to Clipboard
          </Button>
        </CardActions> */}
      </Card>
    </Grid>
  );
};

export default HubspotLink;
