import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  InputLabel,
  ListSubheader,
  ListItemText,
  FormControl,
  Checkbox,
  Chip,
  Select,
} from "@material-ui/core";
import axios from "axios";
const baseUrl =
  process.env.REACT_APP_BASE_URL || process.env.REACT_APP_DEV_ENDPOINT;
const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 200,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    marginRight: 5,
  },
  chip: {
    margin: 2,
  },
}));

const SelectedChip = ({ priceId, prices, className }) => {
  let price = prices.find((p) => p.id === priceId);
  return price ? (
    <Chip
      key={priceId}
      label={`${price.nickname || price.id} ${
        price.amount !== 0
          ? (price.unit_amount / 100).toLocaleString("en-US", {
              style: "currency",
              currency: price.currency,
            })
          : 0
      }`}
      className={className}
    />
  ) : null;
};

export default function ProductsSelect({
  onChange,
  value,
  type = "all",
  onProductsLoaded,
}) {
  console.log({ type });
  let [products, setProducts] = useState([]);
  let [prices, setPrices] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  let fetchPrices = async () => {
    try {
      let pricesRequest = axios({
        method: "get",
        url: `${baseUrl}/v2/prices`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      let productsRequest = axios({
        method: "get",
        url: `${baseUrl}/v1/org/products`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      let [pricesResponse, productsResponse] = await Promise.all([
        pricesRequest,
        productsRequest,
      ]);
      let pricesArray = pricesResponse.data;
      let productsArray = productsResponse.data;
      for (const p of productsArray) {
        p.prices = pricesArray.filter((price) => price.product === p.id);
      }
      console.log({ productsArray });
      setProducts(productsArray);
      setPrices(pricesArray);
    } catch (e) {
      console.log("Error fetching prices", e);
    }
  };
  let fetchProducts = async () => {
    setIsLoading(true);
    let pricesRequest = await axios({
      method: "get",
      url: `${baseUrl}/v1/org/products`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).catch((err) => {
      setIsLoading(false);
      console.log(
        err?.response?.data,
        err?.response?.data?.error,
        err?.response?.status
      );
    });
    let products = pricesRequest?.data?.filter((p) => p.active); // Filter out any non-active products

    setIsLoading(false);
    setProducts(products || []);
    // onProductsLoaded(products || []);
    fetchPrices();
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  const classes = useStyles();

  let menuArray = [];

  for (const product of products) {
    menuArray.push(
      <ListSubheader key={product.id}>{product.name}</ListSubheader>
    );
    for (const price of product.prices || []) {
      //if (type === price.type) {
      menuArray.push(
        <MenuItem
          value={price.id}
          key={price.id}
          disabled={price.type !== type && type !== "all"}
        >
          <Checkbox color="primary" checked={value.indexOf(price.id) > -1} />
          <ListItemText
            primary={`${price.nickname || price.name || price.id} `}
            secondary={`${(price.unit_amount / 100).toLocaleString("en-US", {
              style: "currency",
              currency: price.currency,
            })}${
              price.type === "recurring" ? "/" + price.recurring.interval : ""
            }`}
          />
        </MenuItem>
      );
      //}
    }
  }
  return (
    <FormControl variant="outlined" fullWidth className={classes.formControl}>
      <InputLabel htmlFor="products">Stripe Products</InputLabel>
      <Select
        labelWidth={115}
        id="products"
        multiple
        placeholder="Select a Stripe product."
        disabled={isLoading}
        onChange={onChange}
        value={value}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <SelectedChip
                key={value}
                priceId={value}
                prices={prices}
                className={classes.chips}
              />
            ))}
          </div>
        )}
      >
        {menuArray}
      </Select>
    </FormControl>
  );
}
