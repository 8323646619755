import React from 'react';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ChargeInstructionsPageStyles.js';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NavBar2 from '../../components/navigation/NavBar2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import getSymbolFromCurrency from 'currency-symbol-map';
import Directions from '../../components/directions/Directions';
import moment from 'moment';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";





class ChargeInstructions extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 10,
      chargeInstructions:[],
      firstLoadAttempted: false
    };
  }

   changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`
    this.props.history.push(path);
 };

 handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
};

componentDidMount(){
  console.log(this.state)
  this.fetchChargeInstructions(1, 10);
  this.fetchMe();
}

nextPage = (event) => {
  this.fetchChargeInstructions(Number(this.state.page)+1, Number(this.state.rowsPerPage))
  this.setState({
  page: this.state.page+1
  });
}

previousPage = (event) => {
  this.fetchChargeInstructions(Number(this.state.page)-1, Number(this.state.rowsPerPage))
  this.setState({
  page: this.state.page-1
  });
}

fetchMe = () =>{
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/org/me`, //retrieves the current authenticated user
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
 })
  .then(function (response) {
    if(response.status === 200){

    }else{
      let path = `/${self.state.org_handle}/login`
      self.props.history.push(path);
    }
  })
  .catch(function (error) {
    console.log(error);
    self.setState({messageError:"Error retrieving user profile"})
  });
}

fetchChargeInstructions = (page, per_page)=>{
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/org/chargeinstructions?page=${page}&per_page=${per_page}`,
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      self.setState({
      chargeInstructions: response.data,
      finished: true,
      firstLoadAttempted: true
      });
    }})
  .catch(function (error) {
    console.log(error);
    self.setState({
      messageError:"Error retrieving forms",
      firstLoadAttempted: true
    })
  });
}

render() {
  const { classes, ...other } = this.props;
  const chargeInstructions = [];
  for (let chargeInstruction of this.state.chargeInstructions) {
    chargeInstructions.push(
      <TableRow key={chargeInstruction._id}>
        <TableCell >{chargeInstruction.name}</TableCell>
        <TableCell >{getSymbolFromCurrency(chargeInstruction.currency) + Number(chargeInstruction.amount_in_cents/100).toFixed(2)}</TableCell>
        <TableCell >{chargeInstruction.redirect_url}</TableCell>
        <TableCell >{moment(new Date(chargeInstruction.createdAt)).format('MM/DD/YYYY')}</TableCell>
        <TableCell >
        <Button size="small" variant="outlined" color="primary" href={`/chargeinstruction/onetime/snippet/${chargeInstruction._id}`}>Preview/Copy</Button>

        <Button size="small" variant="outlined" color="primary" href={`/chargeinstruction/update/onetime/${chargeInstruction._id}`} >
        Update
        </Button>
        </TableCell>
      </TableRow>
    )
  }

    return (

      <NavBar2 header="Drop in Stripe Checkout" {...other}>
      <Directions
      message="Your One Time Charge embedded checkouts are listed here. To change what happens when it's submitted click Update. To change the way it looks, click Preview/Copy."
      />
      {this.state.firstLoadAttempted ?
        <div>
        { chargeInstructions.length > 0 ?

          <Paper>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell >Name</TableCell>
                <TableCell >Amount</TableCell>
                <TableCell >Redirect Url</TableCell>
                <TableCell >Created At</TableCell>
                <TableCell >View/Edit</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>

            {chargeInstructions}

            </TableBody>
            <TableFooter>
         <TableRow>
         <TableCell>
           Page {this.state.page}
         </TableCell>
         <TableCell/>
        <TableCell/>
        <TableCell/>
           <TableCell>
             <Button
             size="small"
             variant="outlined"
             color="primary"
             onClick={(event) => this.previousPage(event)}
             className={classes.paginationButton}
             disabled={this.state.page <= 1}>
             Previous
             </Button>
             <Button
             size="small"
             variant="outlined"
             color="primary"
             onClick={(event) => this.nextPage(event)}
             className={classes.paginationButton}
             disabled={this.state.chargeInstructions.length < 10}>
             Next
             </Button>
           </TableCell>
         </TableRow>
       </TableFooter>
          </Table>
        </Paper>
        :
        <div>
        <Grid container justify={"center"}>
        <Card className={classes.card}>
   <CardActionArea>
     <CardMedia
       className={classes.media}
       image="/static/images/cards/contemplative-reptile.jpg"
       title="Contemplative Reptile"
     />
     <CardContent>
       <Typography gutterBottom variant="headline" component="h2">
         You don't have an Embedded Checkout yet!
       </Typography>
       <Typography component="p">
         Embedded Checkouts remove the server side work when using a Stripe button. <a href="https://intercom.help/instapayments/feature-descriptions/stripe-post-urls" target="_blank" rel="noopener noreferrer">See this article for more info.</a>
       </Typography>
     </CardContent>
   </CardActionArea>
   <CardActions>
     <Button
     variant="contained"
     color="primary"
     href="/chargeinstruction/new"
     >
       Create an Embedded Checkout
     </Button>
   </CardActions>
 </Card>
 </Grid>
 </div>
        }
        </div>
        :
        <Grid container justify={"center"}>
        <div className={classes.loader}>
        <CircularProgress size={100} className={classes.progress} />
        </div>
        </Grid>
      }
        <Button
        variant="fab"
        color="primary"
        aria-label="Add"
        href="/chargeinstruction/new"
        className={classes.fab}
        >
          <AddIcon />
        </Button>

  </NavBar2>
    );
  }
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(ChargeInstructions);
