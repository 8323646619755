import React from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Currencies from "../../components/currencies";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import NavBar2 from "../../components/navigation/NavBar2";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import getSymbolFromCurrency from "currency-symbol-map";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Snackbar from "@material-ui/core/Snackbar";
import Directions from "../../components/directions/Directions";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
import withRouter from "../../components/withRouter";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: theme.mixins.toolbar,
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    marginTop: theme.spacing.unit * 3,
  }),
  button: {
    marginTop: theme.spacing.unit * 2,
  },
  textField: {
    marginTop: theme.spacing.unit,
  },
  formControl: {
    marginTop: theme.spacing.unit,
    minWidth: 120,
  },
  inline: {
    display: "inline",
  },
  inlineField: {
    marginTop: theme.spacing.unit,
    width: "10%",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

class NewPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      amount: 0,
      redirect_url: "",
      currency: "USD",
      instructionType: "oneTime",
      allowPassThrough: false,
      products: [],
      selectedPlans: [],
      interval_count: 1,
      interval: "month",
    };
  }

  changeNav = (name) => (event) => {
    let path = `/${this.props.params.org}/${name}`;
    this.props.history.push(path);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  fetchProduct = () => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/products/${self.props.params.productId}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            product: response.data,
            finished: true,
            firstLoadAttempted: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({
          messageError: "Error retrieving products",
          firstLoadAttempted: true,
        });
      });
  };

  createPlan = (event) => {
    event.preventDefault();
    var self = this;

    axios({
      method: "POST",
      url: `${apiBaseUrl}/v1/org/products/${self.props.params.productId}/plans/new`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        nickname: this.state.nickname,
        amount_in_cents: Math.ceil(this.state.amount * 100),
        currency: this.state.currency,
        billing_scheme: "per_unit",
        trial_period_days: this.state.trial_period_days,
        interval: this.state.interval,
        interval_count: this.state.interval_count,
      },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            snackOpen: true,
            snackVariant: "success",
            snackMessage: "Your product has been created.",
          });
          let path = `/products/${response.data.id}`;
          self.props.history.push(path);
        }
      })
      .catch(function (error) {
        console.log(error.response);
        if (error.response && error.response.data) {
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: error.response.data.message,
          });
        } else {
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: "Error creating product.",
          });
        }
      });
  };

  componentDidMount(event) {
    this.fetchProduct();
  }

  render() {
    const { classes, theme, ...other } = this.props;
    console.log(this.state);

    const productArray = [];

    for (let product of this.state.products) {
      let plans = [];
      productArray.push(
        <MenuItem disabled key={product.id} value={product.name}>
          <ListItemText primary={product.name} />
        </MenuItem>
      );
      for (let plan of product.plans) {
        productArray.push(
          <MenuItem key={plan.id} value={plan.id}>
            <Checkbox
              checked={this.state.selectedPlans.indexOf(plan.id) > -1}
            />
            <ListItemText primary={plan.nickname || plan.id} />
          </MenuItem>
        );
      }
    }

    return (
      <NavBar2
        header={`New Plan for ${this.state.product && this.state.product.name}`}
        {...other}
      >
        <Directions>
          <Typography>
            Define your new plan pricing here. If you need tiered based pricing,{" "}
            <a href="https://dashboard.stripe.com/plans/create?product=prod_ERbYsbkNh5eUd5">
              please create your product in Stripe.
            </a>
          </Typography>
        </Directions>
        <Paper className={classes.paper}>
          <form onSubmit={this.createPlan}>
            <TextField
              label="Nickname"
              fullWidth
              required
              type="text"
              helperText="Internal name to identify the plan easily"
              className={classes.textField}
              onChange={this.handleChange("nickname")}
            />
            <TextField
              label="Amount per Unit"
              fullWidth
              required
              helperText="Amount per unit in the plan."
              type="number"
              InputProps={{
                inputProps: {
                  step: "any",
                  min: 0,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    {getSymbolFromCurrency(this.state.currency)}
                  </InputAdornment>
                ),
              }}
              className={classes.textField}
              onChange={this.handleChange("amount")}
            />
            <div className={classes.inline}>
              <TextField
                label="Charge every..."
                value={this.state.interval_count}
                className={classes.inlineField}
                fullWidth
                helperText=""
                type="number"
                onChange={this.handleChange("interval_count")}
              />

              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="charge-type"></InputLabel>
                <Select
                  native
                  onChange={this.handleChange("interval")}
                  inputProps={{
                    name: "interval",
                    id: "interval",
                  }}
                >
                  <option value="month">Month(s)</option>
                  <option value="week">Week(s)</option>
                  <option value="day">Day(s)</option>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="currency">Currency</InputLabel>
                <Select
                  native
                  value={this.state.currency}
                  onChange={this.handleChange("currency")}
                  inputProps={{
                    name: "currency",
                    id: "currency",
                  }}
                >
                  <Currencies />
                </Select>
                <FormHelperText>
                  The currency you want the charge processed in.
                </FormHelperText>
              </FormControl>
            </div>
            <TextField
              label="Trial (optional)"
              fullWidth
              helperText="Length of free trial in days."
              type="number"
              InputProps={{
                inputProps: {
                  step: "any",
                  min: 0,
                },
              }}
              className={classes.textField}
              onChange={this.handleChange("trial_period_days")}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              className={classes.button}
              type="submit"
            >
              Create Plan
            </Button>
          </form>
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
        >
          <MySnackbarContentWrapper
            variant={this.state.snackVariant}
            message={this.state.snackMessage}
            onClose={this.handleSnackClose}
          />
        </Snackbar>
      </NavBar2>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(withRouter(NewPlan));
