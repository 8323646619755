import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';


function StripeEvents(props) {
  return (
    <React.Fragment>

      <MenuItem value="customer.subscription.created" key="customer.subscription.created" >Subscription Created </MenuItem>
      <MenuItem value="customer.subscription.trial_will_end" key="customer.subscription.trial_will_end" >Trial Ends in Three Days</MenuItem>
      
      <MenuItem value="invoice.payment_succeeded" key="invoice.payment_succeeded" >Subscription Successfully Renewed</MenuItem>
      <MenuItem value="invoice.upcoming" key="invoice.upcoming" >Subscription will renew in X Days</MenuItem>
      
      <MenuItem value="charge.succeeded" key="charge.succeeded" >One Time Charge Succeeded</MenuItem>

      <MenuItem value="invoice.payment_failed" key="invoice.payment_failed" >Invoice Payment Failed </MenuItem>
      <MenuItem value="customer.subscription.deleted" key="customer.subscription.deleted" >Subscription Canceled</MenuItem>
    </React.Fragment>
  );
}


export default (StripeEvents);