import React from 'react';


function Currencies(props) {
  return (
    <React.Fragment>
        <option value="USD">USD - US Dollar</option>
        <option value="AUD">AUD - Australian Dollar</option>
        <option value="CAD">CAD - Canadian Dollar</option>
        <option value="EUR">EUR - Euro</option>
        <option value="GBP">GBP - British Pound</option>
        <option value="JPY">JPY - Japanese Yen</option>
        <option value="NZD">NZD - New Zealand Dollar</option>
        <option value="NOK">NOK - Norway Krona</option>
        <option value="SEK">SEK - Swedish Krona</option>
        <option value="MXN">MXN - Pesos</option>
        <option value="SGD">SGD - Singapore Dollar</option>
        <option value="TRY">TRY - Turkish Lira</option>
    </React.Fragment>
  );
}


export default (Currencies);