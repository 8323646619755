import { fade } from '@material-ui/core/styles/colorManipulator';


const styles = theme => ({
   loader:{
     marginTop: "15%"
   },
   productCard:{
     width: "100%",
     marginBottom: "2%"
   }
});

export {styles};
