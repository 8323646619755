import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import DashboardIcon from "@material-ui/icons/Dashboard";
import GettingStartedIcon from "@material-ui/icons/Cake";
import CustomersIcon from "@material-ui/icons/SupervisorAccount";
import UsersIcon from "@material-ui/icons/Face";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ProfileIcon from "@material-ui/icons/Person";
import AccountIcon from "@material-ui/icons/Business";
import IntegrationsIcon from "@material-ui/icons/DeviceHub";
import PagesIcon from "@material-ui/icons/InsertDriveFile";
import SettingsIcon from "@material-ui/icons/Settings";
import OneTimeIcon from "@material-ui/icons/SyncDisabled";
import RecurringIcon from "@material-ui/icons/Autorenew";
import ProductIcon from "@material-ui/icons/Store";
import LogOutIcon from "@material-ui/icons/ExitToApp";
import PortalIcon from "@material-ui/icons/SettingsBrightness";
import {
  Email,
  Folder,
  Drafts,
  ExpandMore,
  ExpandLess,
  Send,
  Inbox,
  MailOutline,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useNavigate } from "react-router-dom";

const styles = (theme) => ({
  // nested: {
  //   paddingLeft: theme.spacing.unit * 5,
  // },
  active: {
    backgroundColor: "#B0BEC5",
  },
  normal: {},
});

const NavigationList = (props) => {
  let [state, setState] = useState({
    open: false,
    openLinks: false,
    active: "",
  });
  const navigate = useNavigate();
  const classes = makeStyles(styles);
  const handleClick = () => {
    setState({ ...state, open: !state.open });
  };

  const handleLinkClick = () => {
    setState({ ...state, openLink: !state.openLink });
  };

  const handleFormsLinkClick = () => {
    setState({ ...state, openFormsLink: !state.openFormsLink });
  };

  const handleNotificationsLinkClick = () => {
    setState({
      ...state,
      openNotificationsLink: !state.openNotificationsLink,
    });
  };

  const changeNav = (name) => (event) => {
    let path = `/${name}`;
    navigate(path);
  };

  const currentPath = window.location?.pathname?.split("/")[1];
  console.log(currentPath);
  const activeStyles = { backgroundColor: "#B0BEC5" };
  return (
    <div>
      <List component="nav">
        <ListItem
          button
          style={currentPath === "dashboard" ? activeStyles : null}
          onClick={changeNav("dashboard")}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        {/* <ListItem
          button
          className={
            currentPath === "start-guide" ? classes.active : classes.normal
          }
          onClick={changeNav("start-guide")}
        >
          <ListItemIcon>
            <GettingStartedIcon />
          </ListItemIcon>
          <ListItemText primary="Get Started" />
        </ListItem> */}
        <ListItem
          button
          style={currentPath === "stripecheckouts" ? activeStyles : null}
          onClick={changeNav("stripecheckouts")}
        >
          <ListItemIcon>
            <ShoppingCart />
          </ListItemIcon>
          <ListItemText primary="Checkouts" />
        </ListItem>

        <ListItem
          button
          style={currentPath === "customers" ? activeStyles : null}
          onClick={changeNav("customers")}
        >
          <ListItemIcon>
            <CustomersIcon />
          </ListItemIcon>
          <ListItemText primary="Customers" />
        </ListItem>

        <ListItem
          button
          style={currentPath === "products" ? activeStyles : null}
          onClick={changeNav("products")}
        >
          <ListItemIcon>
            <ProductIcon />
          </ListItemIcon>
          <ListItemText primary="Products" />
        </ListItem>
        {new Date(localStorage.getItem("createdAt")).getTime() <
          new Date("2022-03-10").getTime() && (
          <>
            <ListItem
              button
              className={classes.nested}
              onClick={changeNav("forms")}
            >
              <ListItemIcon>
                <RecurringIcon />
              </ListItemIcon>
              <ListItemText primary="Recurring Billing Forms" />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={changeNav("npforms")}
            >
              <ListItemIcon>
                <OneTimeIcon />
              </ListItemIcon>
              <ListItemText primary="One Time Charge Forms" />
            </ListItem>
            <ListItem
              button
              className={
                currentPath === "users" ? classes.active : classes.normal
              }
              onClick={changeNav("users")}
            >
              <ListItemIcon>
                <UsersIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>

            <ListItem
              button
              onClick={handleNotificationsLinkClick}
              className={
                currentPath === "notifications"
                  ? classes.active
                  : classes.normal
              }
            >
              <ListItemIcon>
                <Email />
              </ListItemIcon>
              <ListItemText primary="Notifications (beta)" />
              {state.openNotificationsLink ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse
              in={state.openNotificationsLink}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={changeNav("notifications/triggers")}
                >
                  <ListItemIcon>
                    <Send />
                  </ListItemIcon>
                  <ListItemText primary="Send Events" />
                </ListItem>

                <ListItem
                  button
                  className={classes.nested}
                  onClick={changeNav("notifications/templates")}
                >
                  <ListItemIcon>
                    <Folder />
                  </ListItemIcon>
                  <ListItemText primary="Email Templates" />
                </ListItem>

                <ListItem
                  button
                  className={classes.nested}
                  onClick={changeNav("notifications/from-addresses")}
                >
                  <ListItemIcon>
                    <Drafts />
                  </ListItemIcon>
                  <ListItemText primary="From Addresses" />
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
        <ListItem
          button
          onClick={handleClick}
          className={
            currentPath === "settings" ? classes.active : classes.normal
          }
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
          {state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              onClick={changeNav("settings/profile")}
            >
              <ListItemIcon>
                <ProfileIcon />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={changeNav("settings/integrations")}
            >
              <ListItemIcon>
                <IntegrationsIcon />
              </ListItemIcon>
              <ListItemText primary="Integrations" />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={changeNav("settings/portal")}
            >
              <ListItemIcon>
                <PortalIcon />
              </ListItemIcon>
              <ListItemText primary="Branding" />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={changeNav("settings/billing")}
            >
              <ListItemIcon>
                <AccountIcon />
              </ListItemIcon>
              <ListItemText primary="Billing" />
            </ListItem>
          </List>
        </Collapse>
        <Divider />

        <ListItem button onClick={changeNav("logout")}>
          <ListItemIcon>
            <LogOutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );
};

export default NavigationList;
