import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import axios from "axios";
import Typography from "@material-ui/core/Typography";

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import NavigationList from "./NavigationList";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

const drawerWidth = 240;
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  flex: {
    flex: 1,
  },
  appFrame: {
    minHeight: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  appBar: {
    position: "absolute",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  "appBarShift-left": {
    marginLeft: drawerWidth,
  },
  "appBarShift-right": {
    marginRight: drawerWidth,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  "content-left": {
    marginLeft: -drawerWidth,
  },
  "content-right": {
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  "contentShift-left": {
    marginLeft: 0,
  },
  "contentShift-right": {
    marginRight: 0,
  },
  avatar: {
    marginLeft: 0,
  },
  logo: {
    maxWidth: "50px",
    maxHeight: "50px",
  },
});

class NavBar2 extends React.Component {
  state = {
    open: false,
    anchor: "left",
    anchorMenuEl: null,
    openDrawer: true,
    finishedLoading: false,
  };

  changeNav = (name) => (event) => {
    let path = `/${name}`;
    this.props.history.push(path);
  };

  handleMenu = (event) => {
    /*this.setState({
     anchorMenuEl: event.currentTarget,
     open: !this.state.open

   });*/
  };

  componentDidMount() {
    this.fetchMe();
  }

  fetchMe = () => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/me`, //retrieves the current authenticated user
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        if (response.status === 200) {
          self.setState({ org: { ...response.data }, finishedLoading: true });
        } else {
          let path = `/${self.state.org_handle}/login`;
          self.props.history.push(path);
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving user profile" });
      });
  };

  handleClose = () => {
    this.setState({ anchorMenuEl: null });
  };

  handleClose = () => {
    this.setState({ anchorMenuEl: null });
  };

  handleDrawerOpen = () => {
    this.setState({
      openDrawer: !this.state.openDrawer,
    });
  };

  render() {
    const { classes, theme, children, ...other } = this.props;
    const { open, openDrawer, anchorMenuEl } = this.state;
    const anchor = "left";
    console.log(this.state);
    const drawer = (
      <Drawer
        variant="persistent"
        anchor={"left"}
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={this.handleDrawerOpen}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <NavigationList {...other} />
      </Drawer>
    );

    let before = null;
    let after = null;

    if (anchor === "left") {
      before = drawer;
    } else {
      after = drawer;
    }

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar
            position="fixed"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: openDrawer,
            })}
          >
            <Toolbar>
              <IconButton
                onClick={this.handleDrawerOpen}
                color="inherit"
                aria-label="Open drawer"
                className={classNames(
                  classes.menuButton,
                  openDrawer && classes.hide
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                className={classes.flex}
              >
                <img
                  className={classes.logo}
                  src={process.env.REACT_APP_LOGO_URL_2}
                  alt="logo"
                />
              </Typography>
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                className={classes.flex}
              >
                {this.props.header
                  ? this.props.header
                  : process.env.REACT_APP_BUSINESS_NAME}
              </Typography>
              <Typography variant="subheading" color="inherit" noWrap>
                Hi {localStorage.getItem("first_name")}
              </Typography>
              <IconButton
                aria-owns={open ? "menu-appbar" : null}
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
                className={classes.avatar}
              >
                <Avatar
                  aria-label="Avatar"
                  className={classes.avatar}
                  src={localStorage.getItem("gravatar")}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorMenuEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                open={open}
              >
                <MenuItem onClick={this.changeNav("settings/profile")}>
                  Profile
                </MenuItem>
                <MenuItem onClick={this.changeNav("logout")}>Logout</MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
          {before}
          <main
            className={classNames(
              classes.content,
              classes[`content-${anchor}`],
              {
                [classes.contentShift]: openDrawer,
                [classes[`contentShift-${anchor}`]]: openDrawer,
              }
            )}
          >
            <div className={classes.drawerHeader} />
            {this.state.finishedLoading &&
            !this.state.org.stripe_credentials ? (
              <Alert severity="error">
                <AlertTitle>Missing Stripe Connection</AlertTitle>
                Looks like there is no Stripe connection on this account.
                InstaPayments requires this to work properly.
                <a href="/settings/integrations">Connect To Stripe</a>
                <br />
              </Alert>
            ) : null}
            {this.state.finishedLoading &&
            this.state.org.billing.subscriptions.total_count === 0 ? (
              <Alert severity="error">
                <AlertTitle>Your Account is Deactivated</AlertTitle>
                An active or trial subscription is required to use InstaPayments
                checkouts and customer portal. You do not need an active
                subscription if you are only using the free Hubspot Integration.
                <a href="/settings/billing">Activate My Account</a>
              </Alert>
            ) : null}
            {children}
          </main>
          {after}
        </div>
      </div>
    );
  }
}

NavBar2.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NavBar2);
