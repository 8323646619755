import Paper from "@material-ui/core/Paper";
import React from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import NavBar2 from "../../components/navigation/NavBar2";
import Step from "@material-ui/core/Step";
import Grid from "@material-ui/core/Grid";
import StepLabel from "@material-ui/core/StepLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import getSymbolFromCurrency from "currency-symbol-map";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Currencies from "../../components/currencies";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import ordinal from "ordinal";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = theme => ({
  content: {
    padding: "2%"
  },
  stepper: {
    width: "100%"
  },
  paper: {
    minHeight: "50%"
  },
  fields: {
    // width: "100%"
  },
  text: {
    paddingTop: "13px"
  },
  textMiddle: {
    paddingTop: "13px",
    marginLeft: "5px"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 300
  }
});

function getSteps() {
  return [
    "Pick a Type",
    "Your Pricing",
    "Additional Settings",
    "Finishing Touches"
  ];
}

class EasyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      amount: 20,
      interval_count: 1,
      interval: "month",
      trial_length: 0,
      custom_fields: [],
      products: [],
      selectedPlans: [],
      currency: "USD"
    };
  }

  componentDidMount() {
    this.fetchProducts();
  }

  createProductAndPlan = () => {
    var self = this;
    axios({
      method: "POST",
      url: `${apiBaseUrl}/v2/org/products`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json"
      },
      data: {
        interval: self.state.interval,
        interval_count: self.state.interval_count,
        currency: self.state.currency,
        amount_in_cents: self.state.amount * 100,
        name: self.state.name
      }
    })
      .then(async function(response) {
        console.log(response.status, response.data);
        if (response.status === 200) {
          await self.setState({
            selectedPlans: [response.data.plans[0].id]
          });
        }
        self.createForm();
      })
      .catch(function(error) {
        console.log(error);
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          snackMessage: "error creating the product"
        });
      });
  };

  fetchProducts = () => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/products`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        if (response.status === 200) {
          const filteredProducts = response.data.filter(
            product => product.plans && product.plans.length > 0
          );

          self.setState({
            availableProducts: filteredProducts,
            products: filteredProducts
          });
        } else {
          console.log(response.status);
        }
      })
      .catch(function(error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving forms" });
      });
  };

  createForm = () => {
    console.log(
      this.state.selectedPlans,
      this.state.name,
      this.state.discount,
      this.state.enableDiscount
    );

    var self = this;
    axios({
      method: "POST",
      url: `${apiBaseUrl}/v1/org/forms`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json"
      },
      data: {
        plan_ids: this.state.selectedPlans,
        skus: this.state.selectedSkus,
        name: this.state.name,
        coupon: this.state.discount,
        coupon_field: this.state.enableDiscount,
        coupon_enabled: this.state.enableDiscount,
        currency: this.state.currency,
        redirect_url: this.state.redirect_url,
        custom_fields: this.state.custom_fields,
        hide_address: this.state.hide_address,
        type: this.state.type || "signup",
        taxes: {
          active: this.state.enableTaxes,
          percent: this.state.tax_percentage
        },
        setup_fee: {
          active: this.state.enableSetupFee,
          amount_in_cents: this.state.setup_fee
        },
        donation_defaults: {
          onetime: this.state.defaultsOnetime
            ? this.state.defaultsOnetime.split(",")
            : [25, 50, 100, 250, 500, 1000],
          recurring: this.state.defaultsRecurring
            ? this.state.defaultsRecurring.split(",")
            : [25, 50, 100, 250, 500, 1000]
        },
        custom_logo: {
          active: this.state.custom_logo,
          custom_logo_url: this.state.custom_logo_url
        },
        billing_anchor_date: {
          active: this.state.enableAnchorDate,
          date_number: this.state.date_number,
          prorated: this.state.prorated
        },
        installment_plan: {
          active: this.state.enableInstallment,
          amount_in_cents: this.state.installment_total * 100
        },
        terms: {
          active: this.state.enableTerms,
          link: this.state.termsLink
        },

        custom_label_first_name: this.state.custom_label_first_name,
        custom_label_last_name: this.state.custom_label_last_name,
        custom_label_email: this.state.custom_label_email,
        custom_label_donation_tab_1: this.state.custom_label_donation_tab_1,
        custom_label_donation_tab_2: this.state.custom_label_donation_tab_2,
        custom_label_donation_helper_text_1: this.state
          .custom_label_donation_helper_text_1,
        custom_label_donation_helper_text_2: this.state
          .custom_label_donation_helper_text_2,
        custom_label_checkout_button: this.state.custom_label_checkout_button
      }
    })
      .then(function(response) {
        console.log("Form creation response", response.data);
        if (response.status === 200) {
          self.setState({
            snackOpen: true,
            snackVariant: "success",
            snackMessage: "Your plan has been created"
          });
          let path = `/forms/integrate/${response.data.type}/${response.data._id}`;
          self.props.history.push(path);
        }
      })
      .catch(function(error) {
        console.log(error);
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          snackMessage: "error creating form"
        });
      });
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  handleNext = () => {
    console.log(getSteps().length, this.state.step);
    if (getSteps().length - 1 === this.state.step) {
      console.log("submit was called");
      if (this.state.selectedPlans.length > 0) {
        //isSubmitting
        console.log(this.state.selectedPlans);
      } else {
        this.createProductAndPlan();

        //is submitting
        //create product and plans
      }
    } else {
      this.setState({
        step: this.state.step + 1
      });
    }
  };

  handleBack = () => {
    this.setState({
      step: this.state.step - 1
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleCustomFieldChange = index => event => {
    let unmutatedArray = new Array().concat(this.state.custom_fields);
    unmutatedArray[index] = { name: event.target.value };

    this.setState({
      custom_fields: unmutatedArray
    });
  };

  handleCustomFieldCheckChange = index => event => {
    let unmutatedArray = new Array().concat(this.state.custom_fields);
    unmutatedArray[index].required = !this.state.custom_fields[index].required;

    this.setState({
      custom_fields: unmutatedArray
    });
  };

  addField = index => event => {
    let custom_fields = this.state.custom_fields;
    custom_fields.push({
      name: "new field"
    });
    this.setState({
      custom_fields: custom_fields
    });
  };

  removeField = index => event => {
    let custom_fields = new Array().concat(this.state.custom_fields);
    let edited_custom_fields = custom_fields.splice(index, 1);
    this.setState({
      custom_fields: custom_fields
    });
  };

  render() {
    console.log(this.state);
    const { classes, ...other } = this.props;
    const steps = getSteps();
    const potentialDays = [];
    for (var i = 1; i < 32; i++) {
      potentialDays.push(<option value={i}>{ordinal(i)}</option>);
    }
    potentialDays.push(<option value={32}>{"End of Month"}</option>);

    const productArray = [];

    for (let product of this.state.products) {
      let plans = [];
      productArray.push(
        <MenuItem disabled key={product.id} value={product.name}>
          <ListItemText primary={product.name} />
        </MenuItem>
      );
      for (let plan of product.plans) {
        productArray.push(
          <MenuItem key={plan.id} value={plan.id}>
            <Checkbox
              checked={this.state.selectedPlans.indexOf(plan.id) > -1}
            />
            <ListItemText primary={plan.nickname || plan.id} />
          </MenuItem>
        );
      }
    }

    const customFieldsArray = [];
    for (let [index, custom_field] of this.state.custom_fields.entries()) {
      customFieldsArray.push(
        <div id={"custom_field_" + index} key={index}>
          <TextField
            label="Field Name"
            type="text"
            required
            value={custom_field.name}
            className={classes.textFieldHalf}
            helperText="Custom field name displayed on the forms"
            onChange={this.handleCustomFieldChange(index)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={custom_field.required}
                onChange={this.handleCustomFieldCheckChange(index)}
                color="primary"
              />
            }
            label="Required?"
          />
          <Button
            color="primary"
            disabled={this.state.custom_fields.length === 0}
            onClick={this.removeField(index)}
            className={classes.button}
          >
            Remove Field
          </Button>
        </div>
      );
    }

    return (
      <NavBar2 {...other}>
        <Paper className={classes.paper}>
          <div className={classes.stepper}>
            <Stepper activeStep={this.state.step} alternativeLabel>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {this.state.step === 0 ? (
              <Grid
                container
                justify={"center"}
                //alignContent={"center"}
                //alignItems={"center"}
                direction="row"
                className={classes.content}
                spacing={16}
              >
                <Grid item>
                  <TextField
                    label="Form Name"
                    type="text"
                    required
                    fullWidth
                    value={this.state.name}
                    className={classes.textFieldHalf}
                    helperText="This is for tracking the form internally."
                    onChange={this.handleChange("name")}
                  />
                </Grid>

                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Type</InputLabel>
                    <Select
                      native
                      fullWidth
                      variant={"outlined"}
                      value={this.state.type}
                      onChange={this.handleChange("type")}
                      inputProps={{
                        name: "type",
                        id: "type"
                      }}
                    >
                      <option value="signup">Subscription Signup</option>
                      <option value="multiprod">Customer Picks a Plan</option>
                      <option value="signup-quantity">
                        Customer enters a Quantity
                      </option>
                      {/*<option value="onetime">One Time Charge</option>*/}
                      <option value="donation">Donation</option>
                    </Select>
                    <FormHelperText>The type of form you want.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="currency">Currency</InputLabel>
                    <Select
                      native
                      value={this.state.currency}
                      onChange={this.handleChange("currency")}
                      inputProps={{
                        name: "currency",
                        id: "currency"
                      }}
                    >
                      <Currencies />
                    </Select>
                    <FormHelperText>
                      The currency you want displayed on the form.
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            ) : null}

            <Grid
              container
              justify={"center"}
              alignContent={"left"}
              className={classes.content}
              spacing={16}
            >
              {this.state.step === 1 ? (
                
                <React.Fragment>
                  {this.state.products.length > 0 ? (
                    <React.Fragment>

                      <Grid
                        container
                        justify={"center"}
                        alignContent={"center"}
                        className={classes.content}
                        spacing={16}
                      >
                        <Grid item>
                          <Typography style={{ paddingTop: "22px" }}>
                            Pick an existing product already in your Stripe
                            account
                          </Typography>
                        </Grid>
                        <Grid item>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <Select
                              multiple
                              styles={{
                                minWidth: "30%"
                              }}
                              input={<OutlinedInput name="plans" id="plans" />}
                              value={this.state.selectedPlans}
                              onChange={this.handleChange("selectedPlans")}
                              renderValue={selected => (
                                <div className={classes.chips}>
                                  {selected.map(value => (
                                    <Chip
                                      key={value}
                                      label={value}
                                      className={classes.chip}
                                    />
                                  ))}
                                </div>
                              )}
                              MenuProps={{
                                style: {
                                  maxHeight: 500
                                  //width: 200,
                                }
                              }}
                            >
                              {productArray}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justify={"center"}
                        alignContent={"center"}
                        className={classes.content}
                        spacing={16}
                      >
                        <Grid item md={5}>
                          <Divider />
                        </Grid>
                        <Grid style={{ textAlign: "center" }} item md={1}>
                          OR
                        </Grid>
                        <Grid item md={5}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ) : null}
                  <Grid item>
                    <Typography className={classes.text}>
                      I want to charge my customer(s)
                    </Typography>
                  </Grid>
                  <Grid item md={1}>
                    <TextField
                      label=""
                      variant="outlined"
                      className={classes.fields}
                      required
                      autoFocus
                      fullWidth
                      value={this.state.amount}
                      type="number"
                      InputProps={{
                        inputProps: {
                          step: "any",
                          min: 0
                        },
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        )
                      }}
                      onChange={this.handleChange("amount")}
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.textMiddle}>
                      {"every"}
                    </Typography>
                  </Grid>
                  <Grid item md={1}>
                    <TextField
                      variant="outlined"
                      required
                      value={this.state.interval_count}
                      className={classes.field}
                      type="number"
                      onChange={this.handleChange("interval_count")}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="charge-type" />
                      <Select
                        native
                        required
                        value={this.state.interval}
                        onChange={this.handleChange("interval")}
                        input={<OutlinedInput name="interval" id="interval" />}
                      >
                        <option value="month">Month(s)</option>
                        <option value="week">Week(s)</option>
                        <option value="day">Day(s)</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    container
                    justify={"center"}
                    alignContent={"left"}
                    className={classes.content}
                    spacing={16}
                  >
                    <Grid item>
                      <Typography className={classes.text}>{"in"}</Typography>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <Select
                          native
                          required
                          value={this.state.currency}
                          onChange={this.handleChange("currency")}
                          input={
                            <OutlinedInput name="currency" id="currency" />
                          }
                        >
                          <Currencies />
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.textMiddle}>
                        {"with a free trial of "}
                      </Typography>
                    </Grid>
                    <Grid item md={1}>
                      <TextField
                        variant="outlined"
                        required
                        value={this.state.trial_length}
                        type="number"
                        onChange={this.handleChange("trial_length")}
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.textMiddle}>
                        {"days (put 0 for no trial period)."}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justify={"center"}
                    alignContent={"left"}
                    className={classes.content}
                    spacing={16}
                  >
                    <Grid item>
                      <Typography className={classes.textMiddle}>
                        {"The name of what I'm selling is "}
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <TextField
                        variant="outlined"
                        required
                        value={this.state.name}
                        type="text"
                        onChange={this.handleChange("name")}
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.textMiddle}>
                        {"and shows as"}
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <TextField
                        variant="outlined"
                        value={this.state.statement_descriptor}
                        type="text"
                        onChange={this.handleChange("statement_descriptor")}
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.text}>
                        {"on their bank statement."}
                      </Typography>
                    </Grid>
                  </Grid>
          
                </React.Fragment>
              ) : null}

              {this.state.step === 2 ? (
                <Grid
                  container
                  justify={"center"}
                  alignContent={"left"}
                  className={classes.content}
                  spacing={16}
                >
                  <Grid item md={2}>
                    <Typography variant="h6">Look and Feel</Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.enableDiscount}
                          onChange={() =>
                            this.setState({
                              enableDiscount: !this.state.enableDiscount
                            })
                          }
                          value=""
                          color="primary"
                        />
                      }
                      label="Enable Coupon Field"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.hide_address}
                          onChange={() =>
                            this.setState({
                              hide_address: !this.state.hide_address
                            })
                          }
                          value=""
                          color="primary"
                        />
                      }
                      label="Hide Address Fields"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.custom_logo}
                          onChange={() =>
                            this.setState({
                              custom_logo: !this.state.custom_logo
                            })
                          }
                          value=""
                          color="primary"
                        />
                      }
                      label="Custom Logo"
                    />
                    {this.state.custom_logo ? (
                      <TextField
                        label="Logo URL"
                        fullWidth
                        helperText="A url for a specific logo for this form. By default we use the one uploaded in settings > branding."
                        value={this.state.custom_logo_url}
                        type="text"
                        className={classes.textField}
                        onChange={this.handleChange("custom_logo_url")}
                      />
                    ) : null}

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.custom_labels}
                          onChange={() =>
                            this.setState({
                              custom_labels: !this.state.custom_labels
                            })
                          }
                          value=""
                          color="primary"
                        />
                      }
                      label="Custom Labels"
                    />
                    {this.state.custom_labels ? (
                      <div>
                        <TextField
                          label="First Name"
                          fullWidth
                          helperText="New text for the label First Name."
                          value={this.state.custom_label_first_name}
                          type="text"
                          className={classes.textField}
                          onChange={this.handleChange(
                            "custom_label_first_name"
                          )}
                        />
                        <TextField
                          label="Last Name"
                          fullWidth
                          helperText="New text for the label Last Name."
                          value={this.state.custom_label_last_name}
                          type="text"
                          className={classes.textField}
                          onChange={this.handleChange("custom_label_last_name")}
                        />
                        <TextField
                          label="Checkout Button"
                          fullWidth
                          helperText="Submit button text."
                          value={this.state.custom_label_checkout_button}
                          type="text"
                          className={classes.textField}
                          onChange={this.handleChange(
                            "custom_label_checkout_button"
                          )}
                        />

                        {this.state.type === "donation" ? (
                          <div>
                            <TextField
                              label="Donation Tab 1"
                              fullWidth
                              helperText="New text for the One Time Donation tab."
                              value={this.state.custom_label_donation_tab_1}
                              type="text"
                              className={classes.textField}
                              onChange={this.handleChange(
                                "custom_label_donation_tab_1"
                              )}
                            />
                            <TextField
                              label="Donation Tab 2"
                              fullWidth
                              helperText="New text for the Recurring Donation tab."
                              value={this.state.custom_label_donation_tab_2}
                              type="text"
                              className={classes.textField}
                              onChange={this.handleChange(
                                "custom_label_donation_tab_2"
                              )}
                            />
                            <TextField
                              label="One Time Helper Text"
                              fullWidth
                              helperText="New helper text for the One Time dropdown."
                              value={
                                this.state.custom_label_donation_helper_text_1
                              }
                              type="text"
                              className={classes.textField}
                              onChange={this.handleChange(
                                "custom_label_donation_helper_text_1"
                              )}
                            />
                            <TextField
                              label="Recurring Helper Text"
                              fullWidth
                              helperText="New helper text for the Recurring dropdown."
                              value={
                                this.state.custom_label_donation_helper_text_2
                              }
                              type="text"
                              className={classes.textField}
                              onChange={this.handleChange(
                                "custom_label_donation_helper_text_2"
                              )}
                            />
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.enableTerms}
                          onChange={() =>
                            this.setState({
                              enableTerms: !this.state.enableTerms
                            })
                          }
                          value=""
                          color="primary"
                        />
                      }
                      label="Enable Terms"
                    />
                    {this.state.enableTerms ? (
                      <TextField
                        label="Terms and Conditions"
                        fullWidth
                        helperText="URL of your terms and conditions"
                        value={this.state.link}
                        type="text"
                        className={classes.textField}
                        onChange={this.handleChange("termsLink")}
                      />
                    ) : null}
                  </Grid>

                  <Grid item md={2}>
                    <Typography variant="h6">Additional Fees</Typography>
                    {this.state.type !== "onetime" ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.enableTaxes}
                            onChange={() =>
                              this.setState({
                                enableTaxes: !this.state.enableTaxes
                              })
                            }
                            value=""
                            color="primary"
                          />
                        }
                        label="Enable Taxes"
                      />
                    ) : (
                      ""
                    )}
                    {this.state.enableTaxes ? (
                      <TextField
                        label="Tax Percentage"
                        fullWidth
                        helperText="Tax percentage you want applied to the subscription."
                        value={this.state.tax_percentage}
                        type="number"
                        InputProps={{
                          inputProps: {
                            step: "any",
                            min: 0
                          },
                          startAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          )
                        }}
                        className={classes.textField}
                        onChange={this.handleChange("tax_percentage")}
                      />
                    ) : null}

                    {this.state.type !== "onetime" ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.enableSetupFee}
                            onChange={() =>
                              this.setState({
                                enableSetupFee: !this.state.enableSetupFee
                              })
                            }
                            value=""
                            color="primary"
                          />
                        }
                        label="Enable Setup Fee"
                      />
                    ) : (
                      ""
                    )}
                    {this.state.enableSetupFee ? (
                      <TextField
                        label="Setup Fee Amount"
                        fullWidth
                        required
                        value={this.state.setup_fee}
                        helperText="Amount you want to charge once, upon signup."
                        type="number"
                        InputProps={{
                          inputProps: {
                            step: "any",
                            min: 0
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              {getSymbolFromCurrency(this.state.currency)}
                            </InputAdornment>
                          )
                        }}
                        className={classes.textField}
                        onChange={this.handleChange("setup_fee")}
                      />
                    ) : null}
                  </Grid>

                  <Grid item md={2}>
                    <Typography variant="h6">When to Bill</Typography>
                    {this.state.type !== "onetime" ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.enableAnchorDate}
                            onChange={() =>
                              this.setState({
                                enableAnchorDate: !this.state.enableAnchorDate
                              })
                            }
                            value=""
                            color="primary"
                          />
                        }
                        label="Enable Anchor Date"
                      />
                    ) : null}

                    {this.state.enableAnchorDate ? (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.prorated}
                              onChange={() =>
                                this.setState({
                                  prorated: !this.state.prorated
                                })
                              }
                              value=""
                              color="primary"
                            />
                          }
                          label="Prorate until Anchor"
                        />
                        <FormControl className={classes.formControl}>
                          <InputLabel>Anchor Date</InputLabel>
                          <Select
                            native
                            value={this.state.date_number}
                            onChange={this.handleChange("date_number")}
                            inputProps={{
                              name: "date_number",
                              id: "date_number"
                            }}
                          >
                            {potentialDays}
                          </Select>
                          <FormHelperText>
                            Sets billing to renew on this date.
                          </FormHelperText>
                        </FormControl>
                      </div>
                    ) : null}

                    {this.state.type !== "onetime" ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.enableInstallment}
                            onChange={() =>
                              this.setState({
                                enableInstallment: !this.state.enableInstallment
                              })
                            }
                            value=""
                            color="primary"
                          />
                        }
                        label="Enable Installment Plan"
                      />
                    ) : (
                      ""
                    )}
                    {this.state.enableInstallment ? (
                      <TextField
                        label="Installment Total"
                        fullWidth
                        required
                        value={this.state.installment_total}
                        helperText="Amount you want to collect before canceling."
                        type="number"
                        InputProps={{
                          inputProps: {
                            step: "any",
                            min: 0
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              {getSymbolFromCurrency(this.state.currency) ||
                                "$"}
                            </InputAdornment>
                          )
                        }}
                        className={classes.textField}
                        onChange={this.handleChange("installment_total")}
                      />
                    ) : null}
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant="h6">Custom Fields</Typography>
                    {customFieldsArray}
                    <Button
                      color="primary"
                      onClick={this.addField()}
                      className={classes.button}
                    >
                      Add Custom Field
                    </Button>
                  </Grid>
                </Grid>
              ) : null}

              {this.state.step === 3 ? (
                <React.Fragment>
                  <Grid item>
                    <Typography className={classes.textMiddle}>
                      {"I want to send my customers to "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Redirect URL"
                      type="text"
                      variant="outlined"
                      required
                      value={this.state.redirect_url}
                      className={classes.textFieldHalf}
                      helperText="Customers will be sent here upon completing the form."
                      onChange={this.handleChange("redirect_url")}
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.textMiddle}>
                      {
                        "after the form is completed. If left blank, we will send them to our thank you page."
                      }
                    </Typography>
                  </Grid>
                </React.Fragment>
              ) : null}
              <Grid item xs={12}>
                <Button
                  disabled={this.state.step === 0}
                  onClick={this.handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                >
                  {this.state.step === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
        >
          <MySnackbarContentWrapper
            variant={this.state.snackVariant}
            message={this.state.snackMessage}
            onClose={this.handleSnackClose}
          />
        </Snackbar>
      </NavBar2>
    );
  }
}

export default withStyles(styles)(EasyForm);
