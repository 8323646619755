import Paper from "@material-ui/core/Paper";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { CircularProgress } from "@material-ui/core";
import { useParams, useNavigate } from "react-router-dom";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "10%",
  },
  background: {
    //backgroundColor: "grey"
  },
  paper: theme.mixins.gutters({
    paddingTop: 50,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3,
  }),
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  button: {
    //width:00,
    margin: theme.spacing.unit,
  },
  bigAvatar: {
    width: 75,
    height: 75,
    backgroundColor: "#fff",
  },
  menu: {
    width: 100,
  },
  logo: {
    maxWidth: "60px",
    maxHeight: "60px",
  },
}));

const Login = (props) => {
  const params = useParams();
  console.log({ params });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    username: "test",
    password: "",
    messageError: "",
    usernameMessage: "Enter your username",
    passwordMessage: "Enter your password",
    usernameInvalid: false,
    passwordInvalid: false,
  });

  const classes = useStyles();

  const handleChange = (name, event) => {
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleClick = (event) => {
    setIsLoading(true);
    event.preventDefault();
    console.log(state);
    const payload = {
      username: state.username,
      password: state.password,
    };
    const loginUrl = params.org
      ? `${apiBaseUrl}/v1/org/${params.org}/login`
      : `${apiBaseUrl}/v1/org/login`;
    axios
      .post(loginUrl, payload)
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("org_handle", params.org || response.data.org);
          localStorage.setItem("timestamp", new Date().getTime());
          localStorage.setItem(
            "name",
            `${response.data.first_name} ${response.data.last_name}`
          );
          localStorage.setItem("createdAt", response.data.createdAt);
          localStorage.setItem("first_name", response.data.first_name);
          localStorage.setItem("last_name", response.data.last_name);
          localStorage.setItem(
            "gravatar",
            `https://www.gravatar.com/avatar/${response.data.gravatar}?d=mp`
          );
          localStorage.setItem("org", response.data.org);
          localStorage.setItem("org_id", response.data.org_id);
          localStorage.setItem("orgId", response.data.org_id);
          localStorage.setItem("admin_id", response.data.admin_id);

          let _hsq = (window._hsq = window._hsq || []);
          _hsq.push([
            "identify",
            {
              email: state.username,
            },
          ]);
          _hsq.push([
            "trackEvent",
            {
              id: "LoggedIn",
            },
          ]);
          setIsLoading(false);
          let path = `/dashboard`;
          window.location.href = path;
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setState({
          ...state,
          usernameMessage: "Incorrect Username or Password",
          passwordMessage: "Incorrect Username or Password",
          usernameInvalid: true,
          passwordInvalid: true,
        });
      });
  };
  return (
    <div className={classes.background}>
      <Grid container className={classes.root} justify={"center"}>
        <Paper className={classes.paper} elevation={4}>
          <Grid container justify={"center"}>
            <Avatar aria-label="Logo" className={classes.bigAvatar}>
              <img
                className={classes.logo}
                src={process.env.REACT_APP_LOGO_URL_1}
                alt="logo"
              />
            </Avatar>
          </Grid>

          <form
            className={classes.container}
            onSubmit={(event) => handleClick(event)}
          >
            <TextField
              label="Email"
              fullWidth
              required
              disabled={isLoading}
              error={state.usernameInvalid}
              className={classes.textField}
              helperText={state.usernameMessage}
              onChange={(e) => handleChange("username", e)}
            />

            <TextField
              label="Password"
              fullWidth
              required
              disabled={isLoading}
              error={state.passwordInvalid}
              type="password"
              className={classes.textField}
              helperText={state.passwordMessage}
              onChange={(e) => handleChange("password", e)}
            />

            <Grid container justify={"center"}>
              <br />
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading}
                fullWidth={true}
                className={classes.button}
                type="submit"
              >
                {isLoading ? <CircularProgress /> : "Login"}
              </Button>
            </Grid>
          </form>
          <Grid container justify="space-between" style={{ padding: 10 }}>
            <Grid item>
              <Typography variant="caption">
                <a
                  style={{ textDecorationLine: "none" }}
                  href={`/${this?.props?.match?.params?.org}/password/reset`}
                >
                  Forgot password?
                </a>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                <a style={{ textDecorationLine: "none" }} href={`/register3`}>
                  Don't have an account? Sign Up
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default Login;
