import React from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Currencies from '../../components/currencies';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import NavBar2 from '../../components/navigation/NavBar2';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Moment from 'react-moment';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import getSymbolFromCurrency from 'currency-symbol-map';
import Snackbar from '@material-ui/core/Snackbar';
import Directions from '../../components/directions/Directions';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;
Moment.globalFormat = 'MMM D YYYY';

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar,
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    width: "80%",
    marginLeft: "8%",
    marginRight: "8%",
    marginTop: theme.spacing.unit * 3,
  }),
  button:{
    marginTop: theme.spacing.unit * 2
  },
  textField:{
    marginTop: theme.spacing.unit
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

class UpdateChargeInstructions extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      name: "",
      description:"",
      amount:0,
      redirect_url:"",
      plan_ids: "",
      instructionType: "oneTime",
      allowPassThrough: false,
      products: [],
      selectedPlans: [],
      enableInstallment: false
    };
  }

  changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`
    this.props.history.push(path);
  };

 handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
};

handleCheckboxChange = name => event => {
  this.setState({
    [name]: !this.state[name],
  });
};

handleSnackClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  this.setState({ snackOpen: false });
};


fetchChargeInstruction = () => {
    var self = this;
    console.log(this.state)
    axios({
     method: 'get',
     url: `${apiBaseUrl}/v1/org/chargeinstructions/${self.props.match.params.chargeInstructionId}`,
     headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
   })
    .then(function (response) {
      console.log(response.data);
      if(response.status === 200){
        self.setState({
        name: response.data.name,
        description: response.data.description,
        currency: response.data.currency,
        amount: response.data.amount_in_cents/100,
        redirect_url: response.data.redirect_url,
        allowPassThrough: response.data.allow_frontend_passthrough
        });
      }})
    .catch(function (error) {
      console.log(error);
      self.setState({messageError:"Error retrieving forms"})
    });
}

fetchProducts = () => {
    var self = this;
    console.log(this.state)
    axios({
     method: 'get',
     url: `${apiBaseUrl}/v1/org/products`,
     headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
   })
    .then(function (response) {
      console.log(response.data);
      if(response.status === 200){
      const filteredProducts = response.data.filter(product => product.plans && product.plans.length > 0) //makes sure only producs wth plans are shown
        self.setState({
        products: filteredProducts
        });
        console.log(self.state)
      }})
    .catch(function (error) {
      console.log(error);
      self.setState({messageError:"Error retrieving forms"})
    });
}

fetchRecurringInstruction = () => {
    var self = this;
    console.log(this.state)
    axios({
     method: 'get',
     url: `${apiBaseUrl}/v1/org/recurringinstructions/${self.props.match.params.chargeInstructionId}`,
     headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
   })
    .then(function (response) {
      console.log(response.data);
      if(response.status === 200){
        self.setState({
        name: response.data.name,
        description: response.data.description,
        plan_ids: response.data.plan_ids.join(","), //this is expected to be a string not an array
        selectedPlans: response.data.plan_ids,
        redirect_url: response.data.redirect_url,
        enableInstallment: response.data.installment_plan ? response.data.installment_plan.active : false,
        installment_total: response.data.installment_plan ? response.data.installment_plan.amount_in_cents/100 : 0,
        });
      }})
    .catch(function (error) {
      console.log(error);
      self.setState({messageError:"Error retrieving forms"})
    });
}

updateChargeInstruction = (event) =>{
  event.preventDefault();
  var self = this;
  axios({
   method: 'PUT',
   url: `${apiBaseUrl}/v1/org/chargeinstructions/${self.props.match.params.chargeInstructionId}`,
   headers: {
     'Authorization': 'Bearer ' + localStorage.getItem('token'),
     'Content-Type' : 'application/json'
   },
   data:{
     name: this.state.name,
     description: this.state.description,
     currency: this.state.currency,
     amount_in_cents: this.state.amount * 100,
     redirect_url: this.state.redirect_url,
     allow_frontend_passthrough: this.state.allowPassThrough
   }
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      self.setState({
        snackOpen: true,
        snackVariant:"success",
        snackMessage:"Your plan has been updated."
      });

      let path = `/chargeinstructions/onetime`
      self.props.history.push(path);
    }})
  .catch(function (error) {
    self.setState({
        snackOpen: true,
        snackVariant:"error",
        activeStep: 0,
        snackMessage: error.response.data.message
      })
  });
}

updateRecurringInstruction = (event) =>{
  event.preventDefault();

  const reformattedPlanIds = [];
  const arrayOfIds = this.state.plan_ids.split(",");
  for (let id of arrayOfIds) {
    let trimmedId = id.trim();
    reformattedPlanIds.push(trimmedId);
  }

  var self = this;
  axios({
   method: 'PUT',
   url: `${apiBaseUrl}/v1/org/recurringinstructions/${self.props.match.params.chargeInstructionId}`,
   headers: {
     'Authorization': 'Bearer ' + localStorage.getItem('token'),
     'Content-Type' : 'application/json'
   },
   data:{
     name: this.state.name,
     description: this.state.description,
     plan_ids: this.state.selectedPlans,
     redirect_url: this.state.redirect_url,
     installment_plan:{
       active: this.state.enableInstallment,
       amount_in_cents: this.state.installment_total * 100
     },
   }
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      self.setState({
        snackOpen: true,
        snackVariant:"success",
        snackMessage:"Your plan has been updated."
      });

      let path = `/chargeinstructions/recurring`
      self.props.history.push(path);
    }})
  .catch(function (error) {
    self.setState({
        snackOpen: true,
        snackVariant:"error",
        activeStep: 0,
        snackMessage: error.response.data.message
      })
  });
}


componentDidMount(event){
  if (this.props.match.params.type === "recurring") {
    this.setState({
      instructionType: "recurring"
      })
    this.fetchRecurringInstruction();
    this.fetchProducts();
  }

  if (this.props.match.params.type === "onetime") {
    this.setState({
      instructionType: "oneTime"
      })
    this.fetchChargeInstruction();
  }
}

render() {
  const { classes, theme, ...other } = this.props;


    const productArray =[];

        for (let product of this.state.products) {
          let plans =[]
          productArray.push(
            <MenuItem disabled key={ product.id} value={product.name}>
            <ListItemText primary={product.name} />
            </MenuItem>
          )
          for (let plan of product.plans) {
            productArray.push(
              <MenuItem key={ plan.id} value={plan.id}>
              <Checkbox checked={this.state.selectedPlans.indexOf(plan.id) > -1} />
              <ListItemText primary={plan.nickname || plan.id} />
              </MenuItem>
            )
          }
        }

    return (

      <NavBar2 header="Post Link Update Form" {...other}>
      <Directions
      message="Update your Embedded Checkout to change what happens when the form is submitted."
      />

          <Paper className={classes.paper}>
          { this.state.instructionType === "oneTime" ?
          <form onSubmit={this.updateChargeInstruction}>
          <TextField
          label="Name"
          fullWidth
          required
          type="text"
          value={this.state.name}
          helperText="Name so you can easily identify the link."
          className={classes.textField}
          onChange={this.handleChange('name')}
          />
          <TextField
          label="Description"
          fullWidth
          required
          type="text"
          value={this.state.description}
          helperText="This will be the description sent to Stripe for the charge."
          className={classes.textField}
          onChange={this.handleChange('description')}
          />
          <TextField
          label="Amount to Charge"
          fullWidth
          required
          value={this.state.amount}
          helperText="Amount you want Stripe to charge the customer when they complete your checkout."
          type="number"
          InputProps={{
            inputProps: {
            step: "any",
            min:0
          },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          className={classes.textField}
          onChange={this.handleChange('amount')}
          />
          <FormControl className={classes.formControl}>
          <InputLabel htmlFor="currency">Currency</InputLabel>
          <Select
          native
          value={this.state.currency}
          onChange={this.handleChange('currency')}
          inputProps={{
            name: 'currency',
            id: 'currency',
          }}

          >
          <Currencies />
          </Select>
          <FormHelperText>The currency you want the charge processed in.</FormHelperText>
          </FormControl>
          <TextField
          label="Redirect Url"
          fullWidth
          type="text"
          value={this.state.redirect_url}
          helperText="The URL you want us to send the customer to when the checkout is complete."
          className={classes.textField}
          onChange={this.handleChange('redirect_url')}
          />
          <FormControlLabel
                   control={
                     <Checkbox
                       checked={this.state.allowPassThrough}
                       onChange={this.handleCheckboxChange('allowPassThrough')}

                       color="primary"
                     />
                   }
                   label="Allow Frontend Pass Through"
                 />
                 <FormHelperText>This allows you to pass price, redirect and charge description through the frontend form. This is less secure and isn't needed by most customers.</FormHelperText>
          <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          className={classes.button}
          type="submit"
          >
          Update One Time Stripe Form URL
          </Button>
          </form>
          : null}

          { this.state.instructionType === "recurring" ?
          <form onSubmit={this.updateRecurringInstruction}>
          <TextField
          label="Name"
          fullWidth
          required
          type="text"
          helperText="Name so you can easily identify the link."
          value={this.state.name}
          className={classes.textField}
          onChange={this.handleChange('name')}
          />
          <TextField
          label="Description"
          fullWidth
          required
          type="text"
          helperText="Internal description."
          value={this.state.description}
          className={classes.textField}
          onChange={this.handleChange('description')}
          />
          <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-multiple-checkbox">Plans</InputLabel>
          <Select
          multiple
          value={this.state.selectedPlans}
          onChange={this.handleChange('selectedPlans')}
          renderValue={selected => (
            <div className={classes.chips}>
                   {selected.map(value => (
                     <Chip key={value} label={value} className={classes.chip} />
                   ))}
                 </div>
               )}
           MenuProps={MenuProps}
          >
          {productArray}
          </Select>
          </FormControl>
          <FormHelperText>Select the Stripe plan(s) you want customers subscribed to.</FormHelperText>

          <TextField
          label="Redirect Url"
          fullWidth
          type="text"
          helperText="The URL you want us to send the customer to when the checkout is complete."
          value={this.state.redirect_url}
          className={classes.textField}
          onChange={this.handleChange('redirect_url')}
          />
          <div>
          <FormControlLabel
          control={
            <Checkbox
            checked={this.state.enableInstallment}
            onChange={()=> this.setState({enableInstallment: !this.state.enableInstallment})}
            value={this.state.enableInstallment}
            color="primary"
            />
          }
          label="Enable Installment Plan"
          />
          {this.state.enableInstallment ?
            <TextField
            label="Installment Total"
            fullWidth
            required
            value={this.state.installment_total}
            helperText="Amount you want to collect before canceling."
            type="number"
            InputProps={{
              inputProps: {
              step: "any",
              min:0
            },
              startAdornment: <InputAdornment position="start">{getSymbolFromCurrency(this.state.currency)  || "$"}</InputAdornment>,
            }}
            className={classes.textField}
            onChange={this.handleChange('installment_total')}
            />
          : null
          }
          </div>
          <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          className={classes.button}
          type="submit"
          >
          Update Recurring Stripe Form URL
          </Button>
          </form>
          : null}
        </Paper>

        <Snackbar
           anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'center',
           }}
           open={this.state.snackOpen}
           autoHideDuration={3000}
           onClose={this.handleSnackClose}
           >
             <MySnackbarContentWrapper
             variant={this.state.snackVariant}
             message={this.state.snackMessage}
             onClose={this.handleSnackClose}
            />
        </Snackbar>
      </NavBar2>
    );
  }
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(UpdateChargeInstructions);
