import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import NavBar2 from '../../components/navigation/NavBar2';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
import YouTube from 'react-youtube';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";


const styles = theme => ({

paper:{
  paddingTop: "5%",
  paddingBottom: "5%"
}

});

class StartGuide extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    name: "",
    activeStep: 0,
    steps: ['What is InstaPayments?', 'Branding', 'Handling Signups', 'Customer Self Management', 'Questions and Assistance']
    };
  }

  handleNext = () => {
     this.setState(state => ({
       activeStep: state.activeStep + 1,
     }));
   };

   handleBack = () => {
     this.setState(state => ({
       activeStep: state.activeStep - 1,
     }));
   };

componentDidMount(event){

}



render() {
  const { classes, theme, ...other } = this.props;
  const steps = this.state.steps;
  const { activeStep } = this.state;

  console.log(this.state, steps.length)
    return (
        <NavBar2 {...this.props}>
          <Paper className={classes.paper}>
          <Grid container justify={"center"}>
          <div className={classes.video}>
                   <YouTube
                   videoId="ENbYLzEifEE"
                   opts={{
                     height: '600',
                     width: '840',
                     playerVars: { // https://developers.google.com/youtube/player_parameters
                       autoplay: 1
                     }
                   }}
                   onReady={this._onReady}
                   />
                </div>
             </Grid>
    {/*      <form onSubmit={this.createForm}>
          <Stepper activeStep={activeStep} orientation="vertical">
     {steps.map((label, index) => {
       return (



         <Step key={label}>
         <StepLabel>{label}</StepLabel>
         <StepContent>


           {this.state.activeStep === 0 ?
             <div>
             <Typography>
             InstaPayments aims to get your business easily signing up customers with no code.<br/>
             Once they're signed up, we provide ways for them to update their stored card(s) on file and manage their subscriptions.
             </Typography>
             </div>
           : null}

           {this.state.activeStep === 1 ?
             <div>
             <Typography>
             <strong>
             Forms and the User Portal look much better with a logo and your colors.

             </strong>
             </Typography>
             <Typography>
             <ol>
             <li>Head over to Settings > Branding </li>
             <li>The main things to change are the Primary color and uploading a logo.</li>
             <li>Use the color picker to select your brands main color and hit the update button.</li>
             <li>Scroll down the page a bit to the logo upload section and grab an img file from your computer.</li>
             <li>The file uplaod will begin automatically when the logo file is selcted. Be sure not to close the page until the logo is finished uploading.</li>
             <li>If you've defined a form, you can view/refresh the form to see your logo added and button color changed.</li>
             </ol>
             </Typography>
             </div>
             : null }

           {this.state.activeStep === 2 ?
             <div>
             <Typography>
             <strong>There are two ways to sign up customers with InstaPayments. The first and easiest is using our Hosted Forms.</strong>
             </Typography>
             <Typography>
               <ol>
                 <li>To create a form, you'll need to have defined your the pricing of your plan in your Stripe account. You can do this <a target="_blank" rel="noopener noreferrer" href="https://dashboard.stripe.com/subscriptions/products">here. </a></li>
                 <li>Once you've got your plan(s) ready to go, head over to our Hosted Forms and click the "+" button in the bottom right and follow the wizard.</li>
                 <li>Your form is now created. You should now see it in the list of your forms. Click view.</li>
                 <li>When viewing your form, you can copy and paste the URL from your browser and put this anywhere your customers could click this. </li>
                 <li>The URL you just copied, will not change and is usually on your website behind a button, sent via email etc.</li>
               </ol>
             </Typography>
             <Typography>
             <strong>
             The second way of signing up customers. Uses Stripe's embedded checkout. This requires you have a website where you can add some HTML.
             <br />(You won't need to know how to write any code. Just be a copy and paste pro.)
             </strong>
             </Typography>
             <Typography>
               <ol>
                 <li>This method also requires you to have your plans defined in Stripe. You can do this <a target="_blank" rel="noopener noreferrer" href="https://dashboard.stripe.com/subscriptions/products">here. </a></li>
                 <li>Once you've got your plan(s) ready to go, head over to our Embedded Checkout sections and click the "+" button in the bottom right and fill out the form.</li>
                 <li>After you hit save, you'll be taken to the edit and preview screen. Change the fields and upload your logo until it looks the way you want. You can test it in CodePen as well.</li>
                 <li>Now that it's looking how you want, hit the copy code button or manually copy the code to your clipboard. Don't close the tab yet, since we don't store your frontend customizations for the embedded checkout.</li>
                 <li>Go to your website and paste this HTML wherever you want the button to be.</li>
                 <li>Be sure to try it with a live card before you release it. You can always refund yourself.</li>
               </ol>
             </Typography>

             </div>
             : null}

             {this.state.activeStep === 3 ?
               <div>
               <Typography>
               <strong>
                Many InstaPayments merchants only need easy card updates for their customers instead of a full subscription management portal.
                <br />
                If that's all you need, we recomend using our update forms.
               </strong>
               </Typography>
               <Typography>
               <ol>
               <li>
               This works by your customers entering their email on a form and if they're a customer in your Stripe account they recieve a secure card update link via email.
               </li>
               <li>
               The link to your card update retrieval form is: <a href={`https://forms.${process.env.REACT_APP_BASE_DOMAIN}/find/${localStorage.getItem('org')}/${localStorage.getItem('org_id')}`} target="_blank"> {`https://forms.instapayments.io/find/${localStorage.getItem('org')}/${localStorage.getItem('org_id')}`}</a>
               </li>
               <li>
               Copy the link above and add it the spot where customers would look to update their Card.
               </li>
               </ol>
               </Typography>

               <Typography>
               <strong>
                If you require full subscription management, you'll probably want to utilize our User Portal.
               </strong>
               </Typography>
               <Typography>
               <ol>
               <li>
               The user portal can be accessed by your customers at this link: <a href={`https://users.${process.env.REACT_APP_BASE_DOMAIN}/${localStorage.getItem('org')}/login`} target="_blank"> {`https://users.instapayments.io/${localStorage.getItem('org')}/login/link`}</a>
               </li>
               <li>
               We automatically find customers when a user logs in for the first time. You can also manually add Stripe customer profiles to that User or re-run the auto match by heading to the Users tab in InstaPayments.
               </li>
               <li> A user can have access to many Stripe customers in case you have duplicates or multiple offerings.</li>
               <li> Once inside, a user can see past statements, cancel/reactivate subscriptions, update cards etc.</li>
               </ol>
               </Typography>
               </div>
              : null }

              {this.state.activeStep === 4 ?
                <div>
                <Typography>
                <strong>Questions?</strong>
                </Typography>
                <Typography>
                The easiest way to get quick help is to use our chat. If the problem is hard to solve that way, our team can get on screenshare and help workout the kinks.
                </Typography>
                <Typography>
                <strong>New features</strong>
                </Typography>
                <Typography>
                We are constantly adding new functionality to this product. If you want something, we can probably add it for you just send us a quick message.
                </Typography>
                <Typography>
                <strong>Custom Development</strong>
                </Typography>
                <Typography>
                We know this product won't cover every use case. That's why we offer dev assistance if you have unique needs.
                Here are some common use cases we've covered
                <ol>
                <li>Custom integrations with Stripe (sending data to your CRM etc.)</li>
                <li>Updating Stripe with usage from a database or service.</li>
                <li>Custom signup forms and checkouts</li>
                <li>Custom user portal that ties into your specific product or service.</li>
                </ol>
                </Typography>
                <Typography>
                These are the most common requests, but we've handled many others.
                </Typography>
                </div>
              : null}

               <div className={classes.actionsContainer}>
               <Button
               disabled={activeStep === 0}
               onClick={this.handleBack}
               className={classes.button}
               >
               Back
               </Button>
               {this.state.activeStep !== this.state.steps.length-1 ?
               <Button
               color="primary"
               onClick={this.handleNext}
               className={classes.button}
               >
                {this.state.activeStep !== this.state.steps.length-1 ? "Next" : "Go to Dashboard"}
               </Button>
               :
              <Button
               color="primary"
               className={classes.button}
               href={`https://orgs.${process.env.REACT_APP_BASE_DOMAIN}/dashboard`}
               >
               Go to Dashboard
               </Button>}
               </div>
               </StepContent>
               </Step>
       );
     })}
   </Stepper>

   </form> */}
  </Paper>


      <Snackbar
           anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'center',
           }}
           open={this.state.snackOpen}
           autoHideDuration={3000}
           onClose={this.handleSnackClose}
           >
             <MySnackbarContentWrapper
             variant={this.state.snackVariant}
             message={this.state.snackMessage}
             onClose={this.handleSnackClose}
            />
        </Snackbar>
        </NavBar2>
    );
  }
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(StartGuide);
