import Paper from "@material-ui/core/Paper";
import React from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavigationList from "../../components/navigation/NavigationList";
import NavBar2 from "../../components/navigation/NavBar2";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import YouTube from "react-youtube";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = (theme) => ({
  paper: theme.mixins.gutters({
    paddingBottom: 10,
    width: "40%",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    marginTop: theme.spacing.unit * 3,
  }),
  linkPaper: theme.mixins.gutters({
    paddingBottom: 10,
    width: "100%",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    position: "relative",
    marginTop: theme.spacing.unit * 3,
  }),

  paperHeader: theme.mixins.gutters({
    paddingTop: 10,
    paddingBottom: 10,
    //width: "80%",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    marginTop: theme.spacing.unit * 3,
  }),
  link: {
    marginTop: 20,
  },
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        customers: [],
      },
      org: {
        metrics: {
          customer_count: 100,
          user_count: 100,
        },
      },
      finished: false,
    };
  }

  changeNav = (name) => (event) => {
    let path = `/${this.props.match.params.org}/${name}`;
    this.props.history.push(path);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  componentDidMount(event) {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/me`, //retrieves the current authenticated user
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        if (response.status === 200) {
          self.setState({
            org: response.data,
            finished: true,
          });
        } else {
          let path = `/${self.state.org_handle}/login`;
          self.props.history.push(path);
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving user profile" });
      });
  }

  render() {
    const { classes, ...other } = this.props;
    const customers = [];
    const portalLink = `https://users.${
      process.env.REACT_APP_BASE_DOMAIN
    }/${localStorage.getItem("org")}/secure/login`;
    console.log(customers);
    console.log("customers array", customers);
    return (
      <NavBar2 {...other}>
        <Grid container spacing={24}>
          <Paper className={classes.linkPaper} elevation={4}>
            <AppBar
              position="static"
              className={classes.paperAppBar}
              color="secondary"
            >
              <Toolbar>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                >
                  User Portal Link
                </Typography>
              </Toolbar>
            </AppBar>
            <br />
            <Typography
              style={{ marginTop: "2%" }}
              variant="body"
              color="inherit"
              className={classes.flex}
            >
              <Tooltip title="Users can access the user portal here.">
                <a href={portalLink} target="_blank">
                  {" "}
                  {portalLink}
                </a>
              </Tooltip>
            </Typography>
            <Grid container justify={"center"}>
              <CopyToClipboard
                text={portalLink}
                onCopy={() => this.setState({ copiedRegister: true })}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                >
                  {this.state.copiedRegister ? "Copied" : "Copy Link"}
                </Button>
              </CopyToClipboard>
            </Grid>
          </Paper>

          <Paper className={classes.linkPaper} elevation={4}>
            <AppBar
              position="static"
              className={classes.paperAppBar}
              color="secondary"
            >
              <Toolbar>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                >
                  Card Update URL
                </Typography>
              </Toolbar>
            </AppBar>
            <br />
            <Typography
              style={{ marginTop: "2%" }}
              variant="body"
              color="inherit"
              className={classes.flex}
            >
              <Tooltip title="Users can retrieve their unique card update link without logging in.">
                <a
                  href={`https://forms.${
                    process.env.REACT_APP_BASE_DOMAIN
                  }/find/${localStorage.getItem("org")}/${localStorage.getItem(
                    "org_id"
                  )}`}
                  target="_blank"
                >
                  {" "}
                  {`https://forms.${
                    process.env.REACT_APP_BASE_DOMAIN
                  }/find/${localStorage.getItem("org")}/${localStorage.getItem(
                    "org_id"
                  )}`}
                </a>
              </Tooltip>
            </Typography>

            <Grid container justify={"center"}>
              <CopyToClipboard
                text={`https://forms.${
                  process.env.REACT_APP_BASE_DOMAIN
                }/find/${localStorage.getItem("org")}/${localStorage.getItem(
                  "org_id"
                )}`}
                onCopy={() => this.setState({ copiedFinder: true })}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                >
                  {this.state.copiedFinder ? "Copied" : "Copy Link"}
                </Button>
              </CopyToClipboard>
            </Grid>
          </Paper>
          {new Date(this.state.org.createdAt).getTime() <
            new Date("2022-03-10").getTime() && (
            <Paper className={classes.linkPaper} elevation={4}>
              <AppBar
                position="static"
                className={classes.paperAppBar}
                color="secondary"
              >
                <Toolbar>
                  <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.flex}
                  >
                    User Portal Link (legacy)
                  </Typography>
                </Toolbar>
              </AppBar>
              <br />
              <Typography
                style={{ marginTop: "2%" }}
                variant="body"
                color="inherit"
                className={classes.flex}
              >
                <Tooltip title="Users can access the user portal here.">
                  <a
                    href={`https://users.${
                      process.env.REACT_APP_BASE_DOMAIN
                    }/${localStorage.getItem("org")}/login/link`}
                    target="_blank"
                  >
                    {" "}
                    {`https://users.${
                      process.env.REACT_APP_BASE_DOMAIN
                    }/${localStorage.getItem("org")}/login/link`}
                  </a>
                </Tooltip>
              </Typography>
              <Grid container justify={"center"}>
                <CopyToClipboard
                  text={`https://users.${
                    process.env.REACT_APP_BASE_DOMAIN
                  }/${localStorage.getItem("org")}/login/link`}
                  onCopy={() => this.setState({ copiedRegister: true })}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                  >
                    {this.state.copiedRegister ? "Copied" : "Copy Link"}
                  </Button>
                </CopyToClipboard>
              </Grid>
            </Paper>
          )}
        </Grid>
      </NavBar2>
    );
  }
}

export default withStyles(styles)(Dashboard);
