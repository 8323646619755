import React from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavBar2 from "../../components/navigation/NavBar2";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Input from "@material-ui/core/Input";
import { styles } from "./NotificationsPageStyles.js";
import SearchIcon from "@material-ui/icons/Search";
import Directions from "../../components/directions/Directions";

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 10,
      notifications: [],
      searchValue: "",
      firstLoadAttempted: false,
    };
  }

  changeNav = (name) => (event) => {
    let path = `/${this.props.match.params.org}/${name}`;
    this.props.history.push(path);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  nextPage = (event) => {
    this.loadNotifications(
      Number(this.state.page) + 1,
      Number(this.state.rowsPerPage)
    );
    this.setState({
      page: this.state.page + 1,
    });
  };

  previousPage = (event) => {
    this.loadNotifications(
      Number(this.state.page) - 1,
      Number(this.state.rowsPerPage)
    );
    this.setState({
      page: this.state.page - 1,
    });
  };

  componentDidMount(event) {
    console.log(this.state);
    this.loadNotifications(
      Number(this.state.page),
      Number(this.state.rowsPerPage)
    );
  }

  deleteNotification = (notificationId, notificationName) => {
    var self = this;
    console.log(notificationId);
    if (
      window.confirm(
        `Are you sure you want to delete the notification ${notificationName}`
      )
    ) {
      axios({
        method: "delete",
        url: `${apiBaseUrl}/v1/org/notifications/triggers/${notificationId}`,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then(function (response) {
          console.log(response.data);
          if (response.status === 200) {
            self.loadNotifications();
          }
        })
        .catch(function (error) {
          console.log(error);
          self.setState({ messageError: "Error retrieving forms" });
        });
    }
  };

  loadNotifications = (page, per_page) => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/notifications/triggers?page=${page}&per_page=${per_page}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            notifications: response.data,
            finished: true,
            firstLoadAttempted: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({
          messageError: "Error retrieving notifications",
          firstLoadAttempted: true,
        });
      });
  };

  render() {
    const { classes, ...other } = this.props;
    const notifications = [];
    console.log(this.state);

    for (let notification of this.state.notifications) {
      notifications.push(
        <TableRow key={notification._id}>
          <TableCell>{notification.name}</TableCell>
          <TableCell>{notification.trigger_event}</TableCell>
          <TableCell>{String(notification.active)}</TableCell>
          <TableCell>
            {moment(new Date(notification.createdAt)).format("MM/DD/YYYY")}
          </TableCell>
          <TableCell>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              href={`/notifications/triggers/update/${notification._id}`}
            >
              Edit Notification
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() =>
                this.deleteNotification(notification._id, notification.name)
              }
            >
              Delete
            </Button>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <NavBar2 header="Notifications" {...this.props}>
        <Directions
          message={`Turn email notifications on or off on this page. All email notifications send an email template. 
      To customize, the wording of an email go to templates.`}
        />
        {this.state.firstLoadAttempted ? (
          <div>
            <div className={classes.toolbar} />
            {this.state.notifications.length > 0 ? (
              <Paper>
                <div>
                  <AppBar position="static">
                    <Toolbar>
                      <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.grow}
                      >
                        Create or manage your email notifications.
                      </Typography>
                      <Button
                        variant="outlined"
                        color="inherit"
                        href="/notifications/triggers/new"
                      >
                        Create a Notification
                      </Button>
                    </Toolbar>
                  </AppBar>
                </div>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Trigger Event</TableCell>
                      <TableCell>Active?</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Preview/Update</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{notifications}</TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell>Page {this.state.page}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell>
                        <Button
                          size="small"
                          variant="email"
                          color="primary"
                          onClick={(event) => this.previousPage(event)}
                          className={classes.paginationButton}
                          disabled={this.state.page <= 1}
                        >
                          Previous
                        </Button>
                        <Button
                          size="small"
                          variant="email"
                          color="primary"
                          onClick={(event) => this.nextPage(event)}
                          className={classes.paginationButton}
                          disabled={this.state.notifications.length < 10}
                        >
                          Next
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </Paper>
            ) : (
              <div>
                <Grid container justify={"center"}>
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="headline"
                          component="h2"
                        >
                          You don't have a notification yet!
                        </Typography>
                        <Typography component="p">
                          Notifications are used to send notifications to
                          yourself and your customers via email.
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button
                        variant="email"
                        color="primary"
                        href="/notifications/triggers/new"
                      >
                        Create a Notification
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </div>
            )}
          </div>
        ) : (
          <Grid container justify={"center"}>
            <div className={classes.loader}>
              <CircularProgress size={100} className={classes.progress} />
            </div>
          </Grid>
        )}
      </NavBar2>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Notifications);
