import React from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavBar2 from "../../components/navigation/NavBar2";
import Paper from "@material-ui/core/Paper";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Input from "@material-ui/core/Input";
import SearchIcon from "@material-ui/icons/Search";
import Snackbar from '@material-ui/core/Snackbar';
import MenuItem from '@material-ui/core/MenuItem';
import Directions from '../../components/directions/Directions';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
import SimpleMDE from "react-simplemde-editor";
import TextField from "@material-ui/core/TextField";
import "easymde/dist/easymde.min.css";

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = theme => ({
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    paddingTop: 30,
    marginTop: theme.spacing.unit * 2
  })
});

class NewTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: 1,
      rowsPerPage: 10,
      templates: [],
      notification_emails:[],
      searchValue: "",
      firstLoadAttempted: false,
      templateText: ""
    };
  }

  componentDidMount(event) {
    this.fetchOrg();
  }

  fetchOrg = () => {
    var self = this;
    console.log("templateId", self.props.match.params.templateId);
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/me`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            notification_emails: response.data.notification_emails,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving forms" });
      });
  };


  createTemplate = event => {
    event.preventDefault();
    var self = this;
    axios({
      method: "POST",
      url: `${apiBaseUrl}/v1/org/templates/email/new`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json"
      },
      data: {
        name: this.state.name,
        email_subject: this.state.email_subject,
        email_body: this.state.email_body,
        type: "html"
      }
    })
      .then(function(response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            snackOpen: true,
            snackVariant: "success",
            snackMessage: "Your template has been created."
          });

          let path = `/notifications/templates/update/${response.data._id}`;
          self.props.history.push(path);
        }
      })
      .catch(function(error) {
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          snackMessage:
            "Error creating template. Be sure all the fields are filled in."
        });
      });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleTemplateChange = value => {
    this.setState({ email_body: value });
  };

  render() {
    const { classes, ...other } = this.props;
    const fromAddresses = [];
    for (const emailObject of this.state.notification_emails) {
      if (emailObject.verification_status == "Success") {
        fromAddresses.push(
          <MenuItem 
          value={emailObject.email}
          key={emailObject._id}>
            {emailObject.email}
          </MenuItem>
        )
      }
    }

    console.log(this.state);
    return (
      <NavBar2 header="New Template" {...this.props}>
        <Paper className={classes.paper}>
          <TextField
            variant="outlined"
            label="Template Name"
            helperText="Internal name so you can easily identify it."
            fullWidth
            value={this.state.name}
            onChange={this.handleChange("name")}
            style={{
              paddingBottom: 20
            }}
          />
          <TextField
            variant="outlined"
            label="Email Subject"
            fullWidth
            value={this.state.email_subject}
            onChange={this.handleChange("email_subject")}
            style={{
              paddingBottom: 10
            }}
          />
          <SimpleMDE
            onChange={this.handleTemplateChange}
            value={this.state.email_body}
            options={{
              autofocus: true,
              spellChecker: false,
              sanitize: true,
              preview: false,
              hideIcons: ["preview", "fullscreen", "|", "side-by-side"],
              toolbar: [
                "bold",
                "italic",
                "heading",
                "|",
                "quote",
                "unordered-list",
                "ordered-list",
                "table",
                "|",
                "link",
                "image",
                "|",
                "guide"
              ]
              // etc.
            }}
          />
          <Button
            variant="contained"
            onClick={this.createTemplate}
            color="primary"
          >
            Save and Preview
          </Button>
        </Paper>
      </NavBar2>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(NewTemplate);
