import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  CssBaseline,
  CircularProgress,
  Backdrop,
  Container,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Button,
  AppBar,
  Toolbar,
  Chip,
} from "@material-ui/core";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { listCheckouts } from "../../apirequests/checkouts";
import NavBar2 from "../../components/navigation/NavBar2";

const baseUrl =
  process.env.REACT_APP_BASE_URL || process.env.REACT_APP_DEV_ENDPOINT;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formHeader: { marginBottom: theme.spacing(2) },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ModeChips = ({ mode }) => {
  if (mode === "subscription") {
    return (
      <Chip
        label="Subscription"
        variant="outlined"
        style={{ borderColor: "green", color: "green" }}
      />
    );
  }
  if (mode === "setup") {
    return (
      <Chip
        label="Setup"
        variant="outlined"
        style={{ borderColor: "#007FFF", color: "#007FFF" }}
      />
    );
  }
  if (mode === "payment") {
    return (
      <Chip
        label="One Time Payment"
        variant="outlined"
        style={{ borderColor: "#814ff8", color: "#814ff8" }}
      />
    );
  }
};

const Checkouts = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [checkouts, setCheckouts] = useState([]);
  const handleCheckoutFetching = async () => {
    let { data: checkouts } = await listCheckouts();
    setCheckouts(checkouts);
  };

  useEffect(() => {
    handleCheckoutFetching();
  }, []);

  const handleCopyCheckoutUrl = (checkout) => {
    navigator.clipboard.writeText(
      `https://forms.instapayments.io/checkout/${checkout._id}`
    );
    enqueueSnackbar("Permanent Checkout URL copied to clipboard", {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };
  const handleDeleteCheckout = async (checkoutId) => {
    if (window.confirm("Are you sure you want to delete this checkout?")) {
      let request = await axios({
        method: "delete",
        url: `${baseUrl}/v2/org/checkouts/${checkoutId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      handleCheckoutFetching();
    }
  };

  return (
    <NavBar2 pageName="Checkouts" pageDescription="List of Checkouts.">
      <Container component="main">
        <CssBaseline />
        <TableContainer component={Paper}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
                Checkouts - Create or manage your Stripe Checkouts.
              </Typography>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => navigate("/stripecheckouts/new")}
              >
                Create a New Checkout
              </Button>
            </Toolbar>
          </AppBar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nickname</TableCell>
                <TableCell>Mode</TableCell>
                <TableCell>Submit Type</TableCell>
                <TableCell>Line Items</TableCell>
                <TableCell>Cancel URL</TableCell>
                <TableCell>Manage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {checkouts.map((checkout) => (
                <TableRow key={checkout._id}>
                  <TableCell>
                    <Typography>{checkout.nickname}</Typography>
                  </TableCell>
                  <TableCell>{<ModeChips mode={checkout.mode} />}</TableCell>
                  <TableCell>{checkout.submit_type}</TableCell>
                  <TableCell>
                    {checkout?.line_items.map((i) => i.price).join(" ")}
                  </TableCell>
                  <TableCell>{checkout.cancel_url}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => handleCopyCheckoutUrl(checkout)}
                      style={{ margin: 5 }}
                    >
                      Use the Checkout
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        navigate(`/stripecheckout/edit/${checkout._id}`)
                      }
                      style={{ margin: 5 }}
                    >
                      Edit
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => handleDeleteCheckout(checkout._id)}
                      style={{ margin: 5 }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </NavBar2>
  );
};

export default Checkouts;
