import React from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavBar2 from "../../components/navigation/NavBar2";
import AppBar from "@material-ui/core/AppBar";
import AddIcon from "@material-ui/icons/Add";
import Toolbar from "@material-ui/core/Toolbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Moment from "react-moment";
import Dialog from "@material-ui/core/Dialog";
import { format } from "date-fns";
import Directions from "../../components/directions/Directions";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;
Moment.globalFormat = "MMM D YYYY";

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
  button: {
    marginTop: theme.spacing.unit * 3,
  },
  paperAppBar: {
    marginTop: "-1%",
    marginBottom: "1%",
  },
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    width: "100%",
    marginLeft: "8%",
    marginRight: "8%",
    marginTop: theme.spacing.unit * 3,
  }),
  bigAvatar: {
    width: 100,
    height: 100,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  textFieldHalf: {
    marginLeft: theme.spacing.unit * 0.5,
    marginRight: theme.spacing.unit * 0.5,
    width: "48%",
  },
  appBarButton: {
    float: "right",
  },
});

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 10,
      customers: [],
      user: {},
      anchorEl: {},
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      dialogOpen: false,
      customer_to_add: "",
      loadCustomersFinished: false,
      autoAddCustomersFinished: true,
      manualAddCustomersFinished: true,
      removeCustomerFinished: true,
      updateUserFinished: true,
      tabValue: 0,
    };
  }

  changeNav = (name) => (event) => {
    let path = `/${this.props.match.params.org}/${name}`;
    this.props.history.push(path);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClick = (event) => {
    // for action menu
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    //for action menu
    this.setState({ anchorEl: {} });
  };

  handleDialogOpen = (event) => {
    this.setState({ dialogOpen: true });
  };
  handleDialogClose = (event) => {
    this.setState({ dialogOpen: false });
  };
  handleTabChange = (event, value) => {
    this.setState({ tabValue: value });
  };

  nextPage = (event) => {
    this.loadCustomers(
      Number(this.state.page) + 1,
      Number(this.state.rowsPerPage)
    );
    this.setState({
      page: this.state.page + 1,
    });
  };

  previousPage = (event) => {
    this.loadCustomers(
      Number(this.state.page) - 1,
      Number(this.state.rowsPerPage)
    );
    this.setState({
      page: this.state.page - 1,
    });
  };

  componentDidMount(event) {
    console.log("PROPS", this.props);
    this.loadCustomers();
  }

  updateUser = () => {
    var self = this;
    self.setState({
      updateUserFinished: false,
    });
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/v1/org/users/${this.props.match.params.user}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone: this.state.phone,
      },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            updateUserFinished: true,
          });
          self.loadCustomers();
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error updating user" });
      });
  };

  manualAddCustomer = () => {
    var self = this;
    self.setState({
      manualAddCustomersFinished: false,
    });
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/v1/org/users/${this.props.match.params.user}/add/${this.state.customer_to_add}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            manualAddCustomersFinished: true,
          });
          self.loadCustomers();
          self.handleDialogClose();
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving users" });
      });
  };

  removeCustomer = () => {
    var self = this;
    self.setState({
      removeCustomerFinished: false,
    });
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/v1/org/users/${this.props.match.params.user}/remove/${this.state.anchorEl.id}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            removeCustomerFinished: true,
          });
          self.handleClose();
          self.loadCustomers();
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving users" });
      });
  };

  autoAddCustomer = () => {
    var self = this;
    self.setState({
      autoAddCustomersFinished: false,
    });
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/v1/org/users/${this.props.match.params.user}/addcustomersbyemail`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            autoAddCustomersFinished: true,
          });
          self.loadCustomers();
          self.handleDialogClose();
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving users" });
      });
  };

  loadCustomers = () => {
    var self = this;
    self.setState({
      loadCustomersFinished: false,
    });
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/users/${this.props.match.params.user}?customers=true`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          const user = response.data;
          self.setState({
            user: user,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone: user.phone,
            customers: user.customers,
            customer_to_add: "",
            loadCustomersFinished: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving users" });
      });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const customers = [];
    console.log(this.state);
    for (var i = 0; i < this.state.customers.length; i++) {
      customers.push(
        <TableRow key={this.state.customers[i].id}>
          <TableCell component="th" scope="row">
            <Button
              size="small"
              variant="outlined"
              color="primary"
              target="_blank"
              href={`https://dashboard.stripe.com/customers/${this.state.customers[i].id}`}
            >
              {this.state.customers[i].id}
            </Button>
          </TableCell>
          <TableCell>{this.state.customers[i].email}</TableCell>
          <TableCell>
            {format(new Date(this.state.customers[i].created), "mm/dd/yyyy")}
          </TableCell>
          <TableCell>
            {this.state.customers[i].subscriptions?.data?.length}
          </TableCell>
          <TableCell>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              id={this.state.customers[i].id}
              aria-owns={anchorEl ? this.state.customers[i].id : null}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              Actions
            </Button>
            <Menu
              id={this.state.customers[i].id}
              anchorEl={anchorEl}
              open={
                this.state.anchorEl.id === this.state.customers[i].id
                  ? true
                  : false
              }
              onClose={this.handleClose}
            >
              <MenuItem disabled={true}>{this.state.customers[i].id}</MenuItem>
              <MenuItem>
                <a
                  target="_blank"
                  href={`https://dashboard.stripe.com/customers/${this.state.customers[i].id}`}
                >
                  Edit In Stripe
                </a>
              </MenuItem>
              <MenuItem onClick={this.removeCustomer}>
                Remove from User
              </MenuItem>
              <MenuItem>
                <a
                  target="_blank"
                  href={`https://instapaymentpages.com${this.state.customers[i].update_url}`}
                >
                  Card Update Page
                </a>
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <NavBar2 header="Manage User" {...this.props}>
        <Directions message="Manage this user. You can run the auto association to attatch customers to the user OR you can manually enter a customer ID." />
        <Grid container spacing={24}>
          <Paper className={classes.paper}>
            <AppBar
              position="static"
              className={classes.paperAppBar}
              color="secondary"
            >
              <Toolbar>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                >
                  User Info
                </Typography>
              </Toolbar>
            </AppBar>
            <Grid container justify={"center"}>
              <br />
              <form className={classes.container} noValidate autoComplete="off">
                <TextField
                  label="First Name"
                  id="margin-none"
                  value={this.state.first_name}
                  className={classes.textFieldHalf}
                  helperText="User's first name"
                  onChange={this.handleChange("first_name")}
                  margin="normal"
                />

                <TextField
                  label="Last Name"
                  id="margin-none"
                  value={this.state.last_name}
                  className={classes.textFieldHalf}
                  helperText="User's first name"
                  onChange={this.handleChange("last_name")}
                  margin="normal"
                />

                <TextField
                  label="Email"
                  id="margin-none"
                  fullWidth
                  value={this.state.email}
                  className={classes.textFieldHalf}
                  helperText="User's Email"
                  onChange={this.handleChange("email")}
                />
                <TextField
                  label="Phone"
                  id="margin-none"
                  fullWidth
                  value={this.state.phone}
                  className={classes.textFieldHalf}
                  helperText="User's Phone"
                  onChange={this.handleChange("phone")}
                />

                <br />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                  onClick={(event) => this.updateUser(event)}
                  disabled={!this.state.updateUserFinished}
                >
                  Update Profile
                </Button>
              </form>
            </Grid>
          </Paper>

          <Paper className={classes.paper}>
            <AppBar
              position="static"
              className={classes.paperAppBar}
              color="secondary"
            >
              <Toolbar>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                >
                  Linked Customers
                </Typography>
              </Toolbar>
            </AppBar>
            <Grid container justify={"center"}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Customer ID</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Subscriptions</TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={(event) => this.loadCustomers()}
                        className={classes.paginationButton}
                        disabled={!this.state.loadCustomersFinished}
                      >
                        Refresh
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{customers}</TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={(event) => this.previousPage(event)}
                        className={classes.paginationButton}
                        disabled={this.state.page <= 1}
                      >
                        Previous
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={(event) => this.nextPage(event)}
                        className={classes.paginationButton}
                        disabled={this.state.customers.length < 10}
                      >
                        Next
                      </Button>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <Button
                        variant="fab"
                        color="primary"
                        aria-label="Add"
                        className={classes.button}
                        onClick={this.handleDialogOpen}
                      >
                        <AddIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Grid>
          </Paper>

          <Dialog
            open={this.state.dialogOpen}
            fullWidth={true}
            onClose={this.handleDialogClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <AppBar position="static" color="default">
                <Tabs
                  value={this.state.tabValue}
                  onChange={this.handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  fullWidth
                >
                  <Tab label="Auto Find" />
                  <Tab label="Manual" />
                </Tabs>
              </AppBar>
            </DialogTitle>

            {this.state.tabValue === 0 && (
              <TabContainer>
                <DialogContent>
                  This method looks up all customers with the user's email (
                  {this.state.user.email}) in your stripe account and adds them
                  to the user. The user will then be able to manage those
                  customers in their user portal. You can always remove
                  customers associated with a user by using the actions button
                  in the list of customers
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleDialogClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={this.autoAddCustomer}
                    color="primary"
                    disabled={!this.state.autoAddCustomersFinished}
                  >
                    Run Auto Look Up
                  </Button>
                </DialogActions>
              </TabContainer>
            )}

            {this.state.tabValue === 1 && (
              <TabContainer>
                <DialogContent>
                  Enter the Stripe Customer Id in the text field below to add it
                  to the User.
                  <TextField
                    autoFocus
                    margin="dense"
                    id="stripe_customer_id"
                    label="Stripe CustomerId"
                    onChange={this.handleChange("customer_to_add")}
                    helperText="i.e. cus_D5kGa0MT7rA12u"
                    type="text"
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleDialogClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={this.manualAddCustomer}
                    color="primary"
                    disabled={!this.state.manualAddCustomersFinished}
                  >
                    Add Customer
                  </Button>
                </DialogActions>
              </TabContainer>
            )}
          </Dialog>
        </Grid>
      </NavBar2>
    );
  }
}

export default withStyles(styles)(User);
