import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Logout = () => {
  const navigate = useNavigate();
  localStorage.clear();
  let path = "/login";
  useEffect(() => {
    navigate(path);
  }, []);
  // if (this.props.match.params.org) {
  //   path = `/${this.props.match.params.org}/login`;
  // }

  return <div>Logging you out...</div>;
};

export default Logout;
