import React from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Currencies from "../../components/currencies";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import NavBar2 from "../../components/navigation/NavBar2";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Moment from "react-moment";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import Directions from "../../components/directions/Directions";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;
Moment.globalFormat = "MMM D YYYY";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    marginTop: theme.spacing.unit * 3,
  }),
  button: {
    marginTop: theme.spacing.unit * 2,
  },
  textField: {
    marginTop: theme.spacing.unit,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

class UpdateNpForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      amount: 0,
      redirect_url: "",
      plan_ids: "",
      instructionType: "oneTime",
      allowPassThrough: false,
      products: [],
      selectedPlans: [],
      custom_fields: [],
      enableInstallment: false,
      enableTerms: false,
      hide_address: false,
    };
  }

  changeNav = (name) => (event) => {
    let path = `/${this.props.match.params.org}/${name}`;
    this.props.history.push(path);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  fetchNpForm = () => {
    var self = this;
    console.log(this.state);
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/npforms/${self.props.match.params.formId}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            name: response.data.name,
            description: response.data.description,
            amount: response.data.amount_in_cents / 100,
            currency: response.data.currency,
            redirect_url: response.data.redirect_url,
            custom_fields: response.data.custom_fields,
            hide_address: response.data.hide_address,
            coupon_enabled: response.data.coupon_enabled,
            enableTerms:
              response.data.terms && response.data.terms.active
                ? response.data.terms.active
                : false,
            termsLink:
              response.data.terms && response.data.terms.link
                ? response.data.terms.link
                : "",
            enableTaxes:
              response.data.taxes && response.data.taxes.active
                ? response.data.taxes.active
                : false,
            tax_percentage:
              response.data.taxes && response.data.taxes.percent
                ? response.data.taxes.percent
                : "",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving forms" });
      });
  };

  updateNpForm = (event) => {
    event.preventDefault();
    var self = this;
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/v1/org/npforms/${self.props.match.params.formId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        name: this.state.name,
        description: this.state.description,
        currency: this.state.currency,
        amount_in_cents: Math.ceil(this.state.amount * 100),
        redirect_url: this.state.redirect_url,
        coupon_enabled: this.state.coupon_enabled,
        custom_fields: this.state.custom_fields,
        hide_address: this.state.hide_address,
        terms: {
          active: this.state.enableTerms,
          link: this.state.termsLink,
        },
        taxes: {
          active: this.state.enableTaxes,
          percent: this.state.tax_percentage,
        },
      },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            snackOpen: true,
            snackVariant: "success",
            snackMessage: "Your plan has been updated.",
          });

          let path = `/npforms`;
          self.props.history.push(path);
        }
      })
      .catch(function (error) {
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          activeStep: 0,
          snackMessage: error.response.data.message,
        });
      });
  };

  handleCustomFieldTypeChange = (index) => (event) => {
    let unmutatedArray = new Array().concat(this.state.custom_fields);
    unmutatedArray[index].field_type = event.target.value;

    this.setState({
      custom_fields: unmutatedArray,
    });
  };

  handleCustomFieldChange = (index) => (event) => {
    let unmutatedArray = new Array().concat(this.state.custom_fields);
    unmutatedArray[index].name = event.target.value;

    this.setState({
      custom_fields: unmutatedArray,
    });
  };

  handleCustomFieldValuesChange = (index) => (event) => {
    let unmutatedArray = new Array().concat(this.state.custom_fields);
    unmutatedArray[index].values = event.target.value.split(",");

    this.setState({
      custom_fields: unmutatedArray,
    });
  };

  handleCustomFieldCheckChange = (index) => (event) => {
    let unmutatedArray = new Array().concat(this.state.custom_fields);
    unmutatedArray[index].required = !this.state.custom_fields[index].required;

    this.setState({
      custom_fields: unmutatedArray,
    });
  };

  addField = (index) => (event) => {
    console.log("addField was called");
    let custom_fields = this.state.custom_fields;
    custom_fields.push({
      name: "new field",
    });
    this.setState({
      custom_fields: custom_fields,
    });
  };

  removeField = (index) => (event) => {
    console.log(this.state.custom_fields);
    let custom_fields = new Array().concat(this.state.custom_fields);
    let edited_custom_fields = custom_fields.splice(index, 1);
    this.setState({
      custom_fields: custom_fields,
    });
  };

  componentDidMount(event) {
    this.fetchNpForm();
  }

  render() {
    const { classes, theme, ...other } = this.props;
    console.log(this.state);
    const customFieldsArray = [];
    for (let [index, custom_field] of this.state.custom_fields.entries()) {
      customFieldsArray.push(
        <div id={"custom_field_" + index} key={index}>
          <TextField
            label="Field Name"
            type="text"
            required
            value={custom_field.name}
            className={classes.textFieldHalf}
            helperText="Custom field name displayed on the forms"
            onChange={this.handleCustomFieldChange(index)}
          />
          <TextField
            select
            label="Field Type"
            fullWidth
            value={custom_field.field_type}
            onChange={this.handleCustomFieldTypeChange(index)}
          >
            <MenuItem key={"text"} value="text">
              Text
            </MenuItem>
            <MenuItem key={"dropdown"} value="dropdown">
              Dropdown
            </MenuItem>
          </TextField>
          {custom_field.field_type === "dropdown" ? (
            <TextField
              label="Dropdown Values"
              type="text"
              required
              value={custom_field.values}
              placeholder="Small,Medium,Large"
              className={classes.textFieldHalf}
              helperText="Comma separated list of values. i.e. small,medium,large"
              onChange={this.handleCustomFieldValuesChange(index)}
            />
          ) : null}
          <FormControlLabel
            control={
              <Checkbox
                checked={custom_field.required}
                onChange={this.handleCustomFieldCheckChange(index)}
                color="primary"
              />
            }
            label="Required?"
          />
          <Button
            color="primary"
            disabled={this.state.custom_fields.length === 0}
            onClick={this.removeField(index)}
            className={classes.button}
          >
            Remove Field
          </Button>
        </div>
      );
    }

    return (
      <NavBar2 header="Productless Form Update" {...other}>
        <Directions message="Update your Productless form here to change what happens when a customer fills it out." />

        <Paper className={classes.paper}>
          <form onSubmit={this.updateNpForm}>
            <TextField
              label="Name"
              fullWidth
              required
              type="text"
              value={this.state.name}
              helperText="Name so you can easily identify the Form."
              className={classes.textField}
              onChange={this.handleChange("name")}
            />
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="currency">Currency</InputLabel>
              <Select
                native
                value={this.state.currency}
                onChange={this.handleChange("currency")}
                inputProps={{
                  name: "currency",
                  id: "currency",
                }}
              >
                <Currencies />
              </Select>
              <FormHelperText>
                The currency you want the charge processed in.
              </FormHelperText>
            </FormControl>
            <TextField
              label="Description"
              fullWidth
              required
              type="text"
              value={this.state.description}
              helperText="This will be the description sent to Stripe for the charge."
              className={classes.textField}
              onChange={this.handleChange("description")}
            />
            <TextField
              label="Amount to Charge"
              fullWidth
              required
              value={this.state.amount}
              helperText="Amount you want Stripe to charge the customer when they complete your checkout."
              type="number"
              InputProps={{
                inputProps: {
                  step: "any",
                  min: 0,
                },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              className={classes.textField}
              onChange={this.handleChange("amount")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.coupon_enabled}
                  onChange={() =>
                    this.setState({
                      coupon_enabled: !this.state.coupon_enabled,
                    })
                  }
                  value=""
                  color="primary"
                />
              }
              label="Allow coupon field?"
            />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.enableTaxes}
                    onChange={() =>
                      this.setState({
                        enableTaxes: !this.state.enableTaxes,
                      })
                    }
                    value=""
                    color="primary"
                  />
                }
                label="Enable Taxes"
              />

              {this.state.enableTaxes ? (
                <TextField
                  label="Tax Percentage"
                  fullWidth
                  helperText="Tax percentage you want applied to the subscription."
                  value={this.state.tax_percentage}
                  type="number"
                  InputProps={{
                    inputProps: {
                      step: "any",
                      min: 0,
                    },
                    startAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  className={classes.textField}
                  onChange={this.handleChange("tax_percentage")}
                />
              ) : null}
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.enableTerms}
                    onChange={() =>
                      this.setState({ enableTerms: !this.state.enableTerms })
                    }
                    color="primary"
                  />
                }
                label="Enable Terms"
              />
              {this.state.enableTerms ? (
                <TextField
                  label="Terms and Conditions"
                  fullWidth
                  helperText="URL of your terms and conditions"
                  value={this.state.termsLink}
                  type="text"
                  className={classes.textField}
                  onChange={this.handleChange("termsLink")}
                />
              ) : null}
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.hide_address}
                    onChange={() =>
                      this.setState({
                        hide_address: !this.state.hide_address,
                      })
                    }
                    value=""
                    color="primary"
                  />
                }
                label="Hide Address Fields"
              />
            </div>
            <TextField
              label="Redirect Url"
              fullWidth
              type="text"
              value={this.state.redirect_url}
              helperText="The URL you want us to send the customer to when the checkout is complete."
              className={classes.textField}
              onChange={this.handleChange("redirect_url")}
            />

            <div>
              {customFieldsArray}
              <Button
                color="primary"
                onClick={this.addField()}
                className={classes.button}
              >
                Add Custom Field
              </Button>
            </div>

            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              className={classes.button}
              type="submit"
            >
              Update Productless Form
            </Button>
          </form>
        </Paper>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
        >
          <MySnackbarContentWrapper
            variant={this.state.snackVariant}
            message={this.state.snackMessage}
            onClose={this.handleSnackClose}
          />
        </Snackbar>
      </NavBar2>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(UpdateNpForms);
