// CheckoutForm.js
import React from "react";
import axios from "axios";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  CardElement,
} from "react-stripe-elements";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
//import StripeElementWrapper from './StripeElementWrapper';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = (theme) => ({
  textField: {
    marginBottom: theme.spacing.unit * 3,
  },
  extraPadding: {
    marginTop: theme.spacing.unit * 3,
  },
});

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card_name: "",
      terms: false,
      billingFinished: true,
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  createStripeCustomer = (token) => {
    var self = this;
    self.setState({
      billingFinished: false,
    });
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/v1/org/billing/card`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        token: token,
        // plan:
        //   localStorage.getItem("plan") === "undefined" ||
        //   localStorage.getItem("plan") === null
        //     ? "essential"
        //     : localStorage.getItem("plan"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          self.setState({
            billingFinished: true,
          });

          window.location.href = "/dashboard";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    if (this.state.terms) {
      this.props.stripe
        .createToken({ name: this.state.card_name })
        .then(({ token }) => {
          console.log("Received Stripe token:", token);
          if (token) {
            this.createStripeCustomer(token.id);
          } else {
            alert("Make sure the credit card field isn't empty.");
          }
        });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container justify={"center"}>
          <Typography variant="body1" color="inherit">
            Start your 7 day no risk <strong>FREE</strong> trial. You can cancel
            anytime.
          </Typography>
        </Grid>

        <Grid className={classes.extraPadding} justify={"center"} container>
          <Grid item xs={12} md={8}>
            <form onSubmit={this.handleSubmit}>
              <div
                style={{
                  borderBottom: "1px solid #000000de",
                  //borderRadius: "15px",
                  padding: 4,
                }}
              >
                <CardElement
                  style={{
                    base: {
                      width: "100%",
                      fontSize: "18px",
                    },
                  }}
                />
              </div>

              <FormControlLabel
                className={classes.extraPadding}
                control={
                  <Checkbox
                    checked={this.state.terms}
                    onChange={this.handleCheckboxChange("terms")}
                    color="primary"
                    required
                  />
                }
                label={
                  <div>
                    <span style={{ color: "#000" }}>I agree to the </span>
                    <a
                      href="https://instapayments.io/terms"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      terms of use
                    </a>
                  </div>
                }
              />
              <Grid justify={"center"} container>
                <br />
                <Grid item xs={12} md={8}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={!this.state.billingFinished}
                  >
                    Finish
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default injectStripe(withStyles(styles)(CheckoutForm));
