import axios from "axios";
import Button from "@material-ui/core/Button";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import NavBar2 from "../../components/navigation/NavBar2";
import Paper from "@material-ui/core/Paper";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SyntaxHighlighter from "react-syntax-highlighter";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { prism } from "react-syntax-highlighter/styles/prism";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
  },

  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar,
  paper: theme.mixins.gutters({
    paddingTop: 30,
    paddingBottom: 50,
    marginTop: "2%",
  }),
  textField: {
    margin: "5px",
  },
});

class IntegrateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "$5/mo",
      amount: 500,
      organization_name: "",
      currency: "USD",
      logo: `https://instapayments.s3.us-west-2.amazonaws.com/${
        this.props.match.params.snippetId
      }_${localStorage.getItem("org")}`,
      label: "Pay with Card",
    };
  }

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  fetchMe = () => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/me`, //retrieves the current authenticated user
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        if (response.status == 200) {
          console.log(response.data);
          self.setState({
            org: response.data,
            company_name: response.data.organization_name,
            key: response.data.stripe_credentials.stripe_publishable_key,
            finished: true,
            organization_name: response.data.organization_name,
          });
        } else {
          let path = `/${self.state.org_handle}/login`;
          self.props.history.push(path);
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving user profile" });
      });
  };

  fetchForm = () => {
    var self = this;
    let requestUrl = `${apiBaseUrl}/v1/org/forms/${self.props.match.params.formId}`;
    if (self.props.match.params.type == "noproduct") {
      requestUrl = `${apiBaseUrl}/v1/org/npforms/${self.props.match.params.formId}`;
    }
    console.log(this.state);
    axios({
      method: "get",
      url: requestUrl,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log("FORM", response.data);
        if (response.status === 200) {
          self.setState({
            form: {
              ...response.data,
            },
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving forms" });
      });
  };

  goHome = () => {
    let path = `/chargeinstructions/${this.props.match.params.snippetType}`;
    this.props.history.push(path);
  };

  onUploadFinish = (event) => {
    console.log("Upload event", event);
    this.setState({
      logo: "https://s3-us-west-2.amazonaws.com/instapayments/" + event.fileKey,
      snackOpen: true,
      snackVariant: "success",
      snackMessage: "Your logo was uploaded.",
    });
  };

  onToken = (token) => {
    alert(`Your checkout was successfull. We did NOT charge the card or create a Stripe Customer like we normally would. Normally, your visitors would be redirected to ${this.state.redirect_url}.
      If you want to do a full checkout, use the codepen button OR copy and paste the snippet onto your website.`);
  };

  componentDidMount() {
    this.fetchMe();
    if (this.props.match.params.type === "np") {
      this.fetchNpForm();
    } else {
      this.fetchForm();
    }
  }

  render() {
    console.log(this.state);
    const { classes, ...other } = this.props;
    let formUrl = "";
    let form = this.state.form;
    if (form) {
      if (form.type === "donation") {
        formUrl = `https://forms.${process.env.REACT_APP_BASE_DOMAIN}/donate/${form._id}`;
      } else if (form.type === "onetime") {
        formUrl = `https://forms.${process.env.REACT_APP_BASE_DOMAIN}/onetime/${form._id}`;
      } else if (form.type === "multiprod") {
        formUrl = `https://forms.${process.env.REACT_APP_BASE_DOMAIN}/multiprod/${form._id}`;
      } else if (form.type === "signup-quantity") {
        formUrl = `https://forms.${process.env.REACT_APP_BASE_DOMAIN}/signup-quantity/${form._id}`;
      } else if (this.props.match.params.type === "noproduct") {
        formUrl = `https://forms.${process.env.REACT_APP_BASE_DOMAIN}/np/${form._id}`;
      } else {
        formUrl = `https://forms.${process.env.REACT_APP_BASE_DOMAIN}/signup/${form._id}`;
      }
    }

    let codeString = `<div>
<iframe
class="embedded-form"
src="${formUrl}"
style=" height: 550px; width:450px; border: none; display:block;"
scroll = “none”
>
  <p>Your browser does not support iframes.</p>
</iframe>
</div>`.trim();

    let modalCodeString =
      `<!--This Javascript and CSS we include in this snippet is simply to show and hide the modal.-->
<!--This CSS would ideally be in the header of your page. You can also copy the CSS and add it to your own style sheet.-->
<link rel="stylesheet" type="text/css" href="https://s3-us-west-2.amazonaws.com/instapayments-scripts/css/ip.css">

<!--Change the CSS in this style tag to customize the button appearance.-->
<style>
.ip-trigger-button{
  position: relative;
  padding: 0 12px;
  height: 30px;
  line-height: 30px;
  background: #1275ff;
  -webkit-font-smoothing: antialiased;
  background-image: linear-gradient(#7dc5ee,#008cdd 85%,#30a2e4);
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.25);
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  cursor: pointer;
}
</style>
<!--You can use any button you want just be sure to include the class, id and onclick function -->
<button class="ip-trigger-button" id="${
        this.state.form && this.state.form._id
      }" formtype="${
        (this.state.form && this.state.form.type) || "np"
      }" onClick="toggleIpModal(this)">
  Pay With Card
</button>

  <div class="modal-ip" id="modal-ip-${this.state.form && this.state.form._id}">
    <div class="modal-ip-content">
      <span class="close-ip-button" onClick="closeIpModals()">×</span>
      <div>
        <iframe
        class="embedded-ip-form"
        src="${formUrl}"
        style=" height: 550px; width:100%; border: none; display:block;"
        scroll = “none”
        >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </div>
  </div>
</div>

<!--Ideally this would be added to the footer of your page-->
<!--This javascript is not very unique and can easily coppied from it's source link for faster load times.-->
<script src="https://s3-us-west-2.amazonaws.com/instapayments-scripts/javascript/ip.js"></script>

`.trim();

    const codePenString = JSON.stringify({
      title: "Stripe Button Preview",
      html: codeString,
    });

    return (
      <NavBar2 header={"Use Your Form"} {...other}>
        <div>
          <Paper>
            <AppBar position="static">
              <Toolbar>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.grow}
                >
                  Use one of the following ways to accept payments with this
                  form.
                </Typography>
                <Button
                  variant="outlined"
                  color="inherit"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={formUrl}
                >
                  View the Form
                </Button>
              </Toolbar>
            </AppBar>
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="p" color="inherit" className={classes.grow}>
              This is the unique link to the form that will not change. You can
              copy this and <strong>put it in an email</strong> to your
              customers OR{" "}
              <strong>put it in behind a button on your website</strong>.
            </Typography>
            <SyntaxHighlighter language="html" style={prism}>
              {formUrl}
            </SyntaxHighlighter>
            <CopyToClipboard
              text={formUrl}
              onCopy={() => this.setState({ copied: true })}
            >
              <Button variant="outlined" color="primary">
                {this.state.copied ? "Copied" : "Copy Unique Url"}
              </Button>
            </CopyToClipboard>
            <Button
              variant="outlined"
              color="primary"
              target="_blank"
              rel="noopener noreferrer"
              href={formUrl}
            >
              View the Form
            </Button>
            {/* <form action="https://codepen.io/pen/define" method="POST" target="_blank">
       <input type="hidden" name="data" value={codePenString}/>
       <Button
       type="submit"
       variant="outlined"
       color="primary"
       >
       Test/Preview in CodePen
       </Button>
       </form>*/}
          </Paper>

          <Paper className={classes.paper}>
            <Typography variant="p" color="inherit" className={classes.grow}>
              <strong>Embed the form</strong> in your website. This can be done
              with this snippet of code. Be sure that your site has SSL/HTTPS to
              maintain PCI compliance.
            </Typography>
            <SyntaxHighlighter language="html" style={prism}>
              {codeString}
            </SyntaxHighlighter>
            <CopyToClipboard
              text={codeString}
              onCopy={() => this.setState({ copiedEmbedSnippet: true })}
            >
              <Button variant="outlined" color="primary">
                {this.state.copiedEmbedSnippet ? "Copied" : "Copy Snippet"}
              </Button>
            </CopyToClipboard>
            {/* <form action="https://codepen.io/pen/define" method="POST" target="_blank">
            <input type="hidden" name="data" value={codePenString}/>
            <Button
            type="submit"
            variant="outlined"
            color="primary"
            >
            Test/Preview in CodePen
            </Button>
            </form>*/}
          </Paper>

          <Paper className={classes.paper}>
            <Typography variant="p" color="inherit" className={classes.grow}>
              <strong>Put the form in a modal or popup</strong> when a button is
              clicked. Be sure that your site has SSL/HTTPS to maintain PCI
              compliance.
            </Typography>
            <SyntaxHighlighter language="html" style={prism}>
              {modalCodeString}
            </SyntaxHighlighter>
            <CopyToClipboard
              text={modalCodeString}
              onCopy={() => this.setState({ copiedModalSnippet: true })}
            >
              <Button variant="outlined" color="primary">
                {this.state.copiedModalSnippet ? "Copied" : "Copy Snippet"}
              </Button>
            </CopyToClipboard>
            {/* <form action="https://codepen.io/pen/define" method="POST" target="_blank">
               <input type="hidden" name="data" value={codePenString}/>
               <Button
               type="submit"
               variant="outlined"
               color="primary"
               >
               Test/Preview in CodePen
               </Button>
               </form>*/}
          </Paper>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={this.state.snackOpen}
            autoHideDuration={3000}
            onClose={this.handleSnackClose}
          >
            <MySnackbarContentWrapper
              variant={this.state.snackVariant}
              message={this.state.snackMessage}
              onClose={this.handleSnackClose}
            />
          </Snackbar>
        </div>
      </NavBar2>
    );
  }
}

export default withStyles(styles)(IntegrateForm);
