const styles = theme => ({
  root: {
    flexGrow: 1,
    //height: 430,
    height: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  card: {
     maxWidth: "70%",
   },
   media: {
     height: "50%",
   },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar,
  paper: theme.mixins.gutters({
    paddingTop: 50,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3,
  }),
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 11,
    right: theme.spacing.unit * 3,
    zIndex: 9999,
  },
  loader:{
    marginTop: "15%"
  }
});

export {styles}
