import React, { useState, useEffect } from "react";
import { useDebounce } from "react-use";
import { Link } from "react-router-dom";
import axios from "axios";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavBar2 from "../../components/navigation/NavBar2";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Moment from "react-moment";
import Directions from "../../components/directions/Directions";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

Moment.globalFormat = "MMM D YYYY";

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paginationButton: {
    margin: theme.spacing.unit,
  },
  //toolbar: theme.mixins.toolbar,
  paper: theme.mixins.gutters({
    paddingTop: 50,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3,
  }),
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
});

const User = (props) => {
  const [state, setState] = useState({
    page: 1,
    rowsPerPage: 10,
    users: [],
  });
  const [search, setSearch] = useState("");

  const [, cancel] = useDebounce(
    () => {
      if (search) {
        handleSearch(search);
      } else {
        loadUsers(1, 10);
      }
      // This needs to be in a debounce so we don't call search for every change on search input
    },
    500,
    [search]
  );

  const changeNav = (name) => (event) => {
    let path = `/${props.match.params.org}/${name}`;
    props.history.push(path);
  };

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleSearch = async () => {
    if (search) {
      let { data: results } = await axios({
        method: "post",
        url: `${apiBaseUrl}/v1/org/users/search`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          search,
        },
      });
      console.log(results);
      if (results.length > 0) {
        setState({
          ...state,
          users: results,
          finished: true,
        });
      }
    }
  };

  const nextPage = (event) => {
    loadUsers(Number(state.page) + 1, Number(state.rowsPerPage));
    setState({
      ...state,
      page: state.page + 1,
    });
  };

  const previousPage = (event) => {
    loadUsers(Number(state.page) - 1, Number(state.rowsPerPage));
    setState({
      ...state,
      page: state.page - 1,
    });
  };
  useEffect(() => {
    loadUsers(Number(state.page), Number(state.rowsPerPage));
  }, []);

  const loadUsers = (page, per_page) => {
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/users?page=${page}&per_page=${per_page}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          setState({
            ...state,
            users: response.data,
            finished: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        setState({ ...state, messageError: "Error retrieving users" });
      });
  };

  const { classes } = props;
  const users = [];
  console.log(state);
  for (var i = 0; i < state.users.length; i++) {
    users.push(
      <TableRow key={state.users[i].id}>
        <TableCell component="th" scope="row">
          <Button
            size="small"
            variant="outlined"
            color="primary"
            component={Link}
            to={`/users/${state.users[i]._id}`}
          >
            View
          </Button>
        </TableCell>
        <TableCell>{`${state.users[i].first_name || ""} ${
          state.users[i].last_name || ""
        }`}</TableCell>
        <TableCell>{state.users[i].email}</TableCell>
        <TableCell>{state.users[i].phone}</TableCell>
        <TableCell>{state.users[i].createdAt.split("T")[0]}</TableCell>
        <TableCell>{state.users[i].customers.length}</TableCell>
      </TableRow>
    );
  }

  return (
    <NavBar2 {...props}>
      <Directions message="Stripe Customers who have registered for the User portal will appear here. You can mange which customers they can access by clicking View." />
      <div className={classes.toolbar} />
      {state.users.length > 0 ? (
        <Paper>
          <AppBar position="static">
            <Toolbar>
              <div className={classes.grow} />
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  <Input
                    placeholder="Search..."
                    disableUnderline
                    onChange={(e) => setSearch(e.target.value)}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                  />
                </form>
              </div>
            </Toolbar>
          </AppBar>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>User ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Customers</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{users}</TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>Page {state.page}</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={(event) => previousPage(event)}
                    className={classes.paginationButton}
                    disabled={state.page <= 1}
                  >
                    Previous
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={(event) => nextPage(event)}
                    className={classes.paginationButton}
                    disabled={state.users.length < 10}
                  >
                    Next
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      ) : (
        <div>
          <Grid container justify={"center"}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image="/static/images/cards/contemplative-reptile.jpg"
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography gutterBottom variant="headline" component="h2">
                    You don't have a User yet!
                  </Typography>
                  <Typography component="p">
                    "Users" are your customers that have logged into
                    InstaPayments to manage their billing. The first time they
                    log in we automatically give them access to Stripe Customers
                    with that email. All customers have a unique card update URL
                    that does not need a log in at all. You can find this on the
                    Customers Page.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </div>
      )}
    </NavBar2>
  );
};

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(User);
