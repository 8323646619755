import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";
import Dashboard from "./views/dashboard/DashboardPage.js";
import PortalUsers from "./views/user/UsersPage.js";
import PortalUser from "./views/user/UserPage.js";
import User from "./views/user/UserPage.js";
import Customer from "./views/customer/CustomersPage.js";
import Products from "./views/product/ProductsPage.js";
import Product from "./views/product/ProductPage.js";
import NewProduct from "./views/product/NewProductPage.js";
import NewPlan from "./views/product/NewPlanPage.js";
import Forms from "./views/form/FormsPage.js";
import NpForms from "./views/npforms/FormsPage.js";
import UpdateNpForm from "./views/npforms/UpdateFormPage.js";
import CreateNpForm from "./views/npforms/CreateFormPage.js";
import IntegrateForm from "./views/form/IntegrateFormPage.js";
import NewForm3 from "./views/form/NewFormPage3.js";
import EasyForm from "./views/form/EasyForm.js";
import UpdateForm3 from "./views/form/UpdateFormPage3.js";
import StartGuide from "./views/startguide/StartGuidePage.js";
import NewChargeInstruction from "./views/chargeinstruction/NewChargeInstructionPage.js";
import UpdateChargeInstruction from "./views/chargeinstruction/UpdateChargeInstructionPage.js";
import ChargeInstructions from "./views/chargeinstruction/ChargeInstructionsPage.js";
import RecurringInstructions from "./views/chargeinstruction/RecurringInstructionsPage.js";
import SnippetCustomizer from "./views/chargeinstruction/ShowSnippet2.js";
import Account from "./views/setting/AccountPage.js";
import Billing from "./views/setting/BillingPage.js";
import Integration from "./views/setting/IntegrationsPage.js";
import Portal from "./views/setting/PortalPage.js";
import Profile from "./views/setting/ProfilePage.js";
import Login from "./views/login/LoginPage.js";
import PasswordReset from "./views/login/PasswordResetPage.js";
import PasswordSet from "./views/login/PasswordSetPage.js";
import OrgFinder from "./views/login/OrgFinderPage.js";
import Logout from "./views/logout/logout.js";
import Register3 from "./views/register/RegisterPageV3";
import HubspotRegister from "./views/register/HubspotRegister";
import withRouter from "./components/withRouter";
import StripeCheckout from "./views/stripeCheckouts/handleCheckout";
import Checkouts from "./views/stripeCheckouts/Checkouts";
import HubspotLink from "./views/hubspot";

import Templates from "./views/templates/TemplatesPage.js";
import NewTemplate from "./views/templates/NewTemplatePage.js";
import UpdateTemplate from "./views/templates/UpdateTemplatePage.js";

import Notifications from "./views/notifications/NotificationsPage.js";
import NewNotification from "./views/notifications/NewNotificationPage.js";
import UpdateNotification from "./views/notifications/UpdateNotificationPage.js";
import FromAddresses from "./views/notifications/FromAddressesPage.js";

const routes = [
  { path: "/", index: true, Component: Login, exact: true },
  {
    path: "/dashboard",
    Component: Dashboard,
    exact: true,
    requiresAuth: true,
  },
  { path: "/start-guide", Component: StartGuide, exact: true },
  { path: "/users", Component: PortalUsers, exact: true, requiresAuth: true },
  {
    path: "/users/:user",
    Component: withRouter(PortalUser),
    exact: true,
    requiresAuth: true,
  },
  { path: "/profile", Component: Profile, exact: true, requiresAuth: true },
  {
    path: "/settings/profile",
    Component: Profile,
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/settings/integrations",
    Component: withRouter(Integration),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/settings/integrations/:integration",
    Component: withRouter(Integration),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/settings/portal",
    Component: Portal,
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/settings/account",
    Component: Account,
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/settings/billing",
    Component: Billing,
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/user/:user",
    Component: withRouter(User),
    exact: true,
    requiresAuth: true,
  },
  { path: "/customers", Component: Customer, exact: true, requiresAuth: true },
  { path: "/products", Component: Products, exact: true, requiresAuth: true },
  {
    path: "/products/:productId",
    Component: withRouter(Product),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/products/product/new",
    Component: withRouter(NewProduct),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/products/:productId/plans/new",
    Component: withRouter(NewPlan),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/npforms",
    Component: withRouter(NpForms),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/npforms/new",
    Component: withRouter(CreateNpForm),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/npforms/update/:formId",
    Component: withRouter(UpdateNpForm),
    exact: true,
    requiresAuth: true,
  },
  { path: "/easyform", Component: EasyForm, exact: true, requiresAuth: true },
  { path: "/forms", Component: Forms, exact: true, requiresAuth: true },
  {
    path: "/forms/integrate/:type/:formId",
    Component: withRouter(IntegrateForm),
    exact: true,
  },
  {
    path: "/forms/new/v2",
    Component: withRouter(NewForm3),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/forms/update/:formId/v2",
    Component: withRouter(UpdateForm3),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/chargeinstruction/new",
    Component: withRouter(NewChargeInstruction),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/chargeinstruction/update/:type/:chargeInstructionId",
    Component: withRouter(UpdateChargeInstruction),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/chargeinstructions/onetime",
    Component: withRouter(ChargeInstructions),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/chargeinstructions/recurring",
    Component: withRouter(RecurringInstructions),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/chargeinstruction/:snippetType/snippet/:snippetId",
    Component: withRouter(SnippetCustomizer),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/chargeinstruction/:snippetType/snippet2/:snippetId",
    Component: withRouter(SnippetCustomizer),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/stripecheckouts",
    Component: Checkouts,
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/stripecheckouts/new",
    Component: StripeCheckout,
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/stripecheckout/edit/:id",
    Component: StripeCheckout,
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/notifications/templates",
    Component: withRouter(Templates),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/notifications/templates/new",
    Component: withRouter(NewTemplate),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/notifications/templates/update/:templateId",
    Component: withRouter(UpdateTemplate),
    exact: true,
  },
  {
    path: "/notifications/triggers",
    Component: Notifications,
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/notifications/triggers/update/:notificationId",
    Component: withRouter(UpdateNotification),
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/notifications/triggers/new",
    Component: NewNotification,
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/notifications/(from-addresses|addresses)",
    Component: FromAddresses,
    exact: true,
    requiresAuth: true,
  },
  {
    path: "/notifications/logs",
    Component: Templates,
    exact: true,
    requiresAuth: true,
  },
  { path: "/register", Component: Register3, exact: true },
  { path: "/register2", Component: Register3, exact: true },
  { path: "/register3", Component: Register3, exact: true },
  { path: "/register/hubspot", Component: HubspotRegister, exact: true },
  { path: "/login", Component: Login, exact: true },
  { path: "/:org/login", Component: Login, exact: true },

  { path: "/signup", Component: Register3, exact: true },
  {
    path: "/:org/password/reset",
    Component: withRouter(PasswordReset),
    exact: true,
  },
  {
    path: "/:org/password/set/:token",
    Component: withRouter(PasswordSet),
    exact: true,
  },
  { path: "/:org/logout", Component: Logout, exact: true },
  { path: "/logout", Component: Logout, exact: true },
  {
    path: "/display-hubspot",
    Component: withRouter(HubspotLink),
    exact: true,
  },
];
export default routes;
