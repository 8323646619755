import React from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavBar2 from "../../components/navigation/NavBar2";
import Paper from "@material-ui/core/Paper";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Input from "@material-ui/core/Input";

import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import SimpleMDE from "react-simplemde-editor";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import "easymde/dist/easymde.min.css";
import Snackbar from '@material-ui/core/Snackbar';
import Directions from '../../components/directions/Directions';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
import { Menu } from "@material-ui/core";

const styles = theme => ({
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    paddingTop: 30,
    marginTop: theme.spacing.unit * 2
  }),
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

class UpdateTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: 0,
      rowsPerPage: 10,
      templates: [],
      notification_emails: [],
      searchValue: "",
      firstLoadAttempted: false,
      email_body: "",
      email_subject: "",
      from_address: "",
      name: "",
      type: "html",
    };
  }

  fetchOrg = () => {
    var self = this;
    console.log("templateId", self.props.match.params.templateId);
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/me`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            notification_emails: response.data.notification_emails,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving forms" });
      });
  };

  fetchTemplate = () => {
    var self = this;
    console.log("templateId", self.props.match.params.templateId);
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/templates/email/${
        self.props.match.params.templateId
      }`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            name: response.data.name,
            email_subject: response.data.email_subject,
            email_body: atob(response.data.email_body),
            from_address: response.data.from_address,
            type: response.data.type,
            updatedAt: response.data.updatedAt,
            firstLoadAttempted: true
            //version: this.state.version +1
          });
        }
      })
      .catch(function(error) {
        console.log(error);
        self.setState({ 
          messageError: "Error retrieving forms",
          firstLoadAttempted: true
        });
      });
  };
  
  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  updateTemplate = event => {
    event.preventDefault();
    var self = this;
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/v1/org/templates/email/${
        self.props.match.params.templateId
      }`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json"
      },
      data: {
        name: this.state.name,
        email_subject: this.state.email_subject,
        email_body: this.state.email_body,
        from_address: this.state.from_address,
        type: "html"
      }
    })
      .then(function(response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            snackOpen: true,
            snackVariant: "success",
            snackMessage: "Your template has been updated."
          });

          self.fetchTemplate();
        }
      })
      .catch(function(error) {
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          snackMessage:
            "Error updating template. Be sure all the fields are filled in."
        });
      });
  };

  componentDidMount(event) {
    this.fetchTemplate();
    //this.fetchOrg();
  }

  fetchOrg = () => {
    var self = this;
    console.log("templateId", self.props.match.params.templateId);
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/me`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            notification_emails: response.data.notification_emails,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving forms" });
      });
  };

  handleTemplateChange = value => {
    this.setState({ email_body: value });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const { classes, ...other } = this.props;
    const fromAddresses = []

    for (const emailObject of this.state.notification_emails) {
      if (emailObject.verification_status == "Success") {
        fromAddresses.push(
          <MenuItem 
          value={emailObject.email}
          key={emailObject._id}>
            {emailObject.email}
          </MenuItem>
        )
      }
    }

    return (
      <NavBar2 header="Update Template" {...this.props}>
        {this.state.firstLoadAttempted ?
        <div>
        <Paper className={classes.paper}>
        <form onSubmit={this.updateTemplate}>
          <TextField
            variant="outlined"
            label="Template Name"
            helperText="Internal name so you can easily identify it."
            fullWidth
            value={this.state.name}
            onChange={this.handleChange("name")}
            style={{
              paddingBottom: 15
            }}
          />
          <TextField
            variant="outlined"
            label="Email Subject"
            fullWidth
            value={this.state.email_subject}
            onChange={this.handleChange("email_subject")}
            style={{
              paddingBottom: 10
            }}
          />
          <SimpleMDE
            onChange={this.handleTemplateChange}
            value={this.state.email_body}
            options={{
              autofocus: true,
              spellChecker: false,
              //sanitize: true,
              //preview: false,
              hideIcons: ["fullscreen", "|", "side-by-side"],
              toolbar: [
                "bold",
                "italic",
                "heading",
                "|",
                "quote",
                "unordered-list",
                "ordered-list",
                "|",
                "link",
                "image",
                "|",
                "guide"
              ],
              shortcuts: {
                togglePreview: ""
              }
            }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
          >
            Save and Preview
          </Button>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          {this.state.updatedAt ? (
            <iframe
              key={this.state.updatedAt}
              sandbox=""
              height="500"
              width="100%"
              frameBorder="none"
              src={`${apiBaseUrl}/v1/org/templates/email/${
                this.props.match.params.templateId
              }/preview`}
            />
          ) : null}
        </Paper>
        </div>
          :
          <Grid container justify={"center"}>
          <div className={classes.loader}>
          <CircularProgress size={100} className={classes.progress} />
          </div>
          </Grid>
        }
        <Snackbar
           anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'center',
           }}
           open={this.state.snackOpen}
           autoHideDuration={3000}
           onClose={this.handleSnackClose}
           >
             <MySnackbarContentWrapper
             variant={this.state.snackVariant}
             message={this.state.snackMessage}
             onClose={this.handleSnackClose}
            />
        </Snackbar>
      </NavBar2>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(UpdateTemplate);
