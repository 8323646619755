import React from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import getSymbolFromCurrency from "currency-symbol-map";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavBar2 from "../../components/navigation/NavBar2";
import Table from "@material-ui/core/Table";
import Divider from "@material-ui/core/Divider";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { styles } from "./ProductPageStyles.js";
import withRouter from "../../components/withRouter";
import moment from "moment";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 10,
      products: [],
      firstLoadAttempted: false,
    };
  }

  changeNav = (name) => (event) => {
    let path = `/${this.props.params.org}/${name}`;
    this.props.history.push(path);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  componentDidMount() {
    console.log(this.state);
    this.fetchProduct();
  }

  deleteProduct = (productId, productName) => {
    var self = this;
    console.log(productId);
    if (
      window.confirm(
        `Are you sure you want to delete the product ${productName}`
      )
    ) {
      axios({
        method: "delete",
        url: `${apiBaseUrl}/v1/org/products/${productId}`,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then(function (response) {
          console.log(response.data);
          if (response.status === 200) {
            self.fetchProduct();
          }
        })
        .catch(function (error) {
          console.log(error);
          self.setState({ messageError: "Error retrieving products" });
        });
    }
  };

  fetchProduct = () => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/products/${self.props.params.productId}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            product: response.data,
            finished: true,
            firstLoadAttempted: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({
          messageError: "Error retrieving products",
          firstLoadAttempted: true,
        });
      });
  };

  render() {
    const { classes, ...other } = this.props;
    const plans = [];
    if (this.state.product && this.state.product.plans) {
      for (let plan of this.state.product.plans) {
        plans.push(
          <TableRow key={plan.id}>
            <TableCell>{plan.nickname || plan.id}</TableCell>
            <TableCell>
              {getSymbolFromCurrency(plan.currency) +
                Number(plan.amount / 100).toFixed(2) +
                ` every ${plan.interval_count} ${plan.interval}(s) `}
            </TableCell>
            <TableCell>
              {plan.trial_period_days
                ? `${plan.trial_period_days} days`
                : "No Trial"}
            </TableCell>
            <TableCell>
              {moment(new Date(plan.created)).format("MM/DD/YYYY")}
            </TableCell>
          </TableRow>
        );
      }
    }

    return (
      <NavBar2 header="Products" {...this.props}>
        {this.state.firstLoadAttempted ? (
          <div>
            {this.state.product ? (
              <div>
                <Grid container justify={"center"}>
                  <Card className={classes.productCard}>
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="headline"
                        component="h3"
                      >
                        {this.state.product.name}
                      </Typography>
                      <Typography component="p">
                        {this.state.product.plans
                          ? `${this.state.product.plans.length} Pricing Plan(s)`
                          : "No Pricing Plans"}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        color="primary"
                        href={`/products/${this.state.product.id}/plans/new`}
                      >
                        Add a Plan
                      </Button>
                    </CardActions>
                  </Card>
                  <Card className={classes.productCard}>
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="headline"
                        component="h4"
                      >
                        Details
                      </Typography>
                    </CardContent>
                    <Divider />
                    <CardContent>
                      <table>
                        <tbody>
                          <tr>
                            <td>ID</td>
                            <td>{this.state.product.id}</td>
                          </tr>
                          <tr>
                            <td>Name</td>
                            <td>{this.state.product.name}</td>
                          </tr>
                          <tr>
                            <td> Created </td>
                            <td>
                              {moment(
                                new Date(this.state.product.created * 1000)
                              ).format("MM/DD/YYYY")}
                            </td>
                          </tr>
                          <tr>
                            <td>Statement Descriptor</td>
                            <td>
                              {this.state.product.statement_descriptor ||
                                "none"}{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        color="primary"
                        href={`https://dashboard.stripe.com/products/${this.props.params.productId}`}
                        target="_blank"
                      >
                        Mange in Stripe
                      </Button>
                    </CardActions>
                  </Card>
                  <Card className={classes.productCard}>
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="headline"
                        component="h4"
                      >
                        Plans
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        href={`/products/${this.state.product.id}/plans/new`}
                      >
                        Add a Plan
                      </Button>
                    </CardContent>
                    <Divider />
                    <CardContent>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Nickname or ID</TableCell>
                            <TableCell>Pricing</TableCell>
                            <TableCell>Trial Period</TableCell>
                            <TableCell>Created</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>{plans}</TableBody>
                      </Table>
                    </CardContent>
                    <CardActions></CardActions>
                  </Card>
                </Grid>
              </div>
            ) : (
              <div>
                <Grid container justify={"center"}>
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image="/static/images/cards/contemplative-reptile.jpg"
                        title="Contemplative Reptile"
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="headline"
                          component="h2"
                        >
                          You don't have a product yet!
                        </Typography>
                        <Typography component="p">
                          Products provide an easy way for your customers to
                          signup with Stripe.
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button
                        variant="contained"
                        color="primary"
                        href="/products/product/new"
                      >
                        Create a Product
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </div>
            )}
          </div>
        ) : (
          <Grid container justify={"center"}>
            <div className={classes.loader}>
              <CircularProgress size={100} className={classes.progress} />
            </div>
          </Grid>
        )}
      </NavBar2>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(withRouter(Product));
