import React from "react";
import axios from "axios";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import getSymbolFromCurrency from "currency-symbol-map";
import Typography from "@material-ui/core/Typography";
import NavBar2 from "../../components/navigation/NavBar2";
import Directions from "../../components/directions/Directions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Moment from "react-moment";
import { styles } from "./FormsPageStyles.js";
import moment from "moment";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;
Moment.globalFormat = "MMM D YYYY";

class NpForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 10,
      forms: [],
      firstLoadAttempted: false,
    };
  }

  changeNav = (name) => (event) => {
    let path = `/${this.props.match.params.org}/${name}`;
    this.props.history.push(path);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  componentDidMount() {
    console.log(this.state);
    this.fetchForms(1, 10);
  }

  nextPage = (event) => {
    this.fetchForms(
      Number(this.state.page) + 1,
      Number(this.state.rowsPerPage)
    );
    this.setState({
      page: this.state.page + 1,
    });
  };

  previousPage = (event) => {
    this.fetchForms(
      Number(this.state.page) - 1,
      Number(this.state.rowsPerPage)
    );
    this.setState({
      page: this.state.page - 1,
    });
  };

  deleteForm = (formId, formName) => {
    var self = this;
    console.log(formId);
    if (
      window.confirm(`Are you sure you want to delete the form ${formName}`)
    ) {
      axios({
        method: "delete",
        url: `${apiBaseUrl}/v1/org/npforms/${formId}`,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then(function (response) {
          console.log(response.data);
          if (response.status === 200) {
            self.fetchForms();
          }
        })
        .catch(function (error) {
          console.log(error);
          self.setState({ messageError: "Error retrieving forms" });
        });
    }
  };

  fetchForms = (page, per_page) => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/npforms?page=${page}&per_page=${per_page}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            forms: response.data,
            finished: true,
            firstLoadAttempted: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({
          messageError: "Error retrieving forms",
          firstLoadAttempted: true,
        });
      });
  };

  render() {
    const { classes, ...other } = this.props;
    const forms = [];

    for (let form of this.state.forms) {
      let line_items = "";

      let formUrl = `https://forms.${process.env.REACT_APP_BASE_DOMAIN}/np/${form._id}`;

      forms.push(
        <TableRow key={form._id}>
          <TableCell>{form.name}</TableCell>
          <TableCell>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              href={`/forms/integrate/noproduct/${form._id}`}
            >
              Use the Form
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              href={`/npforms/update/${form._id}`}
            >
              Edit
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => this.deleteForm(form._id, form.name)}
            >
              Delete
            </Button>
          </TableCell>
          <TableCell>{form.description}</TableCell>
          <TableCell>
            {getSymbolFromCurrency(form.currency) +
              Number(form.amount_in_cents / 100).toFixed(2)}
          </TableCell>
          <TableCell>
            <CopyToClipboard
              text={formUrl}
              onCopy={() => this.setState({ ["copied_" + form._id]: true })}
            >
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
              >
                <Tooltip title="This url is unique to this form and can be put behind a button on your website or in an email.">
                  <span>
                    {this.state["copied_" + form._id] ? "Copied" : "Copy Link"}
                  </span>
                </Tooltip>
              </Button>
            </CopyToClipboard>
          </TableCell>
          <TableCell>
            {moment(new Date(form.createdAt)).format("MM/DD/YYYY")}
          </TableCell>
        </TableRow>
      );
    }

    return (
      <NavBar2 header="One Time Charge Forms" {...this.props}>
        {this.state.firstLoadAttempted ? (
          <div>
            {forms.length > 0 ? (
              <div>
                <Directions message="These forms charge a customer one time and save the card inside of Stripe for future payments." />
                <Paper>
                  <AppBar position="static">
                    <Toolbar>
                      <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.grow}
                      >
                        Forms - Create or manage your forms.
                      </Typography>
                      <Button
                        variant="outlined"
                        color="inherit"
                        href="/npforms/new"
                      >
                        Create a Form
                      </Button>
                    </Toolbar>
                  </AppBar>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>View/Edit</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Price</TableCell>

                        <TableCell>Unique Form URL</TableCell>

                        <TableCell>Created At</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{forms}</TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell>Page {this.state.page}</TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={(event) => this.previousPage(event)}
                            className={classes.paginationButton}
                            disabled={this.state.page <= 1}
                          >
                            Previous
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={(event) => this.nextPage(event)}
                            className={classes.paginationButton}
                            disabled={this.state.forms.length < 8}
                          >
                            Next
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Paper>
              </div>
            ) : (
              <div>
                <Grid container justify={"center"}>
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="headline"
                          component="h2"
                        >
                          You don't have a form yet!
                        </Typography>
                        <Typography component="p">
                          Forms provide an easy way for your customers to signup
                          with Stripe.
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button
                        variant="contained"
                        color="primary"
                        href="/npforms/new"
                      >
                        Create a Form
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </div>
            )}
          </div>
        ) : (
          <Grid container justify={"center"}>
            <div className={classes.loader}>
              <CircularProgress size={100} className={classes.progress} />
            </div>
          </Grid>
        )}
        <Button
          variant="fab"
          color="primary"
          aria-label="Add"
          href="/npforms/new"
          className={classes.fab}
        >
          <AddIcon />
        </Button>
      </NavBar2>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(NpForms);
