import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const listCheckouts = () =>
  axios({
    method: "GET",
    url: `${apiBaseUrl}/v2/org/checkouts`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });

const fetchCheckout = (checkoutId) =>
  axios({
    method: "GET",
    url: `${apiBaseUrl}/v2/org/checkouts/${checkoutId}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });

export { listCheckouts, fetchCheckout };
