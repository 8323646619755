import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NavBar2 from '../../components/navigation/NavBar2';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import TwitterPicker from 'react-color';
import Directions from '../../components/directions/Directions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
import ReactS3Uploader from 'react-s3-uploader';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = theme => ({

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paperAppBar:{
    marginTop:"-1%",
    marginBottom:"1%"
  },
  toolbar: theme.mixins.toolbar,
  button:{
    marginTop: theme.spacing.unit * 3
  },
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    width: "100%",
    marginLeft: "4%",
    marginRight: "4%",
    marginTop: theme.spacing.unit * 3,
  }),
  bigAvatar: {
  width: 100,
  height: 100,
  },
  currentLogo: {
    maxWidth: "50px",
    maxHeight: "50px",
  },
  popover: {
     position: 'absolute',
     zIndex: '2',
     marginBottom: "2%"
   },
   brandingButton:{
     display: "inline",
     width: "33%",
     marginTop: theme.spacing.unit * 3
   },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  textFieldHalf:{
    marginLeft: theme.spacing.unit * .5,
    marginRight: theme.spacing.unit * .5,
    width: "48%"
  }
});


class Portal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      snackOpen: false,
      snackVariant:"success",
      snackMessage:"Password has been updated",
      colorPickerPrimary: true,
      colorPickerSecondary: true,
      colorPickerText: true,
      allowCancelations: false,
      logo:"",
      business_name:"AdvancEarly",
      primary_color:"",
      secondary_color: "",
      text_color: "#fff",
      url:"https://www.integrateyourbusiness.com"
    };
  }

   changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`
    this.props.history.push(path);
    };

    handleChange = name => event => {
      this.setState({
        [name]: event.target.value,
      });
    };

    handleCheckboxChange = name => event => {
      this.setState({
        [name]: !this.state[name],
      });
    };

    onUploadFinish = (event)=> {
      console.log("Upload event", event)
      this.setState({
        logo: "https://s3-us-west-2.amazonaws.com/instapayments/" + event.fileKey,
        snackOpen: true,
        snackVariant:"success",
        snackMessage:"Your logo was uploaded."
      });
      this.updateBrandingLogo()
    };

    handleColorChange = ( color, name) => {
      console.log(color, name)
      this.setState({
        [name + "_color"]: color.hex
    })
  };

    handleSnackClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      this.setState({ snackOpen: false });
    };

fetchBranding = () => {
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/org/${localStorage.getItem('org')}/brand`, //retrieves the current authenticated user
 })
  .then(function (response) {
    if(response.status === 200){
      console.log("response", response.data.branding)
      self.setState(
      response.data.branding
      );
      console.log(self.state)
    }else{
      let path = `/${self.state.org_handle}/login`
      self.props.history.push(path);
    }
  }).catch(function (error) {
    console.log(error);
    self.setState({messageError:"Error retrieving user profile"})
  });
}

fetchMe = () =>{
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/org/me`, //retrieves the current authenticated user
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
 })
  .then(function (response) {
    if(response.status === 200){
      self.setState({
        allowCancelations: response.data.permissions.allow_cancelations,
        ...response.data.branding
      });
    }else{
      let path = `/${self.state.org_handle}/login`
      self.props.history.push(path);
    }
  })
  .catch(function (error) {
    console.log(error);
    self.setState({messageError:"Error retrieving user profile"})
  });
}



updateBrandingLogo = () => {
  var self = this;
  axios({
   method: 'put',
   url: `${apiBaseUrl}/v1/org/settings/branding`, //retrieves the current authenticated user
   headers: {
     'Authorization': 'Bearer ' + localStorage.getItem('token'),
     'Content-Type' : 'application/json'
   },
   data:{
     'logo': this.state.logo
   }
 })
  .then(function (response) {
    if(response.status === 200){
      self.setState({
        snackOpen: true,
        snackVariant:"success",
        snackMessage:"Your logo has been updated."
      });
    }else{
      let path = `/${self.state.org_handle}/login`
      self.props.history.push(path);
    }
  }).catch(function (error) {
    console.log(error);
    self.setState({messageError:"Error retrieving user profile"})
  });
}


updateBrandingColors = () => {
  var self = this;
  axios({
   method: 'put',
   url: `${apiBaseUrl}/v1/org/settings/branding`, //retrieves the current authenticated user
   headers: {
     'Authorization': 'Bearer ' + localStorage.getItem('token'),
     'Content-Type' : 'application/json'
   },
   data:{
     'primary_color': this.state.primary_color,
     'text_color': this.state.text_color,
     'secondary_color': this.state.secondary_color
   }
 })
  .then(function (response) {
    if(response.status === 200){
      self.setState(response.data);
      self.setState({
        snackOpen: true,
        snackVariant:"success",
        snackMessage:"Your branding colors have been updated."
      });
    }else{
      let path = `/${self.state.org_handle}/login`
      self.props.history.push(path);
    }
  }).catch(function (error) {
    console.log(error);
    self.setState({messageError:"Error retrieving user profile"})
  });
}

updatePermissions = () => {
  var self = this;
  axios({
   method: 'put',
   url: `${apiBaseUrl}/v1/org/settings/permissions`, //retrieves the current authenticated user
   headers: {
     'Authorization': 'Bearer ' + localStorage.getItem('token'),
     'Content-Type' : 'application/json'
   },
   data:{
     'allow_cancelations': this.state.allowCancelations
   }
 })
  .then(function (response) {
    if(response.status === 200){
      self.setState({
        snackOpen: true,
        snackVariant:"success",
        snackMessage:"Your permissions have been updated."
      });
    }else{
      let path = `/${self.state.org_handle}/login`
      self.props.history.push(path);
    }
  }).catch(function (error) {
    console.log(error);
    self.setState({messageError:"Error retrieving user profile"})
  });
}

componentDidMount(event){
 this.fetchMe()
}

render() {
  console.log(this.state)
  const { classes, ...other } = this.props;

    return (
      <NavBar2 header="Branding" {...other}>
      <Directions
      message="Upload a logo and change your colors for the forms here."
      />
      <Grid container spacing={24}>
      <Paper className={classes.paper} elevation={4}>
      <AppBar position="static" className={classes.paperAppBar} color="secondary">
         <Toolbar>
           <Typography variant="h6" color="inherit" className={classes.flex}>
             Branding
           </Typography>
         </Toolbar>
       </AppBar>

      <form className={classes.container} noValidate autoComplete="off">

<Grid item xs={12}>
    <Button
    variant="contained"
    style={{backgroundColor: this.state.primary_color}}
    className={classes.brandingButton}
    onClick={(event) => this.handleColorPicker("Primary")}
    >
    Primary Color
    </Button>
    {this.state.colorPickerPrimary ?
      <div style={ styles.popover }>
    <TwitterPicker
    onChange={ (color)=> this.handleColorChange(color, "primary") }
    color={ this.state.primary_color }
    />
    </div>
    : null
  }

    <Button
    variant="contained"
    style={
      {
        backgroundColor: this.state.secondary_color
      }
    }
    className={classes.brandingButton}
    onClick={(event) => this.handleColorPicker("Secondary")}
    >
    Secondary Color
    </Button>
    {this.state.colorPickerSecondary ?
      <div style={ styles.popover }>
    <TwitterPicker
    onChange={ (color)=> this.handleColorChange(color, "secondary") }
    color={ this.state.secondary_color }
    />
    </div>
    : null
  }


    <Button
    variant="contained"
    style={{backgroundColor: this.state.text_color}}
    className={classes.brandingButton}
    onClick={(event) => this.handleColorPicker("Text")}
    >
    Text Color
    </Button>
    {this.state.colorPickerText ?
    <div style={ styles.popover }>
    <TwitterPicker
    onChange={ (color) => this.handleColorChange(color, "text") }
    color={this.state.text_color }
    />
    </div>
    : null
  }
  </Grid>

        <Grid container justify={"center"}>
        <br />
        <Button
        variant="contained"
        color="primary"

        className={classes.button}
        onClick={(event) => this.updateBrandingColors()}
        >
        Update Branding
        </Button>
        </Grid>

        </form>
      </Paper>

      <Paper className={classes.paper} elevation={4}>
      <AppBar position="static" className={classes.paperAppBar} color="secondary">
         <Toolbar>
           <Typography variant="h6" color="inherit" className={classes.flex}>
             Logo
           </Typography>
           <Typography variant="subheading" color="inherit" className={classes.flex}>
              {" : select a new image file to auto upload your new logo."}
           </Typography>
         </Toolbar>
       </AppBar>
       <div>
       Current Logo:
       <img className={classes.currentLogo} alt="logo" src={this.state.logo}/>
       </div>
       <br/>
       <div>
       <ReactS3Uploader
       signingUrl="/s3/sign"
       signingUrlMethod="GET"
       accept="image/*"
       preprocess={this.onUploadStart}
       onSignedUrl={this.onSignedUrl}
       onProgress={this.onUploadProgress}
       onError={this.onUploadError}
       onFinish={this.onUploadFinish}
       signingUrlWithCredentials={ true }
       uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}
       contentDisposition="auto"
       scrubFilename={(filename) => localStorage.getItem('org_id') + "_" + localStorage.getItem('org')}
       server={apiBaseUrl}
       autoUpload={true}
       />
     </div>
      </Paper>

      <Paper className={classes.paper} elevation={4}>
      <AppBar position="static" className={classes.paperAppBar} color="secondary">
         <Toolbar>
           <Typography variant="h6" color="inherit" className={classes.flex}>
             Functionality
           </Typography>
         </Toolbar>
       </AppBar>
      <form className={classes.container} noValidate autoComplete="off">
      <FormControlLabel
               control={
                 <Checkbox
                   checked={this.state.allowCancelations}
                   onChange={this.handleCheckboxChange('allowCancelations')}
                   color="primary"
                 />
               }
               label="Allow Cancelations"
             />

        <Grid container justify={"center"}>
        <br />
        <Button
        variant="contained"
        color="primary"

        className={classes.button}
        onClick={(event) => this.updatePermissions()}
        >
        Save Changes
        </Button>
        </Grid>

        </form>
      </Paper>
      </Grid>
        <Snackbar
           anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'center',
           }}
           open={this.state.snackOpen}
           autoHideDuration={3000}
           onClose={this.handleSnackClose}
           >
             <MySnackbarContentWrapper
             variant={this.state.snackVariant}
             message={this.state.snackMessage}
             onClose={this.handleSnackClose}
            />
        </Snackbar>
    </NavBar2>
    );
  }
}


//
/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Portal);
