import Paper from '@material-ui/core/Paper';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
  root:{
    marginTop:150
  },
  background:{
    //backgroundColor: "grey"
  },
  paper: theme.mixins.gutters({
    paddingTop: 50,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3,
  }),
  container: {
   display: 'flex',
   flexWrap: 'wrap',
 },
 textField: {
   marginLeft: theme.spacing.unit,
   marginRight: theme.spacing.unit,
 },
 button: {
   //width:00,
    margin: theme.spacing.unit,
  },
  bigAvatar: {
  width: 100,
  height: 100,
  },
 menu: {
   width: 200,
 },
 logo:{
   maxWidth: "60px",
   maxHeight: "60px"
 }
});


class OrgFinder extends React.Component {
  constructor(props){
    super(props);
    this.state={
      org:''
    }
  }

  handleChange = name => event => {
   this.setState({
     [name]: event.target.value,
   });
 };
 enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if(code === 13) { //13 is the enter keycode
        this.handleClick(event);
    }
}
componentDidMount(event){
  var storedOrg = localStorage.getItem('org');
  if (storedOrg != 'undefined' && storedOrg != null && storedOrg != 'null' && storedOrg != undefined) {
    let path = `/${storedOrg}/login`
    this.props.history.push(path);
  }
}

  handleClick(event){
    let path = `/${this.state.org.toLowerCase()}/login`
    this.props.history.push(path);
  }

  render() {
    const { classes } = this.props;
    return (
    <div className={classes.background}>
    <Grid container className={classes.root} justify={"center"}>

      <Paper className={classes.paper} elevation={4}>
      <Grid container justify={"center"}>

         <img className={classes.logo} src={process.env.REACT_APP_LOGO_URL_1} alt="logo"/>

      </Grid>

      <form className={classes.container} noValidate autoComplete="off">
      <TextField
      label="Org Handle"
      id="margin-none"
      fullWidth
      className={classes.textField}
      helperText="What org are you trying to log into?"
      placeholder="acme"
      InputProps={{
        startAdornment: <InputAdornment position="start">instapayments.io/</InputAdornment>,
      }}
      onChange={this.handleChange('org')}
      onKeyPress={this.enterPressed.bind(this)}
      />

        <Grid container justify={"center"}>
        <br />
        <Button
        variant="contained"
        color="primary"
        fullWidth="true"
        className={classes.button}
        onClick={(event) => this.handleClick(event)}
        >
        Login
        </Button>
        </Grid>

        </form>
      </Paper>


    </Grid>
    </div>
  );
}
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(OrgFinder);
