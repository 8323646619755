const styles = theme => ({
  card: {
     maxWidth: "70%",
   },
   media: {
     height: "50%",
   },
   grow: {
   flexGrow: 1,
 },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 11,
    right: theme.spacing.unit * 3,
    zIndex: 9999,
  },
  loader:{
    marginTop: "15%"
  }
});

export {styles}
