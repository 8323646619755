import { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const BillingPage = () => {
  const fetchPortalSession = async () => {
    let { data: session } = await axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/me/billing`, //retrieves the current authenticated user
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
    console.log(session);
    window.location.href = session.url;
  };
  useEffect(() => {
    fetchPortalSession();
  }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <p>Loading your billing portal...</p>
        <CircularProgress />
      </div>
    </div>
  );
};

export default BillingPage;
