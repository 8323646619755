import Paper from "@material-ui/core/Paper";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { customAlphabet } from "nanoid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Avatar from "@material-ui/core/Avatar";
import queryString from "qs";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Snackbar from "@material-ui/core/Snackbar";
import stripeButton from "../../images/stripe-button.png";
import { StripeProvider } from "react-stripe-elements";
import MyStoreCheckout from "../../components/checkout/MyStoreCheckout";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
import { useSnackbar } from "notistack";

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = (theme) => ({
  background: {
    overflowX: "hidden",
  },
  paper: {
    padding: 20,
    marginTop: 50,
  },
  bigAvatar: {
    width: 75,
    height: 75,
    backgroundColor: "#fff",
  },
  logo: {
    maxWidth: "60px",
    maxHeight: "60px",
  },
});

function convertToHandle(regular_name) {
  regular_name = regular_name.toLowerCase();
  regular_name = regular_name.replace(/ /g, "_");
  return regular_name.replace(/[^a-z0-9_]/g, "");
}

function getSteps() {
  return ["Organization Info", "Stripe Connection", "Free Trial"];
}

const Register3 = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  let [state, setState] = useState({
    username: "test",
    password: "",
    messageError: "",
    messageSuccess: "",
    createOrgDisabled: false,
  });
  const [activeStep, setActiveStep] = useState(0);

  const { classes } = props;
  const steps = getSteps();

  useEffect(() => {
    console.log("PROPS", props);
    const query = queryString.parse(window?.location?.search, {
      ignoreQueryPrefix: true,
    });
    if (query.step) {
      setActiveStep(Number(query.step) || 0);
    }
    if (query.plan) {
      localStorage.setItem("plan", query.plan);
    } else {
      localStorage.setItem("plan", "essential");
    }
    if (query.code) {
      updateOrg(query.code);
    }
    if (localStorage.getItem("token")) {
      fetchUser();
    }
  }, []);

  const fetchUser = () => {
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/me`, //retrieves the current authenticated user
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        if (response.status === 200) {
          setState({
            ...state,
            org: response.data,
            stripe_account:
              response.data.stripe_credentials &&
              response.data.stripe_credentials.stripe_user_id,
            finished: true,
          });
        } else {
          let path = `/${state.org_handle}/login`;
          props.history.push(path);
        }
      })
      .catch(function (error) {
        console.log(error);
        setState({ ...state, messageError: "Error retrieving user profile" });
      });
  };

  const updateOrg = (code) => {
    setState({
      ...state,
      stripeUpdateFinished: false,
    });
    axios({
      method: "POST",
      url: `${apiBaseUrl}/v1/org/settings/stripe/update`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        code: code,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          setState({
            ...state,
            stripeUpdateFinished: true,
          });
          setActiveStep(2);
          fetchUser();
        }
      })
      .catch(function (error) {
        console.log(error?.response?.data?.message);
        enqueueSnackbar(
          error?.response?.data?.message ||
            "Error adding Stripe connection to profile.",

          { variant: "error" }
        );
        fetchUser();
      });
  };

  const handleChange = (name, event) => {
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, snackOpen: false });
  };

  const createOrg = (e) => {
    e.preventDefault();
    console.log("createOrg was called");
    const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);
    let org_handle = nanoid();
    setState({
      ...state,
      org_handle,
      company_name: org_handle,
      createOrgDisabled: true,
    });
    var payload = {
      first_name: state.first_name,
      last_name: state.last_name,
      phone: state.phone,
      organization_name: org_handle,
      org_handle: org_handle,
      username: state.username,
      email: state.username,
      password: state.password,
    };
    console.log(payload);
    if (!payload.username || !payload.password) {
      setState({ ...state, messageError: "These fields are required" });
      enqueueSnackbar("Username and password are required", {
        variant: "error",
      });
    } else {
      axios
        .post(`${apiBaseUrl}/v1/org/new`, payload)
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            setState({
              ...state,
              createOrgDisabled: false,
            });
            loginUser(org_handle);
          } else {
            let path = `/register`;
            props.history.push(path);
          }
        })
        .catch(function (error) {
          console.log("response", error);
          if (error?.response?.status === 422) {
            setState({
              ...state,
              createOrgDisabled: false,
              snackOpen: true,
              snackVariant: "error",
              snackMessage:
                error?.response?.data?.errorsArray?.[0]?.msg ||
                "There was an error creating your account. Please check your information and try again.",
            });
          } else {
            setState({
              ...state,
              createOrgDisabled: false,
              snackOpen: true,
              snackVariant: "error",
              snackMessage: "Could not create new Org.",
            });
          }
        });
    }
  };

  const loginUser = (org_handle) => {
    const payload = {
      username: state.username,
      password: state.password,
    };
    if (!payload.username || !payload.password) {
      setState({ ...state, messageError: "These fields are required" });
    } else {
      axios
        .post(
          `${apiBaseUrl}/v1/org/${
            state?.org_handle?.toLowerCase() || org_handle
          }/login`,
          payload
        )
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("timestamp", new Date().getTime());
            localStorage.setItem(
              "name",
              `${response.data.first_name} ${response.data.last_name}`
            );
            localStorage.setItem("first_name", response.data.first_name);
            localStorage.setItem("last_name", response.data.last_name);
            localStorage.setItem(
              "gravatar",
              `https://www.gravatar.com/avatar/${response.data.gravatar}?d=mp`
            );
            localStorage.setItem("org", response.data.org);
            localStorage.setItem("org_id", response.data.org_id);
            localStorage.setItem("admin_id", response.data.admin_id);
            console.log("line before set state");
            setActiveStep(1);
            fetchUser();
          }
        })
        .catch(function (error) {
          console.log(error);
          enqueueSnackbar(
            error?.response?.data?.message || "Error logging into org.",
            { variant: "error" }
          );
        });
    }
  };

  console.log({ state });
  return (
    <Grid
      className={classes.background}
      container
      justify={"center"}
      spacing={1}
    >
      <Grid item xs={12} md={6} lg={5}>
        <Paper className={classes.paper} elevation={4}>
          <Grid container justify={"center"}>
            <Avatar aria-label="Logo" className={classes.bigAvatar}>
              <img
                className={classes.logo}
                src={process.env.REACT_APP_LOGO_URL_1}
                alt="logo"
              />
            </Avatar>
          </Grid>
          <Grid container justify={"center"}>
            <div style={{ width: "100%" }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>
          </Grid>

          {activeStep === 0 ? (
            <form
              className={classes.container}
              onSubmit={(event) => createOrg(event)}
            >
              <Grid container justify={"center"} spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="First Name"
                    name="first_name"
                    required
                    autoComplete="given-name"
                    fullWidth
                    helperText="Enter your first name"
                    onChange={(e) => handleChange("first_name", e)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    label="Last Name"
                    name="last_name"
                    autoComplete="family-name"
                    required
                    fullWidth
                    helperText="Enter your last name"
                    onChange={(e) => handleChange("last_name", e)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    label="Email"
                    name="email"
                    required
                    fullWidth
                    autoComplete="email"
                    helperText="Enter your username"
                    onChange={(e) => handleChange("username", e)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    label="Phone"
                    required
                    name="phone"
                    fullWidth
                    autoComplete="tel"
                    helperText="Enter your phone number"
                    onChange={(e) => handleChange("phone", e)}
                  />
                </Grid>
                {/* <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    label="Company Name"
                    name="company"
                    required
                    fullWidth
                    onChange={() => handleChange("company_name")}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    label="Unique URL Identifier"
                    name="org_handle"
                    required
                    pattern="[a-z0-9\-\_]+"
                    fullWidth
                    className={classes.textField}
                    helperText="Identifier to your org. Customers will see this."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          https://instapayments.io/
                        </InputAdornment>
                      ),
                    }}
                    value={state.org_handle}
                    onChange={() => handleChange("org_handle")}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    label="Password"
                    required
                    fullWidth
                    name="password"
                    type="password"
                    className={classes.textField}
                    helperText="Enter your password"
                    onChange={(e) => handleChange("password", e)}
                  />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={state.createOrgDisabled}
                    type="submit"
                  >
                    Get Started
                  </Button>
                </Grid>
              </Grid>
            </form>
          ) : null}

          {activeStep === 1 ? (
            state.stripe_account ? (
              <Grid container justify={"center"}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={true}
                  href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=https://orgs.${process.env.REACT_APP_BASE_DOMAIN}/register3?step=2`}
                >
                  Connected
                </Button>
              </Grid>
            ) : (
              <Grid container justify={"center"}>
                <a
                  href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=https://orgs.${process.env.REACT_APP_BASE_DOMAIN}/register3?step=1`}
                >
                  <img src={stripeButton} alt="stripe-button" />
                </a>
              </Grid>
            )
          ) : null}

          {activeStep === 2 ? (
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PK_KEY}>
              <MyStoreCheckout {...props} />
            </StripeProvider>
          ) : null}
        </Paper>
      </Grid>
    </Grid>
  );
};

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Register3);
