import axios from "axios";
import Button from "@material-ui/core/Button";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import NavigationList from "../../components/navigation/NavigationList";
import FormHelperText from "@material-ui/core/FormHelperText";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import NavBar2 from "../../components/navigation/NavBar2";
import Paper from "@material-ui/core/Paper";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SyntaxHighlighter from "react-syntax-highlighter";
import TextField from "@material-ui/core/TextField";
import ReactS3Uploader from "react-s3-uploader";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
import StripeCheckout from "react-stripe-checkout";
import { prism } from "react-syntax-highlighter/styles/prism";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar,
  paper: theme.mixins.gutters({
    paddingTop: 30,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3,
  }),
  textField: {
    margin: "5px",
  },
});

class SnippetCustomizer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "$5/mo",
      amount: 500,
      organization_name: "",
      currency: "USD",
      logo: `https://instapayments.s3.us-west-2.amazonaws.com/${
        this.props.match.params.snippetId
      }_${localStorage.getItem("org")}`,
      label: "Pay with Card",
    };
  }

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  fetchMe = () => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/me`, //retrieves the current authenticated user
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        if (response.status == 200) {
          console.log(response.data);
          self.setState({
            org: response.data,
            company_name: response.data.organization_name,
            key: response.data.stripe_credentials.stripe_publishable_key,
            finished: true,
            organization_name: response.data.organization_name,
          });
        } else {
          let path = `/${self.state.org_handle}/login`;
          self.props.history.push(path);
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving user profile" });
      });
  };

  fetchChargeInstruction = () => {
    var self = this;
    console.log(this.state);
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/chargeinstructions/${self.props.match.params.snippetId}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            url: `https://backend.instapayments.io/v1/form/${response.data.base_url}${response.data._id}`,
            amount_in_cents: response.data.amount_in_cents,
            amount: response.data.amount_in_cents,
            description: response.data.description,
            name: response.data.name,
            currency: response.data.currency,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving forms" });
      });
  };

  fetchRecurringInstruction = () => {
    var self = this;
    console.log(this.state);
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/recurringinstructions/${self.props.match.params.snippetId}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            name: response.data.name,
            description: response.data.description,
            plan_ids: response.data.plan_ids,
            redirect_url: response.data.redirect_url,
            url: `https://backend.instapayments.io/v1/form${response.data.base_url}${response.data._id}`,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving forms" });
      });
  };

  goHome = () => {
    let path = `/chargeinstructions/${this.props.match.params.snippetType}`;
    this.props.history.push(path);
  };

  onUploadFinish = (event) => {
    console.log("Upload event", event);
    this.setState({
      logo: "https://s3-us-west-2.amazonaws.com/instapayments/" + event.fileKey,
      snackOpen: true,
      snackVariant: "success",
      snackMessage: "Your logo was uploaded.",
    });
  };

  onToken = (token) => {
    alert(`Your checkout was successfull. We did NOT charge the card or create a Stripe Customer like we normally would. Normally, your visitors would be redirected to ${this.state.redirect_url}.
      If you want to do a full checkout, use the codepen button OR copy and paste the snippet onto your website.`);
  };

  componentDidMount() {
    this.fetchMe();
    if (this.props.match.params.snippetType === "onetime") {
      this.fetchChargeInstruction();
    } else {
      this.fetchRecurringInstruction();
    }
  }

  render() {
    const { classes, ...other } = this.props;
    let codeString = "";
    if (this.props.match.params.snippetType === "onetime") {
      codeString =
        `<form action="${this.state.url}" method="POST">\n` +
        "<script\n" +
        'src="https://checkout.stripe.com/checkout.js" class="stripe-button"\n' +
        `data-key="${this.state.key}"\n` +
        `data-amount="${Number(this.state.amount) || 500}"\n` +
        `data-currency="${this.state.currency || "usd"}"\n` +
        `data-name="${this.state.organization_name}"\n` +
        `data-description="${this.state.description}"\n` +
        `data-image="${this.state.logo}"\n` +
        `data-label="${this.state.label}"\n` +
        `data-panel-label="Pay"\n` +
        'data-locale="auto">\n' +
        "</script>\n" +
        "</form>";
    } else {
      codeString =
        `<form action="${this.state.url}" method="POST">\n` +
        "<script\n" +
        'src="https://checkout.stripe.com/checkout.js" class="stripe-button"\n' +
        `data-key="${this.state.key}"\n` +
        `data-amount="${this.state.amount || 500}"\n` +
        `data-currency="${this.state.currency || "usd"}"\n` +
        `data-name="${this.state.organization_name}"\n` +
        `data-description="${this.state.description}"\n` +
        `data-image="${this.state.logo}"\n` +
        `data-label="${this.state.label}"\n` +
        `data-panel-label="Subscribe for"\n` +
        'data-locale="auto">\n' +
        "</script>\n" +
        "</form>";
    }

    const codePenString = JSON.stringify({
      title: "Stripe Button Preview",
      html: codeString,
    });

    const stripeCheckout = (
      <StripeCheckout
        token={this.onToken}
        stripeKey={this.state.key}
        amount={this.state.amount}
        currency={this.state.currency}
        name={this.state.organization_name}
        image={this.state.logo}
        description={this.state.description}
        label={this.state.label}
        reconfigureOnUpdate={true}
      />
    );

    return (
      <NavBar2 header={this.state.name} {...other}>
        <Paper className={classes.paper}>
          <h5>
            This page will help modify frontend appeance ONLY. The actual charge
            and redirect URL made server side can be{" "}
            <a
              href={`/chargeinstruction/update/${this.props.match.params.snippetType}/${this.props.match.params.snippetId}`}
            >
              configured here.
            </a>
          </h5>
          <h4>Preview the Button:</h4>
          {stripeCheckout}
          <p>
            Use the fields below to change the appearance of the checkout. Click
            The Test Button to preview. Once it's how you like it, copy and
            paste the HTML onto your website. i.e. many website services like
            Weebly, Squarespace etc. provide an HTML field or element.
          </p>

          <TextField
            label="Organization Name"
            fullWidth={false}
            variant="outlined"
            required
            type="text"
            helperText="Organization Name i.e Acme."
            value={this.state.organization_name}
            className={classes.textField}
            onChange={this.handleChange("organization_name")}
          />
          <TextField
            label="Description"
            fullWidth={false}
            variant="outlined"
            required
            type="text"
            helperText="Description under your company's name."
            value={this.state.description}
            className={classes.textField}
            onChange={this.handleChange("description")}
          />
          <TextField
            label="Button amount in cents"
            fullWidth={false}
            variant="outlined"
            required
            type="text"
            helperText="200"
            value={this.state.amount}
            className={classes.textField}
            onChange={this.handleChange("amount")}
          />
          <TextField
            label="Currency Code"
            fullWidth={false}
            variant="outlined"
            required
            type="text"
            helperText="Three digit currency Code i.e. USD."
            value={this.state.currency}
            className={classes.textField}
            onChange={this.handleChange("currency")}
          />
          <TextField
            label="Stripe Button Text"
            fullWidth={false}
            variant="outlined"
            required
            type="text"
            helperText="This defaults to 'Pay with Card' ."
            value={this.state.label}
            className={classes.textField}
            onChange={this.handleChange("label")}
          />
          <div>
            <img
              className={classes.currentLogo}
              alt="Upload a logo"
              src={this.state.logo}
              style={{ maxHeight: "50px" }}
            />
          </div>
          <br />
          <div>
            <ReactS3Uploader
              signingUrl="/s3/sign"
              signingUrlMethod="GET"
              accept="image/*"
              preprocess={this.onUploadStart}
              onSignedUrl={this.onSignedUrl}
              onProgress={this.onUploadProgress}
              onError={this.onUploadError}
              onFinish={this.onUploadFinish}
              signingUrlWithCredentials={true}
              uploadRequestHeaders={{ "x-amz-acl": "public-read" }}
              contentDisposition="auto"
              scrubFilename={(filename) =>
                this.props.match.params.snippetId +
                "_" +
                localStorage.getItem("org")
              }
              server={apiBaseUrl}
              autoUpload={true}
            />
          </div>
          <FormHelperText>Upload a logo</FormHelperText>

          <SyntaxHighlighter language="javascript" style={prism}>
            {codeString}
          </SyntaxHighlighter>
          <CopyToClipboard
            text={codeString}
            onCopy={() => this.setState({ copied: true })}
          >
            <Button variant="outlined" color="primary">
              {this.state.copied ? "Copied" : "Copy Snippet"}
            </Button>
          </CopyToClipboard>
          <br />
          <form
            action="https://codepen.io/pen/define"
            method="POST"
            target="_blank"
          >
            <input type="hidden" name="data" value={codePenString} />
            <Button type="submit" variant="outlined" color="primary">
              Test/Preview in CodePen
            </Button>
          </form>
        </Paper>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
        >
          <MySnackbarContentWrapper
            variant={this.state.snackVariant}
            message={this.state.snackMessage}
            onClose={this.handleSnackClose}
          />
        </Snackbar>
      </NavBar2>
    );
  }
}

export default withStyles(styles)(SnippetCustomizer);
