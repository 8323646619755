import Paper from "@material-ui/core/Paper";
import React from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Avatar from "@material-ui/core/Avatar";
import queryString from "qs";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Snackbar from "@material-ui/core/Snackbar";
import stripeButton from "../../images/stripe-button.png";
import { StripeProvider } from "react-stripe-elements";
import MyStoreCheckout from "../../components/checkout/MyStoreCheckout";
import MySnackbarContentWrapper from "../../components/messages/customSnack";

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = (theme) => ({
  root: {
    //marginTop:15,
    //width: "50%",
    //marginLeft: "25%"
  },
  background: {
    height: "95vh",
    backgroundColor: "#fafafa",
  },
  paper: theme.mixins.gutters({
    paddingTop: 20,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 5,
  }),
  // container: {
  //   display: "flex",
  //   flexWrap: "wrap",
  // },
  bigAvatar: {
    width: 75,
    height: 75,
    backgroundColor: "#fff",
  },
  logo: {
    maxWidth: "60px",
    maxHeight: "60px",
  },
});

function convertToHandle(regular_name) {
  regular_name = regular_name.toLowerCase();
  regular_name = regular_name.replace(/ /g, "_");
  return regular_name.replace(/[^a-z0-9_]/g, "");
}

function getSteps() {
  return ["Organization Info", "Stripe Connection", "Hubspot Connection"];
}

class HubspotRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "test",
      password: "",
      messageError: "",
      messageSuccess: "",
      activeStep: 0,
      createOrgDisabled: false,
    };
  }

  componentDidMount(event) {
    const query = queryString.parse(window?.location?.search, {
      ignoreQueryPrefix: true,
    });
    console.log(query);
    if (query.step) {
      this.setState({
        activeStep: Number(query.step) || 0,
      });
    }
    if (query.plan) {
      localStorage.setItem("plan", query.plan);
    } else {
      localStorage.setItem("plan", "essential");
    }
    if (query.code) {
      this.updateOrg(query.code);
    }
    if (localStorage.getItem("token")) {
      this.fetchUser();
    }
  }

  fetchUser = () => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/me`, //retrieves the current authenticated user
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        if (response.status === 200) {
          self.setState({
            org: response.data,
            stripe_account:
              response.data.stripe_credentials &&
              response.data.stripe_credentials.stripe_user_id,
            finished: true,
          });
          console.log(self.state.org.billing);
          /*if (self.state.org.billing && self.state.org.billing.subscriptions.data.length > 0) {
          let path = `/dashboard`
          self.props.history.push(path);
        }*/
        } else {
          let path = `/${self.state.org_handle}/login`;
          self.props.history.push(path);
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving user profile" });
      });
  };

  updateOrg = (code) => {
    var self = this;
    self.setState({
      stripeUpdateFinished: false,
    });
    axios({
      method: "POST",
      url: `${apiBaseUrl}/v1/org/settings/stripe/update`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        code: code,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          self.setState({
            stripeUpdateFinished: true,
          });
          self.fetchUser();
        }
      })
      .catch(function (error) {
        console.log(error);
        self.fetchUser();
        self.setState({ messageError: "Error updating user" });
      });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    if (name === "company_name") {
      this.setState({
        org_handle: convertToHandle(event.target.value),
      });
    }
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  loginUser = () => {
    var self = this;
    var payload = {
      username: this.state.username,
      password: this.state.password,
    };
    if (!payload.username || !payload.password) {
      self.setState({ messageError: "These fields are required" });
    } else {
      axios
        .post(
          `${apiBaseUrl}/v1/org/${this.state.org_handle.toLowerCase()}/login`,
          payload
        )
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("timestamp", new Date().getTime());
            localStorage.setItem(
              "name",
              `${response.data.first_name} ${response.data.last_name}`
            );
            localStorage.setItem("first_name", response.data.first_name);
            localStorage.setItem("last_name", response.data.last_name);
            localStorage.setItem(
              "gravatar",
              `https://www.gravatar.com/avatar/${response.data.gravatar}?d=mp`
            );
            localStorage.setItem("org", response.data.org);
            localStorage.setItem("org_id", response.data.org_id);
            localStorage.setItem("admin_id", response.data.admin_id);
    
            self.setState({
              activeStep: 1,
            });
            self.fetchUser();
          }
        })
        .catch(function (error) {
          console.log(error);
          self.setState({
            usernameMessage: "Incorrect Username or Password",
            passwordMessage: "Incorrect Username or Password",
            usernameInvalid: true,
            passwordInvalid: true,
            snackOpen: true,
            snackVariant: "error",
            snackMessage: "Your logging you in as a new user.",
          });
        });
    }
  };

  createOrg(event) {
    var self = this;
    event.preventDefault();
    this.setState({
      createOrgDisabled: true,
    });
    var payload = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      phone: this.state.phone,
      organization_name: this.state.company_name,
      org_handle: this.state.org_handle.toLowerCase(),
      username: this.state.username,
      email: this.state.username,
      password: this.state.password,
    };
    console.log(payload);
    if (!payload.username || !payload.password) {
      self.setState({ messageError: "These fields are required" });
    } else {
      axios
        .post(`${apiBaseUrl}/v1/org/new`, payload)
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            self.setState({
              createOrgDisabled: false,
            });
            self.loginUser();
          } else {
            let path = `/register`;
            self.props.history.push(path);
          }
        })
        .catch(function (error) {
          console.log("response", error);
          if (error.response.status === 422) {
            self.setState({
              createOrgDisabled: false,
              snackOpen: true,
              snackVariant: "error",
              snackMessage: error.response.data.errorsArray[0].msg,
            });
          } else {
            self.setState({
              createOrgDisabled: false,
              snackOpen: true,
              snackVariant: "error",
              snackMessage: "Could not create new Org.",
            });
          }
        });
    }
  }

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;
    return (
      <div className={classes.background}>
        <Grid container justify={"center"} spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper} elevation={4}>
              <Grid container justify={"center"}>
                <Avatar aria-label="Logo" className={classes.bigAvatar}>
                  <img
                    className={classes.logo}
                    src={process.env.REACT_APP_LOGO_URL_1}
                    alt="logo"
                  />
                </Avatar>
              </Grid>
              <Grid container justify={"center"}>
                <div style={{ width: "100%" }}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => {
                      return (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </div>
              </Grid>

              {activeStep === 0 ? (
                <form
                  className={classes.container}
                  onSubmit={(event) => this.createOrg(event)}
                >
                  <Grid container justify={"center"} spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        label="First Name"
                        name="first_name"
                        required
                        fullWidth
                        helperText="Enter your first name"
                        onChange={this.handleChange("first_name")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        label="Last Name"
                        name="last_name"
                        required
                        fullWidth
                        helperText="Enter your last name"
                        onChange={this.handleChange("last_name")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        label="Email"
                        name="email"
                        required
                        fullWidth
                        helperText="Enter your username"
                        onChange={this.handleChange("username")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        label="Phone"
                        required
                        name="phone"
                        fullWidth
                        helperText="Enter your phone number"
                        onChange={this.handleChange("phone")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        label="Company Name"
                        name="company"
                        required
                        fullWidth
                        onChange={this.handleChange("company_name")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        label="Unique URL Identifier"
                        name="org_handle"
                        required
                        pattern="[a-z0-9\-\_]+"
                        fullWidth
                        className={classes.textField}
                        helperText="URL friendly version of your company name."
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              https://instapayments.io/
                            </InputAdornment>
                          ),
                        }}
                        value={this.state.org_handle}
                        onChange={this.handleChange("org_handle")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Password"
                        required
                        fullWidth
                        name="password"
                        type="password"
                        className={classes.textField}
                        helperText="Enter your password"
                        onChange={this.handleChange("password")}
                      />
                    </Grid>
                    <Grid item xs={12} xl={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={this.state.createOrgDisabled}
                        type="submit"
                      >
                        Get Started
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              ) : null}

              {activeStep === 1 ? (
                this.state.stripe_account ? (
                  <Grid container justify={"center"}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      disabled={true}
                    >
                      Connected
                    </Button>
                  </Grid>
                ) : (
                  <Grid container justify={"center"}>
                    <a
                      href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=https://orgs.${process.env.REACT_APP_BASE_DOMAIN}/register/hubspot?step=2`}
                    >
                      <img src={stripeButton} alt="stripe-button" />
                    </a>
                  </Grid>
                )
              ) : null}

              {activeStep === 2 ? (
                <Grid container justify={"center"}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#ff7a59",
                      color: "#fff",
                      height: "60px",
                    }}
                    href={`https://app.hubspot.com/oauth/authorize?client_id=302b1bb6-b453-48f8-af2e-8a03630be915&redirect_uri=https://orgs.instapayments.io/settings/integrations/hubspot&scope=contacts%20timeline%20oauth%20tickets`}
                    className={classes.button}
                  >
                    Connect to Hubspot
                  </Button>
                </Grid>
              ) : null}
            </Paper>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
        >
          <MySnackbarContentWrapper
            variant={this.state.snackVariant}
            message={this.state.snackMessage}
            onClose={this.handleSnackClose}
          />
        </Snackbar>
      </div>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(HubspotRegister);
