import Paper from '@material-ui/core/Paper';
import React from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = theme => ({
  root:{
    marginTop:"10%"
  },
  background:{
    //backgroundColor: "grey"
  },
  paper: theme.mixins.gutters({
    paddingTop: 50,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3,
  }),
  container: {
   display: 'flex',
   flexWrap: 'wrap',
 },
 textField: {
   marginLeft: theme.spacing.unit,
   marginRight: theme.spacing.unit,
 },
 button: {
   //width:00,
    margin: theme.spacing.unit,
  },
  bigAvatar: {
  width: 75,
  height: 75,
   backgroundColor: "#fff"
  },
 menu: {
   width: 100,
 },
 logo:{
 maxWidth: "60px",
 maxHeight: "60px",
}
});


class PasswordReset extends React.Component {
  constructor(props){
    super(props);
    this.state={
      username:'test',
      password:'',
      messageError:'',
      usernameMessage:'Enter your username',
      passwordMessage:'Enter your password',
      usernameInvalid: false,
      passwordInvalid: false
    }
  }

  handleChange = name => event => {
   this.setState({
     [name]: event.target.value,
   });
 };

 handleSnackClose = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }
   this.setState({ snackOpen: false });
 };

  handleClick(event){
    console.log(this.state)
    var self = this;

    var payload={
      "email": this.state.email,
    }

    axios.post(`${apiBaseUrl}/v1/org/email/${self.props.match.params.org}/password/reset`, payload)
    .then(function (response) {
      if(response.status === 200){
            self.setState({
              snackOpen: true,
              snackVariant:"success",
              snackMessage:"Your reset email is on its way."
            });
      }})
    .catch(function (error) {
      console.log(error);
    });

}

  render() {
    const { classes } = this.props;
    return (
    <div className={classes.background}>
    <Grid container className={classes.root} justify={"center"}>

      <Paper className={classes.paper} elevation={4}>
      <Grid container justify={"center"}>

            <img className={classes.logo} src={process.env.REACT_APP_LOGO_URL_1} alt="logo"/>

      </Grid>

      <form className={classes.container} autoComplete="off">
        <TextField
        label="Email"
        id="margin-none"
        fullWidth
        error={this.state.usernameInvalid}
        className={classes.textField}
        helperText={this.state.usernameMessage}
        onChange={this.handleChange('email')}
        />

        <Grid container justify={"center"}>
        <br />
        <Button
        variant="contained"
        color="primary"
        fullWidth="true"
        className={classes.button}
        onClick={(event) => this.handleClick(event)}
        >
        Submit
        </Button>
        </Grid>

        </form>
      </Paper>
    </Grid>
    <Snackbar
       anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'center',
       }}
       open={this.state.snackOpen}
       autoHideDuration={3000}
       onClose={this.handleSnackClose}
       >
         <MySnackbarContentWrapper
         variant={this.state.snackVariant}
         message={this.state.snackMessage}
         onClose={this.handleSnackClose}
        />
    </Snackbar>
    </div>
  );
}
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(PasswordReset);
