import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavBar2 from "../../components/navigation/NavBar2";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import { StripeProvider } from "react-stripe-elements";
import CardUpdate from "../../components/checkout/CardUpdate";
import axios from "axios";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paperAppBar: {
    marginTop: "-1%",
    marginBottom: "1%",
  },
  toolbar: theme.mixins.toolbar,
  button: {
    marginTop: theme.spacing.unit * 3,
  },
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    width: "100%",
    marginLeft: "4%",
    marginRight: "4%",
    marginTop: theme.spacing.unit * 3,
  }),
  popover: {
    position: "absolute",
    zIndex: "2",
    marginBottom: "2%",
  },
  brandingButton: {
    display: "inline",
    width: "33%",
    marginTop: theme.spacing.unit * 3,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  textFieldHalf: {
    marginLeft: theme.spacing.unit * 0.5,
    marginRight: theme.spacing.unit * 0.5,
    width: "48%",
  },
});

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackOpen: false,
      snackVariant: "success",
      snackMessage: "",
      colorPickerPrimary: true,
      colorPickerSecondary: true,
      colorPickerText: true,
      allowCancelations: false,
      logo: "",
      business_name: "AdvancEarly",
      primary_color: "",
      secondary_color: "",
      text_color: "#fff",
      url: "https://www.integrateyourbusiness.com",
      request_cancelation: false,
    };
  }

  changeNav = (name) => (event) => {
    let path = `/${this.props.match.params.org}/${name}`;
    this.props.history.push(path);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  showCancelation = () => {
    this.setState({
      request_cancelation: !this.state.request_cancelation,
    });
  };

  submitCancelation = (event) => {
    event.preventDefault();
    var self = this;
    axios({
      method: "DELETE",
      url: `${apiBaseUrl}/v1/org/billing/cancel`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        cancelation_reason: this.state.cancelationMessage,
      },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            snackOpen: true,
            snackVariant: "success",
            snackMessage:
              "Your plan has been canceled. It may take a few minutes for the request to be shown.",
          });
        }
      })
      .catch(function (error) {
        if (error.response.status === 409) {
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            activeStep: 0,
            snackMessage:
              "Your account may be in the process of cancelation. Please check back in a minute or two to verify.",
          });
        } else {
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            activeStep: 0,
            snackMessage: "Error canceling account. Please contact support.",
          });
        }
      });
  };

  reactivateAccount = (event) => {
    event.preventDefault();
    this.setState({
      reactivateIsDisabled: true,
    });
    var self = this;
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/v1/org/billing/reactivate`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        cancelation_reason: this.state.cancelationMessage,
      },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            reactivateIsDisabled: true,
            snackOpen: true,
            snackVariant: "success",
            snackMessage:
              "Your plan has been reactivated. It may take a few seconds to show the changes.",
          });
        }
      })
      .catch(function (error) {
        self.setState({
          reactivateIsDisabled: false,
          snackOpen: true,
          snackVariant: "error",
          activeStep: 0,
          snackMessage: "Error reactivating account. Please contact support.",
        });
      });
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  componentDidMount(event) {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/me`, //retrieves the current authenticated user
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        if (response.status === 200) {
          self.setState({
            org: response.data,
            finished: true,
          });
        } else {
          let path = `/${self.state.org_handle}/login`;
          self.props.history.push(path);
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving user profile" });
      });
  }

  render() {
    console.log(this.state);
    const { classes, ...other } = this.props;

    return (
      <NavBar2 header="Account Settings" {...other}>
        <Grid container spacing={24}>
          <Paper className={classes.paper} elevation={4}>
            <AppBar
              position="static"
              className={classes.paperAppBar}
              color="secondary"
            >
              <Toolbar>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                >
                  Current Plan
                </Typography>
              </Toolbar>
            </AppBar>
            {this.state.org &&
            this.state.org.billing &&
            this.state.org.billing.subscriptions.total_count != 0 ? (
              <table>
                <tr>
                  <td>
                    Current Plan:{" "}
                    {this.state.org.billing.subscriptions.data[0].plan.nickname}
                  </td>
                </tr>
                <tr>
                  <td>
                    Next Amount: $
                    {this.state.org.billing.subscriptions.data[0].plan.amount /
                      100}
                  </td>
                </tr>
                <tr>
                  <td>
                    Next Billing:{" "}
                    {new Date(
                      this.state.org.billing.subscriptions.data[0]
                        .current_period_end * 1000
                    ).toString()}
                  </td>
                </tr>
                <tr>
                  <td>
                    Status:{" "}
                    {this.state.org.billing.subscriptions.data[0].status}
                  </td>
                </tr>
                <tr>
                  <td>
                    Current Card:{" "}
                    {this.state.org.billing.sources.total_count != 0
                      ? this.state.org.billing.sources.data[0].brand +
                        " " +
                        this.state.org.billing.sources.data[0].last4
                      : "no card on file"}
                  </td>
                </tr>
              </table>
            ) : (
              "no current billing on your account."
            )}
          </Paper>

          <Paper className={classes.paper} elevation={4}>
            <AppBar
              position="static"
              className={classes.paperAppBar}
              color="secondary"
            >
              <Toolbar>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                >
                  Update your Card
                </Typography>
              </Toolbar>
            </AppBar>
            <StripeProvider apiKey="pk_live_MFpg2VvUxONHCsbIqLKrrnEh">
              <CardUpdate {...this.props} />
            </StripeProvider>
          </Paper>

          <Paper className={classes.paper} elevation={4}>
            <AppBar
              position="static"
              className={classes.paperAppBar}
              color="secondary"
            >
              <Toolbar>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                >
                  Other
                </Typography>
              </Toolbar>
            </AppBar>

            <Typography />
            <Grid container justify={"center"}>
              <br />
              {!this.state.request_cancelation &&
              this.state.org &&
              this.state.org.billing &&
              this.state.org.billing.subscriptions &&
              this.state.org.billing.subscriptions.data[0] &&
              this.state.org.billing.subscriptions.data[0].status ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.button}
                  onClick={(event) => this.showCancelation()}
                >
                  Request Cancelation
                </Button>
              ) : null}
              {this.state.org &&
              this.state.org.billing &&
              this.state.org.billing.subscriptions &&
              this.state.org.billing.subscriptions.data.length === 0 ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={this.state.reactivateIsDisabled}
                  className={classes.button}
                  onClick={this.reactivateAccount}
                >
                  Reactivate Subscription
                </Button>
              ) : null}
            </Grid>
            <br />

            {this.state.request_cancelation ? (
              <Grid container justify={"center"}>
                <p>
                  Please note your forms will no longer work and all services
                  associated with InstaPayments will be discontinued.
                </p>
                <form onSubmit={this.submitCancelation}>
                  <TextField
                    id="Reason"
                    label="Cancelation Reason"
                    multiline
                    required
                    fullWidth
                    rowsMax="4"
                    value={this.state.cancelationMessage}
                    onChange={this.handleChange("cancelationMessage")}
                    className={classes.textField}
                    margin="normal"
                    helperText="Please tell us why you're canceling. We're just getting started so please let us know how to improve."
                    variant="outlined"
                  />
                  <Grid container justify={"center"}>
                    <Button
                      variant="outlined"
                      style={{
                        backgroundColor: "red",
                        color: "#fff",
                        borderColor: "#fff",
                      }}
                      className={classes.button}
                      type="submit"
                    >
                      Cancel My Account
                    </Button>
                  </Grid>
                </form>
              </Grid>
            ) : null}
          </Paper>
        </Grid>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={5000}
          onClose={this.handleSnackClose}
        >
          <MySnackbarContentWrapper
            variant={this.state.snackVariant}
            message={this.state.snackMessage}
            onClose={this.handleSnackClose}
          />
        </Snackbar>
      </NavBar2>
    );
  }
}

//
/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Account);
