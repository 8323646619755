import React from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Currencies from "../../components/currencies";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import NavBar2 from "../../components/navigation/NavBar2";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Moment from "react-moment";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import StripeEvents from "./stripeEvents";
import MenuItem from "@material-ui/core/MenuItem";

import Snackbar from "@material-ui/core/Snackbar";
import Directions from "../../components/directions/Directions";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;
Moment.globalFormat = "MMM D YYYY";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    marginTop: theme.spacing.unit * 3,
  }),
  button: {
    marginTop: theme.spacing.unit * 2,
  },
  textField: {
    marginTop: theme.spacing.unit,
  },
  formControl: {
    minWidth: 120,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

class NewNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      trigger_event: "",
      templates: [],
      template: "",
      notification_emails: [],
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  createNotification = (event) => {
    event.preventDefault();
    var self = this;
    axios({
      method: "POST",
      url: `${apiBaseUrl}/v1/org/notifications/triggers/new`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        trigger_event: this.state.trigger_event,
        name: this.state.name,
        template: this.state.template,
        active: this.state.active,
        from_address: this.state.from_address,
        to_myself: this.state.to_myself,
      },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            snackOpen: true,
            snackVariant: "success",
            snackMessage: "Your notification has been created.",
          });

          let path = `/notifications/triggers`;
          self.props.history.push(path);
        }
      })
      .catch(function (error) {
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          activeStep: 0,
          snackMessage: error.response.data.message,
        });
      });
  };

  fetchTemplates = (page = 1, per_page = 50) => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/templates?page=${page}&per_page=${per_page}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            templates: response.data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({
          messageError: "Error retrieving templates",
          firstLoadAttempted: true,
        });
      });
  };

  fetchOrg = () => {
    var self = this;
    console.log("templateId", self.props.match.params.templateId);
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/me`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            notification_emails: response.data.notification_emails,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving forms" });
      });
  };

  componentDidMount(event) {
    this.fetchTemplates();
    this.fetchOrg();
  }

  render() {
    const { classes, theme, ...other } = this.props;
    const templates = [];
    const fromAddresses = [];

    for (const template of this.state.templates) {
      templates.push(
        <MenuItem value={template._id} key={template._id}>
          {template.name}
        </MenuItem>
      );
    }

    for (const emailObject of this.state.notification_emails) {
      if (emailObject.verification_status == "Success") {
        fromAddresses.push(
          <MenuItem value={emailObject.email} key={emailObject._id}>
            {emailObject.email}
          </MenuItem>
        );
      }
    }

    console.log(this.state);

    return (
      <NavBar2 header="Create a Notification Trigger" {...other}>
        <Directions message="Create a notification. This will send emails to your customers or to yourself." />

        <Paper className={classes.paper}>
          <form onSubmit={this.createNotification}>
            <div>
              <TextField
                label="Name"
                required
                type="text"
                value={this.state.name}
                helperText="Give it a name so you can easily identify the notification."
                className={classes.textField}
                onChange={this.handleChange("name")}
              />
            </div>
            <TextField
              id="outlined-select-from-address"
              select
              label="From Address"
              value={this.state.from_address}
              onChange={this.handleChange("from_address")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              helperText="Select where you want the notification to come from."
              margin="normal"
              variant="outlined"
              style={{
                paddingBottom: 15,
              }}
            >
              {fromAddresses}
            </TextField>
            <div>
              <TextField
                id="trigger_event"
                select
                required
                label="Trigger Event"
                value={this.state.trigger_event}
                onChange={this.handleChange("trigger_event")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                helperText="What Stripe Event should trigger this email to send?"
                margin="normal"
                variant="outlined"
                style={{
                  paddingBottom: 15,
                }}
              >
                <MenuItem
                  value="customer.subscription.created"
                  key="customer.subscription.created"
                >
                  Subscription Created{" "}
                </MenuItem>
                <MenuItem
                  value="customer.subscription.trial_will_end"
                  key="customer.subscription.trial_will_end"
                >
                  Trial Ends in Three Days
                </MenuItem>

                <MenuItem
                  value="invoice.payment_succeeded"
                  key="invoice.payment_succeeded"
                >
                  Subscription Successfully Renewed
                </MenuItem>
                <MenuItem value="invoice.upcoming" key="invoice.upcoming">
                  Subscription will renew in X Days
                </MenuItem>

                <MenuItem value="charge.succeeded" key="charge.succeeded">
                  One Time Charge Succeeded
                </MenuItem>

                <MenuItem
                  value="invoice.payment_failed"
                  key="invoice.payment_failed"
                >
                  Invoice Payment Failed{" "}
                </MenuItem>
                <MenuItem
                  value="customer.subscription.deleted"
                  key="customer.subscription.deleted"
                >
                  Subscription Canceled
                </MenuItem>
              </TextField>
            </div>
            <div>
              <TextField
                id="template"
                select
                required
                label="Template"
                value={this.state.template}
                onChange={this.handleChange("template")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                helperText="What template should the email use?"
                margin="normal"
                variant="outlined"
                style={{
                  paddingBottom: 15,
                }}
              >
                {templates}
              </TextField>
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.to_myself}
                    onChange={() =>
                      this.setState({ to_myself: !this.state.to_myself })
                    }
                    value=""
                    color="primary"
                  />
                }
                label="Only notify myself when this event happens?"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.active}
                    onChange={() =>
                      this.setState({ active: !this.state.active })
                    }
                    value=""
                    color="primary"
                  />
                }
                label="Set this notification to active. (This tells our system to send emails.)"
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              className={classes.button}
              type="submit"
            >
              Create Notification
            </Button>
          </form>
        </Paper>
        {this.state.template ? (
          <Paper className={classes.paper}>
            <iframe
              key={this.state.template}
              sandbox=""
              height="500"
              width="100%"
              frameBorder="none"
              src={`${apiBaseUrl}/v1/org/templates/email/${this.state.template}/preview`}
            />
          </Paper>
        ) : null}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
        >
          <MySnackbarContentWrapper
            variant={this.state.snackVariant}
            message={this.state.snackMessage}
            onClose={this.handleSnackClose}
          />
        </Snackbar>
      </NavBar2>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(NewNotification);
