import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import NavBar2 from "../../components/navigation/NavBar2";

import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";

import Paper from "@material-ui/core/Paper";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Input from "@material-ui/core/Input";
import { styles } from "./CustomersPageStyles.js";

import SearchIcon from "@material-ui/icons/Search";
import Directions from "../../components/directions/Directions";

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const Customer = (props) => {
  const { classes, ...other } = props;
  const customers = [];
  const { enqueueSnackbar } = useSnackbar();
  let [state, setState] = useState({
    page: 1,
    rowsPerPage: 10,
    customers: [],
    searchValue: "",
    firstLoadAttempted: false,
  });

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const nextPage = (event) => {
    loadCustomers(Number(state.page) + 1, Number(state.rowsPerPage));
    setState({
      ...state,
      page: state.page + 1,
    });
  };

  const previousPage = (event) => {
    loadCustomers(Number(state.page) - 1, Number(state.rowsPerPage));
    setState({
      ...state,
      page: state.page - 1,
    });
  };

  useEffect(() => {
    loadCustomers(Number(state.page), Number(state.rowsPerPage));
  }, []);

  const loadCustomers = (page, per_page) => {
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/customers?page=${page}&per_page=${per_page}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          setState({
            ...state,
            customers: response.data,
            finished: true,
            firstLoadAttempted: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({
          ...state,
          messageError: "Error retrieving customers",
          firstLoadAttempted: true,
        });
      });
  };

  const searchCustomers = () => {
    if (!state.searchValue) {
      loadCustomers(Number(state.page), Number(state.rowsPerPage));
    } else {
      axios({
        method: "POST",
        url: `${apiBaseUrl}/v1/org/customers/search`,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        data: {
          searchValue: state.searchValue,
        },
      })
        .then(function (response) {
          console.log(response.data);
          if (response.status === 200) {
            if (response.data.length > 0) {
              setState({
                ...state,
                customers: response.data,
                finished: true,
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          setState({ ...state, messageError: "Error retrieving customers" });
        });
    }
  };

  const handleCopyUpdateUrl = async (url) => {
    await navigator.clipboard.writeText(url);
    enqueueSnackbar("Permanent Update URL copied to clipboard", {
      variant: "success",
      autoHideDuration: 3000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  for (var i = 0; i < state.customers.length; i++) {
    customers.push();
  }

  return (
    <NavBar2 header="Customers" {...props}>
      <Directions message="Your customers stored in Stripe are listed here. Each customer has a unique card update URL that does not change." />
      {state.firstLoadAttempted ? (
        <div>
          <div className={classes.toolbar} />
          {state.customers.length > 0 ? (
            <Paper>
              <div>
                <AppBar position="static">
                  <Toolbar>
                    <div className={classes.grow} />
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                          searchCustomers();
                        }}
                      >
                        <Input
                          placeholder="Search..."
                          disableUnderline
                          onChange={handleChange("searchValue")}
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                        />
                      </form>
                    </div>
                  </Toolbar>
                </AppBar>
              </div>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Stripe Url</TableCell>
                    <TableCell>Delinquent</TableCell>
                    <TableCell>Card on File</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Update Url</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.customers.map((customer) => (
                    <TableRow key={customer.id}>
                      <TableCell component="th" scope="row">
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          target="_blank"
                          href={`https://dashboard.stripe.com/customers/${customer.id}`}
                        >
                          View
                        </Button>
                      </TableCell>
                      <TableCell>{String(customer.delinquent)}</TableCell>
                      <TableCell>
                        {customer.default_source ? "true" : "false"}
                      </TableCell>
                      <TableCell>{customer.email}</TableCell>
                      <TableCell>
                        {moment(new Date(customer.created)).format(
                          "MM/DD/YYYY"
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          target="_blank"
                          onClick={() =>
                            handleCopyUpdateUrl(
                              `https://forms.${process.env.REACT_APP_BASE_DOMAIN}/update/${customer.org}/${customer.update_hash}`
                            )
                          }
                        >
                          Update Page
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>Page {state.page}</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={(event) => previousPage(event)}
                        className={classes.paginationButton}
                        disabled={state.page <= 1}
                      >
                        Previous
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={(event) => nextPage(event)}
                        className={classes.paginationButton}
                        disabled={state.customers.length < 10}
                      >
                        Next
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
          ) : (
            <div>
              <Grid container justify={"center"}>
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image="/static/images/cards/contemplative-reptile.jpg"
                      title="Contemplative Reptile"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="headline"
                        component="h2"
                      >
                        You don't have a customer yet!
                      </Typography>
                      <Typography component="p">
                        Customers are pulled from Stripe. You can easily signup
                        customers by creating a form in the forms tab, or
                        creating your own signup process with Stripes API.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      href="/forms/new"
                    >
                      Create a Form
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </div>
          )}
        </div>
      ) : (
        <Grid container justify={"center"}>
          <div className={classes.loader}>
            <CircularProgress size={100} className={classes.progress} />
          </div>
        </Grid>
      )}
    </NavBar2>
  );
};

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Customer);
