import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavBar2 from "../../components/navigation/NavBar2";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Moment from "react-moment";
import SyntaxHighlighter from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/styles/prism";
import queryString from "qs";
import { useSnackbar } from "notistack";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
import stripeButton from "../../images/stripe-button.png";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;
Moment.globalFormat = "MMM D YYYY";

const styles = (theme) => ({
  flex: {
    flex: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
  },
  paperAppBar: {
    marginTop: "-1%",
    marginBottom: "1%",
  },
  toolbar: theme.mixins.toolbar,
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
  },
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    width: "100%",
    marginLeft: "4%",
    marginRight: "4%",
    marginTop: theme.spacing.unit * 3,
  }),
  stripe: {
    padding: "0px",
    borderRadius: "10px",
    height: "10px",
  },
});

const Integrations = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  let [state, setState] = useState({
    account_id: "",
    secret_key: "",
    username: "",
  });
  const { classes, ...other } = props;

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  useEffect(() => {
    const query = queryString.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    console.log(query);
    if (query.code && props.match.params.integration === "hubspot") {
      console.log("hubspot", query.code);
      updateHubspot(query.code);
    } else if (query.code) {
      updateOrg(query.code);
    } else {
      fetchUser();
    }
  }, []);

  const fetchUser = () => {
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/me`, //retrieves the current authenticated user
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        if (response.status === 200) {
          setState({
            ...state,
            org: response.data,
            hubspot_credentials: response.data.hubspot_credentials,
            stripe_account:
              response.data.stripe_credentials &&
              response.data.stripe_credentials.stripe_user_id,
            finished: true,
          });
        } else {
          let path = `/${state.org_handle}/login`;
          props.history.push(path);
        }
      })
      .catch(function (error) {
        console.log(error);

        enqueueSnackbar("Error fetching user", { variant: "error" });
      });
  };

  const fetchKeys = () => {
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/keys`, //retrieves the current authenticated user
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        if (response.status === 200) {
          setState({
            ...state,
            secret_key: response.data.secret_key,
            username: response.data.username,
          });
        } else {
          let path = `/${state.org_handle}/login`;
          props.history.push(path);
        }
      })
      .catch(function (error) {
        console.log(error);

        enqueueSnackbar("Error retrieving keys", { variant: "error" });
      });
  };

  const updateOrg = (code) => {
    setState({
      ...state,
      stripeUpdateFinished: false,
    });
    axios({
      method: "POST",
      url: `${apiBaseUrl}/v1/org/settings/stripe/update`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        code: code,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          setState({
            ...state,
            stripeUpdateFinished: true,
          });
          fetchUser();
        }
      })
      .catch(function (error) {
        console.log(error);
        fetchUser();

        enqueueSnackbar(
          error?.response?.data?.message || "Error updating stripe account",
          { variant: "error" }
        );
      });
  };

  const updateHubspot = (code) => {
    setState({
      ...state,
      stripeUpdateFinished: false,
    });
    axios({
      method: "POST",
      url: `${apiBaseUrl}/v1/org/settings/hubspot/update`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        code: code,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          setState({
            ...state,
            hubspotUpdateFinished: true,
          });
          fetchUser();
        }
      })
      .catch(function (error) {
        console.log(error);
        fetchUser();

        enqueueSnackbar("Error connecting to hubspot.", { variant: "error" });
      });
  };

  return (
    <NavBar2 header="Stripe Connection" {...other}>
      <Grid container spacing={24}>
        <Paper className={classes.paper} elevation={4}>
          <AppBar
            position="static"
            className={classes.paperAppBar}
            color="primary"
          >
            <Toolbar variant="regular">
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Stripe - {state.stripe_account}
              </Typography>
              <Typography
                variant="headliner"
                color="inherit"
                style={
                  state.stripe_account ? { color: "#74d874" } : { color: "red" }
                }
              >
                {state.stripe_account ? "Connected" : "Disconnected"}
              </Typography>
            </Toolbar>
          </AppBar>
          {state.stripe_account ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={state.stripe_account ? true : false}
              href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_D4e3hEnCPTZHmp0eKqBqCcTZkjaQJgrs&scope=read_write`}
            >
              Connected
            </Button>
          ) : (
            <a href="https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_D4e3hEnCPTZHmp0eKqBqCcTZkjaQJgrs&scope=read_write">
              <img src={stripeButton} alt="stripe-button" />
            </a>
          )}
        </Paper>

        <Paper className={classes.paper} elevation={4}>
          <AppBar
            position="static"
            className={classes.paperAppBar}
            color="primary"
          >
            <Toolbar variant="regular">
              <Typography variant="h6" color="inherit" className={classes.flex}>
                InstaPayments API Keys - Basic Auth
              </Typography>
            </Toolbar>
          </AppBar>
          <SyntaxHighlighter language="text" style={prism}>
            {"Username: " + state.username}
          </SyntaxHighlighter>
          <SyntaxHighlighter language="text" style={prism}>
            {"Password: " + state.secret_key}
          </SyntaxHighlighter>

          <Button
            variant="outlined"
            color="primary"
            onClick={fetchKeys}
            className={classes.button}
          >
            Show Keys
          </Button>
        </Paper>

        <Paper className={classes.paper} elevation={4}>
          <AppBar
            position="static"
            className={classes.paperAppBar}
            color="primary"
          >
            <Toolbar variant="regular">
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Hubspot
              </Typography>
            </Toolbar>
          </AppBar>
          {state.hubspot_credentials &&
          state.hubspot_credentials.is_connected ? (
            <Button
              variant="outlined"
              color="primary"
              disabled
              className={classes.button}
            >
              Hubspot is Connected
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              href={`https://app.hubspot.com/oauth/authorize?client_id=302b1bb6-b453-48f8-af2e-8a03630be915&redirect_uri=https://orgs.instapayments.io/settings/integrations/hubspot&scope=contacts%20timeline%20oauth%20tickets`}
              className={classes.button}
            >
              Connect to Hubspot
            </Button>
          )}
        </Paper>
      </Grid>
    </NavBar2>
  );
};

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Integrations);
