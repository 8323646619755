import React from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import NavBar2 from "../../components/navigation/NavBar2";
import Paper from "@material-ui/core/Paper";
import Moment from "react-moment";
import Checkbox from "@material-ui/core/Checkbox";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;
Moment.globalFormat = "MMM D YYYY";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar,
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    width: "80%",
    marginLeft: "8%",
    marginRight: "8%",
    marginTop: theme.spacing.unit * 3,
  }),
  button: {
    marginTop: theme.spacing.unit * 2,
  },
  textField: {
    marginTop: theme.spacing.unit,
  },
  formControl: {
    marginTop: theme.spacing.unit,
    minWidth: 120,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

class NewProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      amount: 0,
      redirect_url: "",
      currency: "USD",
      instructionType: "oneTime",
      allowPassThrough: false,
      products: [],
      selectedPlans: [],
    };
  }

  changeNav = (name) => (event) => {
    let path = `/${this.props.match.params.org}/${name}`;
    this.props.history.push(path);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  createProduct = (event) => {
    event.preventDefault();
    var self = this;

    axios({
      method: "POST",
      url: `${apiBaseUrl}/v1/org/products/new`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        name: this.state.name,
        statement_descriptor: this.state.statement_descriptor,
        unit_label: this.state.unit_label,
      },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            snackOpen: true,
            snackVariant: "success",
            snackMessage: "Your product has been created.",
          });
          let path = `/products/${response.data.id}`;
          self.props.history.push(path);
        }
      })
      .catch(function (error) {
        console.log(error.response);
        if (error.response && error.response.data) {
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: error.response.data.message,
          });
        } else {
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: "Error creating product.",
          });
        }
      });
  };

  render() {
    const { classes, theme, ...other } = this.props;
    console.log(this.state);

    const productArray = [];

    for (let product of this.state.products) {
      let plans = [];
      productArray.push(
        <MenuItem disabled key={product.id} value={product.name}>
          <ListItemText primary={product.name} />
        </MenuItem>
      );
      for (let plan of product.plans) {
        productArray.push(
          <MenuItem key={plan.id} value={plan.id}>
            <Checkbox
              checked={this.state.selectedPlans.indexOf(plan.id) > -1}
            />
            <ListItemText primary={plan.nickname || plan.id} />
          </MenuItem>
        );
      }
    }

    return (
      <NavBar2 header="New Product" {...other}>
        <Paper className={classes.paper}>
          <form onSubmit={this.createProduct}>
            <TextField
              label="Name"
              fullWidth
              required
              type="text"
              helperText="The name your customers will see on things like invoices, reciepts, forms etc.."
              className={classes.textField}
              onChange={this.handleChange("name")}
            />
            <TextField
              label="Statement Descriptor (optional)"
              fullWidth
              type="text"
              helperText="This will be what appears on your customers bank statement."
              className={classes.textField}
              onChange={this.handleChange("statement_descriptor")}
            />
            <TextField
              label="Unit Label (optional)"
              fullWidth
              helperText="This will represent a unit of this product, such as a seat or API call, on Checkout, customers' receipts, and invoices."
              type="text"
              className={classes.textField}
              onChange={this.handleChange("unit_label")}
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              className={classes.button}
              type="submit"
            >
              Create Product
            </Button>
          </form>
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
        >
          <MySnackbarContentWrapper
            variant={this.state.snackVariant}
            message={this.state.snackMessage}
            onClose={this.handleSnackClose}
          />
        </Snackbar>
      </NavBar2>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(NewProduct);
