import React from "react";
import { useNavigate, useParams } from "react-router-dom";

/* This component fills in some props for class components that need router hooks */

const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  const navigate = useNavigate();
  // etc... other react-router-dom v6 hooks

  return (
    <WrappedComponent
      {...props}
      params={params}
      match={{ params: { ...params } }}
      location={window.location}
      history={{ push: navigate }} // make history really call navigate for react router 6
      navigate={navigate}
      // etc...
    />
  );
};

export default withRouter;
