import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import Login from "./views/login/LoginPage.js";
import { SnackbarProvider } from "notistack";
import routes from "./routes";
import NotFound from "./views/notfound/NotFound";
import "./App.css";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

function loggedIn() {
  let token = localStorage.getItem("token");
  let timestamp = localStorage.getItem("timestamp");
  let potentialExp = timestamp / 1000 + 7200; // 2 hours after token was created in seconds
  /*Checks if there's a token in local storage and if the timestamp is older than 2 hours.
    These are simply quick front end checks. All auth is really done server side.
  */
  if (
    token != null &&
    timestamp != null &&
    potentialExp > new Date().getTime() / 1000
  ) {
    return true;
  } else {
    return false;
  }
}
const theme = createMuiTheme({
  palette: {
    primary: {
      light: process.env.REACT_APP_PRIMARY_COLOR,
      main: process.env.REACT_APP_PRIMARY_COLOR,
      dark: process.env.REACT_APP_PRIMARY_COLOR,
      contrastText: process.env.REACT_APP_CONTRAST_TEXT,
    },
    secondary: {
      light: process.env.REACT_APP_SECONDARY_COLOR,
      main: process.env.REACT_APP_SECONDARY_COLOR,
      dark: process.env.REACT_APP_SECONDARY_COLOR,
      contrastText: process.env.REACT_APP_CONTRAST_TEXT,
    },
  },
});

const RequireAuth = () => {
  let location = useLocation();

  if (!loggedIn()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
};

const myProtectedRoutes = () => {
  let filteredRoutes = routes.filter(({ requiresAuth }) => requiresAuth);
  return filteredRoutes.map(({ path, Component }) => (
    <Route path={path} element={<Component />} />
  ));
};
const myPublicRoutes = () => {
  let filteredRoutes = routes.filter(({ requiresAuth }) => !requiresAuth);
  return filteredRoutes.map(({ path, Component }) => (
    <Route path={path} element={<Component />} />
  ));
};
// loggedIn() && requiresAuth ? (
//   <Route path={path} element={<Component />} key={path} />
// ) : !requiresAuth ? (
//   <Route path={path} index={index} element={<Component />} key={path} />
// ) : (
//   <Route path={"/login"} element={<LoginNoOrg />} key={"loginnoorg"} />
// )
const PrimaryLayout = () => (
  <Router>
    <Routes>
      <Route element={<RequireAuth />}>{myProtectedRoutes()}</Route>
      {myPublicRoutes()}
      <Route exact path="*" element={<NotFound />} />
    </Routes>
  </Router>
);

const App = () => (
  <MuiThemeProvider theme={theme}>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <PrimaryLayout />
    </SnackbarProvider>
  </MuiThemeProvider>
);

export default App;
