import React from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Currencies from "../../components/currencies";
import { withStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import NavBar2 from "../../components/navigation/NavBar2";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import getSymbolFromCurrency from "currency-symbol-map";
import ordinal from "ordinal";
import Moment from "react-moment";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
import Directions from "../../components/directions/Directions";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;
Moment.globalFormat = "MMM D YYYY";

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0 // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar,
  paper: theme.mixins.gutters({
    paddingTop: 50,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3
  }),
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    marginTop: theme.spacing.unit,
    minWidth: 120
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
  paperAppBar: {
    marginTop: "-1%",
    marginBottom: "1%"
  }
});

const MenuProps = {
  PaperProps: {
    style: {
      //  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      //  width: 250,
    }
  }
};

class UpdateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      enableDiscount: false,
      selectedProducts: [],
      availablePlans: [],
      selectedPlans: [],
      custom_fields: [],
      skus: [],
      selectedSkus: [],
      products: [],
      activeStep: 0
    };
  }

  changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`;
    this.props.history.push(path);
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  handleChangeProduct = event => {
    console.log(event.target.value); // an array of ids
    const productArray = [];
    const productArrayIds = [];
    for (let productId of event.target.value) {
      productArray.push(
        this.state.availableProducts.filter(
          product => product.id === productId
        )[0]
      );
      productArrayIds.push(
        this.state.availableProducts.filter(
          product => product.id === productId
        )[0].id
      );
    }
    console.log("productArray", productArray);

    const planArray = [];
    for (let fullProduct of productArray) {
      console.log("Plans", fullProduct.plans);
      Array.prototype.push.apply(planArray, fullProduct.plans);
    }
    console.log("planArray", planArray);

    this.setState({
      selectedProducts: productArrayIds,
      availablePlans: planArray
    });
  };

  handleChange3 = event => {
    this.setState({
      selectedPlans: event.target.value
    });
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  fetchProducts = () => {
    var self = this;
    console.log(this.state);
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/products`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        console.log(response.data);
        if (response.status === 200) {
          const filteredProducts = response.data.filter(
            product => product.plans && product.plans.length > 0
          );
          const filteredSkus = response.data.filter(
            product => product.skus && product.skus.length > 0
          );

          self.setState({
            availableProducts: filteredProducts,
            availableSkus: filteredSkus,
            skus: filteredSkus,
            products: filteredProducts
          });
        }
      })
      .catch(function(error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving forms" });
      });
  };

  fetchForm = () => {
    var self = this;
    console.log(this.state);
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/forms/${self.props.match.params.formId}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        console.log(response.data);
        if (response.status === 200) {
          const planIds = [];
          const skuIds = [];
          const prodIds = [];
          if (response.data.type !== "onetime") {
            for (let lineItem of response.data.line_items) {
              planIds.push(lineItem.selected_plan.id);
              prodIds.push(lineItem.id);
            }
          } else {
            for (let lineItem of response.data.line_items) {
              skuIds.push(lineItem.selected_sku.id);
              prodIds.push(lineItem.id);
            }
          }

          console.log("custom fields", response.data.custom_fields);
          self.setState({
            selectedPlans: planIds,
            selectedSkus: skuIds,
            selectedProducts: prodIds,
            enableDiscount: response.data.coupon_enabled,
            custom_fields: response.data.custom_fields
              ? response.data.custom_fields
              : [],
            type: response.data.type,
            name: response.data.name,
            hide_address: response.data.hide_address,
            currency: response.data.currency,
            enableAnchorDate:
              response.data.billing_anchor_date &&
              response.data.billing_anchor_date.active
                ? response.data.billing_anchor_date.active
                : false,
            prorated:
              response.data.billing_anchor_date &&
              response.data.billing_anchor_date.prorated
                ? response.data.billing_anchor_date.prorated
                : false,
            date_number:
              response.data.billing_anchor_date &&
              response.data.billing_anchor_date.date_number
                ? response.data.billing_anchor_date.date_number
                : 1,
            enableTerms:
              response.data.terms && response.data.terms.active
                ? response.data.terms.active
                : false,
            termsLink:
              response.data.terms && response.data.terms.link
                ? response.data.terms.link
                : 0,
            redirect_url: response.data.redirect_url,
            enableTaxes:
              response.data.taxes && response.data.taxes.active
                ? response.data.taxes.active
                : false,
            custom_logo:
              response.data.custom_logo && response.data.custom_logo.active
                ? response.data.custom_logo.active
                : false,
            custom_logo_url:
              response.data.custom_logo && response.data.custom_logo.active
                ? response.data.custom_logo.custom_logo_url
                : "",
            tax_percentage:
              response.data.taxes && response.data.taxes.percent
                ? response.data.taxes.percent
                : 0,
            enableSetupFee:
              response.data.setup_fee && response.data.setup_fee.active
                ? response.data.setup_fee.active
                : false,
            setup_fee:
              response.data.setup_fee && response.data.setup_fee.amount_in_cents
                ? response.data.setup_fee.amount_in_cents / 100
                : 0,
            enableInstallment:
              response.data.installment_plan &&
              response.data.installment_plan.active
                ? response.data.installment_plan.active
                : false,
            installment_total:
              response.data.installment_plan &&
              response.data.installment_plan.amount_in_cents
                ? response.data.installment_plan.amount_in_cents / 100
                : 0,
            defaultsRecurring:
              response.data.donation_defaults &&
              response.data.donation_defaults.recurring
                ? response.data.donation_defaults.recurring.join()
                : undefined,
            defaultsOnetime:
              response.data.donation_defaults &&
              response.data.donation_defaults.onetime
                ? response.data.donation_defaults.onetime.join()
                : undefined,
            custom_labels: response.data.custom_labels ? true : false,
            custom_label_first_name:
              (response.data.custom_labels &&
                response.data.custom_labels.first_name) ||
              null,
            custom_label_last_name:
              (response.data.custom_labels &&
                response.data.custom_labels.last_name) ||
              null,
            custom_label_email:
              (response.data.custom_labels &&
                response.data.custom_labels.email) ||
              null,
            custom_label_donation_tab_1:
              (response.data.custom_labels &&
                response.data.custom_labels.donation_tab_1) ||
              null,
            custom_label_donation_tab_2:
              (response.data.custom_labels &&
                response.data.custom_labels.donation_tab_2) ||
              null,
            custom_label_donation_helper_text_1:
              (response.data.custom_labels &&
                response.data.custom_labels.donation_helper_text_1) ||
              null,
            custom_label_donation_helper_text_2:
              (response.data.custom_labels &&
                response.data.custom_labels.donation_helper_text_2) ||
              null,
            custom_label_checkout_button:
              (response.data.custom_labels &&
                response.data.custom_labels.checkout_button) ||
              null
          });
          console.log(self.state);
        }
      })
      .catch(function(error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving forms" });
      });
  };

  updateForm = event => {
    event.preventDefault();
    console.log(
      "The form was submitted.",
      this.state.selectedPlans,
      this.state.name,
      this.state.discount,
      this.state.enableDiscount
    );
    var self = this;
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/v1/org/forms/${self.props.match.params.formId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json"
      },
      data: {
        plan_ids: this.state.selectedPlans,
        skus: this.state.selectedSkus,
        name: this.state.name,
        coupon: this.state.discount,
        coupon_enabled: this.state.enableDiscount,
        currency: this.state.currency,
        redirect_url: this.state.redirect_url,
        custom_fields: this.state.custom_fields,
        hide_address: this.state.hide_address,
        type: this.state.type,
        taxes: {
          active: this.state.enableTaxes,
          percent: this.state.tax_percentage
        },
        donation_defaults: {
          onetime:
            this.state.defaultsOnetime &&
            this.state.defaultsOnetime.split(",").length > 0
              ? this.state.defaultsOnetime.split(",")
              : [25, 50, 100, 250, 500, 1000],
          recurring:
            this.state.defaultsRecurring &&
            this.state.defaultsRecurring.split(",").length > 0
              ? this.state.defaultsRecurring.split(",")
              : [25, 50, 100, 250, 500, 1000]
        },
        setup_fee: {
          active: this.state.enableSetupFee,
          amount_in_cents: this.state.setup_fee * 100
        },
        custom_logo: {
          active: this.state.custom_logo,
          custom_logo_url: this.state.custom_logo_url
        },
        billing_anchor_date: {
          active: this.state.enableAnchorDate,
          date_number: this.state.date_number,
          prorated: this.state.prorated
        },
        installment_plan: {
          active: this.state.enableInstallment,
          amount_in_cents: this.state.installment_total * 100
        },
        terms: {
          active: this.state.enableTerms,
          link: this.state.termsLink
        },

        custom_label_first_name: this.state.custom_label_first_name,
        custom_label_last_name: this.state.custom_label_last_name,
        custom_label_email: this.state.custom_label_email,
        custom_label_donation_tab_1: this.state.custom_label_donation_tab_1,
        custom_label_donation_tab_2: this.state.custom_label_donation_tab_2,
        custom_label_donation_helper_text_1: this.state
          .custom_label_donation_helper_text_1,
        custom_label_donation_helper_text_2: this.state
          .custom_label_donation_helper_text_2,
        custom_label_checkout_button: this.state.custom_label_checkout_button
      }
    })
      .then(function(response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            snackOpen: true,
            snackVariant: "success",
            snackMessage: "Your plan has been updated."
          });

          let path = `/forms`;
          self.props.history.push(path);
        }
      })
      .catch(function(error) {
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          activeStep: 0,
          snackMessage: error.response.data.message
        });
      });
  };
  handleCustomFieldTypeChange = index => event => {
    let unmutatedArray = new Array().concat(this.state.custom_fields);
    unmutatedArray[index].field_type = event.target.value;

    this.setState({
      custom_fields: unmutatedArray
    });
  };

  handleCustomFieldChange = index => event => {
    let unmutatedArray = new Array().concat(this.state.custom_fields);
    unmutatedArray[index].name = event.target.value;

    this.setState({
      custom_fields: unmutatedArray
    });
  };

  handleCustomFieldValuesChange = index => event => {
    let unmutatedArray = new Array().concat(this.state.custom_fields);
    unmutatedArray[index].values = event.target.value.split(",");

    this.setState({
      custom_fields: unmutatedArray
    });
  };

  handleCustomFieldCheckChange = index => event => {
    let unmutatedArray = new Array().concat(this.state.custom_fields);
    unmutatedArray[index].required = !this.state.custom_fields[index].required;

    this.setState({
      custom_fields: unmutatedArray
    });
  };

  addField = index => event => {
    console.log("addField was called");
    let custom_fields = this.state.custom_fields;
    custom_fields.push({
      name: "new field"
    });
    this.setState({
      custom_fields: custom_fields
    });
  };

  removeField = index => event => {
    console.log(this.state.custom_fields);
    let custom_fields = new Array().concat(this.state.custom_fields);
    let edited_custom_fields = custom_fields.splice(index, 1);
    this.setState({
      custom_fields: custom_fields
    });
  };

  componentDidMount(event) {
    console.log(this.state);
    this.fetchProducts();
    this.fetchForm();
  }

  render() {
    const { classes, theme, ...other } = this.props;
    const steps = [
      "The Form",
      `${this.state.type === "onetime" ? "Sku(s)" : "Plan(s)"}`,
      "Additional settings",
      "Custom Fields",
      "Update the Form"
    ];
    const { activeStep } = this.state;
    console.log(this.state);
    const customFieldsArray = [];
    for (let [index, custom_field] of this.state.custom_fields.entries()) {
      customFieldsArray.push(
        <div id={"custom_field_" + index} key={index}>
        <TextField
          label="Field Name"
          type="text"
          required
          value={custom_field.name}
          className={classes.textFieldHalf}
          helperText="Custom field name displayed on the forms"
          onChange={this.handleCustomFieldChange(index)}
        />
        <TextField
          select
          label="Field Type"
          fullWidth
          value={custom_field.field_type}
          onChange={this.handleCustomFieldTypeChange(index)}
        >
          <MenuItem key={"text"} value="text">
            Text
          </MenuItem>
          <MenuItem key={"dropdown"} value="dropdown">
            Dropdown
          </MenuItem>
        </TextField>
        {custom_field.field_type === "dropdown" ? (
          <TextField
            label="Dropdown Values"
            type="text"
            required
            value={custom_field.values}
            placeholder="Small,Medium,Large"
            className={classes.textFieldHalf}
            helperText="Comma separated list of values. i.e. small,medium,large"
            onChange={this.handleCustomFieldValuesChange(index)}
          />
        ) : null}
        <FormControlLabel
          control={
            <Checkbox
              checked={custom_field.required}
              onChange={this.handleCustomFieldCheckChange(index)}
              color="primary"
            />
          }
          label="Required?"
        />
        <Button
          color="primary"
          disabled={this.state.custom_fields.length === 0}
          onClick={this.removeField(index)}
          className={classes.button}
        >
          Remove Field
        </Button>
      </div>
      );
    }

    const productArray = [];

    for (let product of this.state.products) {
      let plans = [];
      productArray.push(
        <MenuItem disabled key={product.id} value={product.name}>
          <ListItemText primary={product.name} />
        </MenuItem>
      );
      for (let plan of product.plans) {
        productArray.push(
          <MenuItem key={plan.id} value={plan.id}>
            <Checkbox
              checked={this.state.selectedPlans.indexOf(plan.id) > -1}
            />
            <ListItemText primary={plan.nickname || plan.id} />
          </MenuItem>
        );
      }
    }

    const skuArray = [];

    for (let product of this.state.skus) {
      if (product.skus) {
        skuArray.push(
          <MenuItem disabled key={product.id} value={product.name}>
            <ListItemText primary={product.name} />
          </MenuItem>
        );
        for (let sku of product.skus) {
          skuArray.push(
            <MenuItem key={sku.id} value={sku.id}>
              <Checkbox
                checked={this.state.selectedSkus.indexOf(sku.id) > -1}
              />
              <ListItemText primary={sku.attributes.name || sku.id} />
            </MenuItem>
          );
        }
      }
    }

    const potentialDays = [];
    for (var i = 1; i < 32; i++) {
      potentialDays.push(<option value={i}>{ordinal(i)}</option>);
    }
    potentialDays.push(<option value={32}>{"End of Month"}</option>);

    return (
      <NavBar2 header="Update Form" {...other}>
        <Directions message="You are updating a form. Be sure to refresh the form to see the changes." />
        <Paper className={classes.root}>
          <form onSubmit={this.updateForm}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      {this.state.activeStep === 0 ? (
                        <div>
                          <TextField
                            label="Form Name"
                            type="text"
                            required
                            value={this.state.name}
                            className={classes.textFieldHalf}
                            helperText="This is for tracking the form internally."
                            onChange={this.handleChange("name")}
                          />
                        </div>
                      ) : null}

                      {this.state.activeStep === 0 ? (
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="type">Type</InputLabel>
                          <Select
                            native
                            value={this.state.type}
                            onChange={this.handleChange("type")}
                            inputProps={{
                              name: "type",
                              id: "type"
                            }}
                          >
                            <option value="signup">
                              Standard Subscription Signup
                            </option>
                            <option value="multiprod">
                              Pick Between Plans i.e. (monthly, semi-annual,
                              annual)
                            </option>
                            <option value="signup-quantity">
                              Subscription Signup with Quantity Selection
                            </option>
                            {/*<option value="onetime">One Time Charge</option>*/}
                            <option value="donation">Donation</option>
                          </Select>
                          <FormHelperText>
                            The type of form you want.
                          </FormHelperText>
                        </FormControl>
                      ) : null}

                      {this.state.activeStep === 0 &&
                      this.state.type === "donation" ? (
                        <div>
                          <div>
                            <TextField
                              label="Recurring Donation Defaults"
                              type="text"
                              value={this.state.defaultsRecurring}
                              className={classes.textFieldHalf}
                              helperText="Please put a comma seperated list of numbers. i.e. 50,100,250,500"
                              onChange={this.handleChange("defaultsRecurring")}
                            />
                          </div>
                          <div>
                            <TextField
                              label="OneTime Donation Defaults"
                              type="text"
                              value={this.state.defaultsOnetime}
                              className={classes.textFieldHalf}
                              helperText="Please put a comma seperated list of numbers. i.e. 50,100,250,500"
                              onChange={this.handleChange("defaultsOnetime")}
                            />
                          </div>
                        </div>
                      ) : null}

                      {this.state.activeStep === 0 ? (
                        <div>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="currency">Currency</InputLabel>
                            <Select
                              native
                              value={this.state.currency}
                              onChange={this.handleChange("currency")}
                              inputProps={{
                                name: "currency",
                                id: "currency"
                              }}
                            >
                              <Currencies />
                            </Select>
                            <FormHelperText>
                              The currency you want shown on the form.
                            </FormHelperText>
                          </FormControl>
                        </div>
                      ) : null}

                      {this.state.activeStep === 1 &&
                      this.state.type === "onetime" ? (
                        <div>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="select-multiple-checkbox">
                              Pick a Sku(s)
                            </InputLabel>
                            <Select
                              multiple
                              value={this.state.selectedSkus}
                              onChange={this.handleChange("selectedSkus")}
                              renderValue={selected => (
                                <div className={classes.chips}>
                                  {selected.map(value => (
                                    <Chip
                                      key={value}
                                      label={value}
                                      className={classes.chip}
                                    />
                                  ))}
                                </div>
                              )}
                              MenuProps={{
                                style: {
                                  maxHeight: 500
                                  //width: 200,
                                }
                              }}
                            >
                              {skuArray}
                            </Select>
                          </FormControl>
                          <FormHelperText>
                            Select the Stripe sku(s) you want customers
                            subscribed to.
                          </FormHelperText>
                        </div>
                      ) : null}

                      {this.state.activeStep === 1 &&
                      this.state.type !== "onetime" ? (
                        <div>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="select-multiple-checkbox">
                              Pick a Plan(s)
                            </InputLabel>
                            <Select
                              multiple
                              value={this.state.selectedPlans}
                              onChange={this.handleChange("selectedPlans")}
                              renderValue={selected => (
                                <div className={classes.chips}>
                                  {selected.map(value => (
                                    <Chip
                                      key={value}
                                      label={value}
                                      className={classes.chip}
                                    />
                                  ))}
                                </div>
                              )}
                              MenuProps={{
                                style: {
                                  maxHeight: 500
                                  //width: 200,
                                }
                              }}
                            >
                              {productArray}
                            </Select>
                          </FormControl>
                          <FormHelperText>
                            Select the Stripe plan(s) you want customers
                            subscribed to.
                          </FormHelperText>
                        </div>
                      ) : null}

                      {this.state.activeStep === 2 ? (
                        <div>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.enableDiscount}
                                  onChange={() =>
                                    this.setState({
                                      enableDiscount: !this.state.enableDiscount
                                    })
                                  }
                                  value=""
                                  color="primary"
                                />
                              }
                              label="Allow coupon field?"
                            />
                          </div>
                          <div>
                            {this.state.type !== "onetime" ? (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.enableTaxes}
                                    onChange={() =>
                                      this.setState({
                                        enableTaxes: !this.state.enableTaxes
                                      })
                                    }
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Enable Taxes"
                              />
                            ) : null}

                            {this.state.enableTaxes ? (
                              <TextField
                                label="Tax Percentage"
                                fullWidth
                                helperText="Tax percentage you want applied to the subscription."
                                value={this.state.tax_percentage}
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    step: "any",
                                    min: 0
                                  },
                                  startAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  )
                                }}
                                className={classes.textField}
                                onChange={this.handleChange("tax_percentage")}
                              />
                            ) : null}
                          </div>

                          <div>
                            {this.state.type !== "onetime" ? (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.enableAnchorDate}
                                    onChange={() =>
                                      this.setState({
                                        enableAnchorDate: !this.state
                                          .enableAnchorDate
                                      })
                                    }
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Enable Anchor Date"
                              />
                            ) : (
                              ""
                            )}
                            {this.state.enableAnchorDate ? (
                              <div>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.prorated}
                                      onChange={() =>
                                        this.setState({
                                          prorated: !this.state.prorated
                                        })
                                      }
                                      value=""
                                      color="primary"
                                    />
                                  }
                                  label="Prorate until Anchor"
                                />
                                <FormControl className={classes.formControl}>
                                  <InputLabel>Anchor Date</InputLabel>
                                  <Select
                                    native
                                    value={this.state.date_number}
                                    className={classes.textField}
                                    onChange={this.handleChange("date_number")}
                                    inputProps={{
                                      name: "date_number",
                                      id: "date_number"
                                    }}
                                  >
                                    {potentialDays}
                                  </Select>
                                  <FormHelperText>
                                    Sets billing to renew on this date.
                                  </FormHelperText>
                                </FormControl>
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.enableTerms}
                                  onChange={() =>
                                    this.setState({
                                      enableTerms: !this.state.enableTerms
                                    })
                                  }
                                  value=""
                                  color="primary"
                                />
                              }
                              label="Enable Terms"
                            />
                            {this.state.enableTerms ? (
                              <TextField
                                label="Terms and Conditions"
                                fullWidth
                                helperText="URL of your terms and conditions"
                                value={this.state.termsLink}
                                type="text"
                                className={classes.textField}
                                onChange={this.handleChange("termsLink")}
                              />
                            ) : null}
                          </div>
                          <div>
                            {this.state.type !== "onetime" ? (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.enableSetupFee}
                                    onChange={() =>
                                      this.setState({
                                        enableSetupFee: !this.state
                                          .enableSetupFee
                                      })
                                    }
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Enable Setup Fee"
                              />
                            ) : null}
                            {this.state.enableSetupFee ? (
                              <TextField
                                label="Setup Fee Amount"
                                fullWidth
                                required
                                value={this.state.setup_fee}
                                helperText="Amount you want to charge once, upon signup."
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    step: "any",
                                    min: 0
                                  },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {getSymbolFromCurrency(
                                        this.state.currency
                                      ) || "$"}
                                    </InputAdornment>
                                  )
                                }}
                                className={classes.textField}
                                onChange={this.handleChange("setup_fee")}
                              />
                            ) : null}
                          </div>

                          <div>
                            {this.state.type !== "onetime" ? (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.enableInstallment}
                                    onChange={() =>
                                      this.setState({
                                        enableInstallment: !this.state
                                          .enableInstallment
                                      })
                                    }
                                    value=""
                                    color="primary"
                                  />
                                }
                                label="Enable Installment Plan"
                              />
                            ) : null}
                            {this.state.enableInstallment ? (
                              <TextField
                                label="Installment Total"
                                fullWidth
                                required
                                value={this.state.installment_total}
                                helperText="Amount you want to collect before canceling."
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    step: "any",
                                    min: 0
                                  },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {getSymbolFromCurrency(
                                        this.state.currency
                                      ) || "$"}
                                    </InputAdornment>
                                  )
                                }}
                                className={classes.textField}
                                onChange={this.handleChange(
                                  "installment_total"
                                )}
                              />
                            ) : null}
                          </div>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.hide_address}
                                  onChange={() =>
                                    this.setState({
                                      hide_address: !this.state.hide_address
                                    })
                                  }
                                  value=""
                                  color="primary"
                                />
                              }
                              label="Hide Address Fields"
                            />
                          </div>

                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.custom_logo}
                                  onChange={() =>
                                    this.setState({
                                      custom_logo: !this.state.custom_logo
                                    })
                                  }
                                  value=""
                                  color="primary"
                                />
                              }
                              label="Custom Logo"
                            />
                            {this.state.custom_logo ? (
                              <TextField
                                label="Logo URL"
                                fullWidth
                                helperText="A url for a specific logo for this form. By default we use the one uploaded in settings > branding."
                                value={this.state.custom_logo_url}
                                type="text"
                                className={classes.textField}
                                onChange={this.handleChange("custom_logo_url")}
                              />
                            ) : null}
                          </div>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.custom_labels}
                                  onChange={() =>
                                    this.setState({
                                      custom_labels: !this.state.custom_labels
                                    })
                                  }
                                  value=""
                                  color="primary"
                                />
                              }
                              label="Custom Labels"
                            />
                            {this.state.custom_labels ? (
                              <div>
                                <TextField
                                  label="First Name"
                                  fullWidth
                                  helperText="New text for the label First Name."
                                  value={this.state.custom_label_first_name}
                                  type="text"
                                  className={classes.textField}
                                  onChange={this.handleChange(
                                    "custom_label_first_name"
                                  )}
                                />
                                <TextField
                                  label="Last Name"
                                  fullWidth
                                  helperText="New text for the label Last Name."
                                  value={this.state.custom_label_last_name}
                                  type="text"
                                  className={classes.textField}
                                  onChange={this.handleChange(
                                    "custom_label_last_name"
                                  )}
                                />
                                {this.state.type === "donation" ? (
                                  <div>
                                    <TextField
                                      label="Donation Tab 1"
                                      fullWidth
                                      helperText="New text for the One Time Donation tab."
                                      value={
                                        this.state.custom_label_donation_tab_1
                                      }
                                      type="text"
                                      className={classes.textField}
                                      onChange={this.handleChange(
                                        "custom_label_donation_tab_1"
                                      )}
                                    />
                                    <TextField
                                      label="Donation Tab 2"
                                      fullWidth
                                      helperText="New text for the Recurring Donation tab."
                                      value={
                                        this.state.custom_label_donation_tab_2
                                      }
                                      type="text"
                                      className={classes.textField}
                                      onChange={this.handleChange(
                                        "custom_label_donation_tab_2"
                                      )}
                                    />
                                    <TextField
                                      label="One Time Helper Text"
                                      fullWidth
                                      helperText="New helper text for the One Time dropdown."
                                      value={
                                        this.state
                                          .custom_label_donation_helper_text_1
                                      }
                                      type="text"
                                      className={classes.textField}
                                      onChange={this.handleChange(
                                        "custom_label_donation_helper_text_1"
                                      )}
                                    />
                                    <TextField
                                      label="Recurring Helper Text"
                                      fullWidth
                                      helperText="New helper text for the Recurring dropdown."
                                      value={
                                        this.state
                                          .custom_label_donation_helper_text_2
                                      }
                                      type="text"
                                      className={classes.textField}
                                      onChange={this.handleChange(
                                        "custom_label_donation_helper_text_2"
                                      )}
                                    />

                                    <TextField
                                      label="Checkout Button"
                                      fullWidth
                                      helperText="Submit button text."
                                      value={
                                        this.state.custom_label_checkout_button
                                      }
                                      type="text"
                                      className={classes.textField}
                                      onChange={this.handleChange(
                                        "custom_label_checkout_button"
                                      )}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}

                      {this.state.activeStep === 3 ? (
                        <div>
                          {customFieldsArray}
                          <Button
                            color="primary"
                            onClick={this.addField()}
                            className={classes.button}
                          >
                            Add Field
                          </Button>
                        </div>
                      ) : null}

                      {this.state.activeStep === 4 ? (
                        <TextField
                          label="Redirect URL"
                          type="text"
                          required
                          value={this.state.redirect_url}
                          className={classes.textFieldHalf}
                          helperText="Customers will be redirected here upon completing the form."
                          onChange={this.handleChange("redirect_url")}
                        />
                      ) : null}

                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={this.handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>
                          {activeStep !== steps.length ? (
                            <Button
                              color="primary"
                              onClick={this.handleNext}
                              className={classes.button}
                            >
                              Next
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              className={classes.button}
                            >
                              Update Form
                            </Button>
                          )}
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          </form>
        </Paper>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
        >
          <MySnackbarContentWrapper
            variant={this.state.snackVariant}
            message={this.state.snackMessage}
            onClose={this.handleSnackClose}
          />
        </Snackbar>
      </NavBar2>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(UpdateForm);
