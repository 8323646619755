import React from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavBar2 from "../../components/navigation/NavBar2";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Input from "@material-ui/core/Input";
import { styles } from "./FromAddressesStyles.js";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Directions from "../../components/directions/Directions";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
import Modal from "@material-ui/core/Modal";

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 35;
  const left = 38;

  return {
    top: `35%`,
    left: `38%`,
    //transform: `translate(-${top}%, -${left}%)`,
  };
}

class FromAddresses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 10,
      notification_emails: [],
      searchValue: "",
      firstLoadAttempted: false,
    };
  }

  changeNav = (name) => (event) => {
    let path = `/${this.props.match.params.org}/${name}`;
    this.props.history.push(path);
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  addEmail = (event) => {
    event.preventDefault();
    var self = this;
    axios({
      method: "POST",
      url: `${apiBaseUrl}/v1/org/notifications/email/address/new`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        email: this.state.newFromAddress,
      },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            snackOpen: true,
            snackVariant: "success",
            snackMessage: `A verification email has been sent to ${self.state.newFromAddress}.`,
            modalOpen: false,
          });
          self.fetchOrg();
        }
      })
      .catch(function (error) {
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          activeStep: 0,
          snackMessage: error.response.data.message,
        });
      });
  };

  resendVerification = (email) => {
    var self = this;
    axios({
      method: "POST",
      url: `${apiBaseUrl}/v1/org/notifications/email/address/resend`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        email: email,
      },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            snackOpen: true,
            snackVariant: "success",
            snackMessage: `A verification email has been sent to ${email}.`,
            modalOpen: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          activeStep: 0,
          snackMessage: error.response.data.message,
        });
      });
  };

  refreshVerification = (event) => {
    var self = this;
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/v1/org/notifications/email/address/update`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        email: "",
      },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            snackOpen: true,
            snackVariant: "success",
            snackMessage: `Verification status have been refreshed.`,
            modalOpen: false,
          });
          self.fetchOrg();
        }
      })
      .catch(function (error) {
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          activeStep: 0,
          snackMessage: error.response.data.message,
        });
      });
  };

  fetchOrg = () => {
    var self = this;
    console.log("templateId", self.props.match.params.templateId);
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/me`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            notification_emails: response.data.notification_emails,
            firstLoadAttempted: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({
          messageError: "Error retrieving forms",
          firstLoadAttempted: true,
        });
      });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  componentDidMount(event) {
    console.log(this.state);
    this.fetchOrg();
  }

  render() {
    const { classes, ...other } = this.props;
    const addresses = [];
    console.log(this.state);

    for (let address of this.state.notification_emails) {
      addresses.push(
        <TableRow key={address._id}>
          <TableCell>{address.email}</TableCell>
          <TableCell>{address.verification_status}</TableCell>
          <TableCell>
            {address.verification_status !== "Success" ? (
              <div>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => this.resendVerification(address.email)}
                >
                  Resend Verfication
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={this.refreshVerification}
                >
                  Refresh Verification Status
                </Button>
              </div>
            ) : (
              <Button size="small" variant="outlined" color="primary" disabled>
                Verified
              </Button>
            )}
          </TableCell>
        </TableRow>
      );
    }

    return (
      <NavBar2 header="Notifications" {...this.props}>
        <Directions
          message={`Add new email addresses to send emails from here.`}
        />
        {this.state.firstLoadAttempted ? (
          <div>
            <div className={classes.toolbar} />

            <Paper>
              <div>
                <AppBar position="static">
                  <Toolbar>
                    <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.grow}
                    >
                      Verify emails to send from here.
                    </Typography>
                    <Button
                      variant="outlined"
                      color="inherit"
                      onClick={this.handleModalOpen}
                    >
                      Add an Email
                    </Button>
                  </Toolbar>
                </AppBar>
              </div>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell>Verification Status</TableCell>
                    <TableCell>Resend</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{addresses}</TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>Page {this.state.page}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
            <Modal open={this.state.modalOpen} onClose={this.handleModalClose}>
              <div
                style={{
                  top: `35%`,
                  left: `37%`,
                }}
                className={classes.paper}
              >
                <form onSubmit={this.addEmail}>
                  <TextField
                    variant="outlined"
                    label="Email Address"
                    helperText="The email address you want to send emails from."
                    fullWidth
                    value={this.state.newFromAddress}
                    onChange={this.handleChange("newFromAddress")}
                    style={{
                      paddingBottom: 20,
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    className={classes.button}
                    type="submit"
                  >
                    Add Email
                  </Button>
                </form>
              </div>
            </Modal>
          </div>
        ) : (
          <Grid container justify={"center"}>
            <div className={classes.loader}>
              <CircularProgress size={100} className={classes.progress} />
            </div>
          </Grid>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
        >
          <MySnackbarContentWrapper
            variant={this.state.snackVariant}
            message={this.state.snackMessage}
            onClose={this.handleSnackClose}
          />
        </Snackbar>
      </NavBar2>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(FromAddresses);
