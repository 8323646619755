import ProductsSelect from "../../components/ProductsSelect";
import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  CssBaseline,
  CircularProgress,
  Backdrop,
  Container,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Button,
  Paper,
  Tooltip,
  Switch,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { NextWeek } from "@material-ui/icons";
import { fetchCheckout } from "../../apirequests/checkouts";
import NavBar2 from "../../components/navigation/NavBar2";
import UpdateCheckout from "./updateCheckout";
const baseUrl =
  process.env.REACT_APP_BASE_URL || process.env.REACT_APP_DEV_ENDPOINT;
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formHeader: { marginBottom: theme.spacing(2) },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function CreateCheckout() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  let [checkoutForm, setCheckoutForm] = useState({
    mode: "subscription",
    submitType: "auto",
    prices: [],
    allowDiscountCodes: true,
    billingAddressCollection: false,
    cancelUrl: id ? `https://forms.instapayments.io/checkout/${id}` : "",
    successUrl: "https://forms.instapayments.io/success",
  });
  let [plans, setPlans] = useState([]);
  let [org, setOrg] = useState(null);
  let [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const fetchCheckoutRequest = async () => {
    await fetchMe();
    let { data: checkout } = await fetchCheckout(id);
    console.log(checkout);
    checkout.prices = checkout.line_items.map((item) => item.price);
    checkout.successUrl = checkout.success_url;
    checkout.cancelUrl = checkout.cancel_url;
    checkout.submitType = checkout.submit_type;
    checkout.allowDiscountCodes = checkout.allow_promotion_codes;
    console.log("FETCHED CHECKOUT", { checkout });
    setCheckoutForm(checkout);
  };

  const fetchMe = async () => {
    let response = await axios({
      method: "get",
      url: `${baseUrl}/v1/org/me`, //retrieves the current authenticated user
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
    setOrg(response.data);
    setCheckoutForm({
      ...checkoutForm,
      cancelUrl: org?.account.business_url,
    });
  };

  useEffect(() => {
    if (id) {
      fetchCheckoutRequest();
    }
    fetchMe();
  }, []);
  let handleCreateCheckout = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      console.log({
        ...checkoutForm,
        lineItems: checkoutForm.prices.map((price) => ({
          price: price,
          quantity: 1,
        })),
      });
      let token = localStorage.getItem("token");
      let request = await axios({
        method: "post",
        url: `${baseUrl}/v2/org/checkouts`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...checkoutForm,
          lineItems: checkoutForm.prices.map((price) => ({
            price: price,
            quantity: 1,
          })),
        },
      });
      console.log({ createdCheckout: request.data, ...checkoutForm });
      navigate("/stripecheckouts");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err?.response?.data, err?.response?.status);
      enqueueSnackbar(
        err?.response?.data?.message || "Error creating checkout.",
        {
          variant: "error",
        }
      );
    }
  };
  let handleUpdateCheckout = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    console.log("UPDATING CHECKOUT", { checkoutForm });
    try {
      let token = localStorage.getItem("token");
      let request = await axios({
        method: "put",
        url: `${baseUrl}/v2/org/checkouts/${id}?livemode=true`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...checkoutForm,
          lineItems: checkoutForm.prices.map((item) => ({
            price: item,
            quantity: 1,
          })),
        },
      });
      console.log({ createdCheckout: request.data, ...checkoutForm });
      setIsLoading(false);
      navigate("/stripecheckouts");
    } catch (err) {
      setIsLoading(false);
      console.log(err?.response?.data?.error, err?.response?.status || err);
      enqueueSnackbar(
        err?.response?.data?.message || "Error creating checkout.",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }
      );
    }
  };
  let handleCheckoutSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (id) {
      handleUpdateCheckout(e);
    } else {
      handleCreateCheckout(e);
    }
  };
  return (
    <NavBar2
      pageName={id ? "Update Checkout" : "Create Checkout"}
      pageDescription="Create a new Checkout."
    >
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography
            className={classes.formHeader}
            component="h1"
            variant="h5"
          >
            {id ? "Update Checkout" : "Create New Checkout"}
          </Typography>
          <form onSubmit={handleCheckoutSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Internal Nickname"
                  disabled={isLoading}
                  value={checkoutForm.nickname}
                  InputLabelProps={{ shrink: true }}
                  placeholder="A name to help you remember what it's used for"
                  onChange={(e) =>
                    setCheckoutForm({
                      ...checkoutForm,
                      nickname: e.target.value.trim(),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Tooltip
                    title="Select the type of checkout you want to create below."
                    placement="right"
                    arrow
                  >
                    <FormLabel component="legend">Checkout Mode</FormLabel>
                  </Tooltip>
                  <RadioGroup
                    aria-label="mode"
                    name="mode"
                    value={checkoutForm.mode}
                    required
                    onChange={(e) =>
                      setCheckoutForm({
                        ...checkoutForm,
                        mode: e.target.value,
                      })
                    }
                  >
                    {checkoutModes.map(({ value, label }) => (
                      <FormControlLabel
                        value={value}
                        disabled={isLoading}
                        control={<Radio color="primary" />}
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              {checkoutForm.mode !== "setup" && (
                <Grid item xs={12}>
                  <ProductsSelect
                    onProductsLoaded={(products) =>
                      console.log("PRODUCT LOADED", products)
                    }
                    type={
                      checkoutForm.mode === "payment"
                        ? "one_time"
                        : checkoutForm.mode === "subscription"
                        ? "recurring"
                        : "all"
                    }
                    onChange={(e) =>
                      setCheckoutForm({
                        ...checkoutForm,
                        prices: e.target.value,
                      })
                    }
                    value={checkoutForm.prices}
                  />
                </Grid>
              )}
              {checkoutForm.mode === "payment" && (
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <Tooltip
                      title="This changes verbage on the checkout page based on your use case"
                      placement="right"
                      arrow
                    >
                      <FormLabel component="legend">Submit Type</FormLabel>
                    </Tooltip>
                    <RadioGroup
                      aria-label="mode"
                      name="mode"
                      row
                      value={checkoutForm.submitType}
                      onChange={(e) =>
                        setCheckoutForm({
                          ...checkoutForm,
                          submitType: e.target.value,
                        })
                      }
                    >
                      {submitTypes.map(({ value, label }) => (
                        <FormControlLabel
                          value={value}
                          disabled={isLoading}
                          control={<Radio color="primary" />}
                          label={label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
              {checkoutForm.mode !== "setup" && (
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Tooltip
                      title="Users can enter your promo codes to get discounts."
                      placement="right"
                      arrow
                    >
                      <FormLabel component="legend">
                        Allow Discount Codes
                      </FormLabel>
                    </Tooltip>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={checkoutForm.allowDiscountCodes}
                          onChange={(e) =>
                            setCheckoutForm({
                              ...checkoutForm,
                              allowDiscountCodes: e.target.checked,
                            })
                          }
                          name="allowDiscountCodes"
                        />
                      }
                      label=""
                    />
                  </FormControl>
                </Grid>
              )}
              {checkoutForm.mode !== "setup" && (
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Tooltip
                      title="Stripe will auto collect when needed if disabled."
                      placement="right"
                      arrow
                    >
                      <FormLabel component="legend">
                        Collect Billing Address
                      </FormLabel>
                    </Tooltip>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={checkoutForm.billingAddressCollection}
                          onChange={(e) =>
                            setCheckoutForm({
                              ...checkoutForm,
                              billingAddressCollection: e.target.checked,
                            })
                          }
                          name="billingAddressCollection"
                        />
                      }
                      label=""
                    />
                  </FormControl>
                </Grid>
              )}
              {checkoutForm.mode === "subscription" && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Trial Period"
                    placeholder="0"
                    helperText="The number of days in the subscription's trial period."
                    disabled={isLoading}
                    onChange={(e) =>
                      setCheckoutForm({
                        ...checkoutForm,
                        trialPeriodDays: e.target.value,
                      })
                    }
                    value={checkoutForm.trialPeriodDays}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Success Url"
                  required
                  placeholder="The URL to send customers when the checkout is complete."
                  disabled={isLoading}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    setCheckoutForm({
                      ...checkoutForm,
                      successUrl: e.target.value,
                    })
                  }
                  value={checkoutForm.successUrl}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Cancel Url"
                  required
                  placeholder="The URL they are directed to if they abandon the checkout."
                  disabled={isLoading}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    setCheckoutForm({
                      ...checkoutForm,
                      cancelUrl: e.target.value,
                    })
                  }
                  value={checkoutForm.cancelUrl}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  {id ? "Update Checkout" : "Create Checkout"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </NavBar2>
  );
}

const submitTypes = [
  {
    value: "auto",
    label: "Auto",
  },
  {
    value: "pay",
    label: "Pay",
  },
  {
    value: "book",
    label: "Book",
  },
  {
    value: "donate",
    label: "Donate",
  },
];

const checkoutModes = [
  {
    value: "payment",
    label: "Payment - One time charge",
  },
  {
    value: "setup",
    label: "Setup - Collect and save a card for later",
  },
  {
    value: "subscription",
    label: "Subscription - Collect a card and subscribe them to a plan",
  },
];

export default CreateCheckout;
