import React from 'react';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './RecurringInstructionsPageStyles.js';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NavBar2 from '../../components/navigation/NavBar2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Directions from '../../components/directions/Directions';
import moment from 'moment';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";




class RecurringInstructions extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 10,
      recurringInstructions:[],
      firstLoadAttempted: false
    };
  }

   changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`
    this.props.history.push(path);
 };

 handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
};

componentDidMount(){
  console.log(this.state)
  this.fetchRecurringInstructions(1, 10);
}

nextPage = (event) => {
  this.fetchRecurringInstructions(Number(this.state.page)+1, Number(this.state.rowsPerPage))
  this.setState({
  page: this.state.page+1
  });
}

previousPage = (event) => {
  this.fetchRecurringInstructions(Number(this.state.page)-1, Number(this.state.rowsPerPage))
  this.setState({
  page: this.state.page-1
  });
}
fetchRecurringInstructions = (page, per_page)=>{
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/org/recurringinstructions?page=${page}&per_page=${per_page}`,
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      self.setState({
      recurringInstructions: response.data,
      finished: true,
      firstLoadAttempted: true
      });
    }})
  .catch(function (error) {
    console.log(error);
    self.setState({
      messageError:"Error retrieving forms",
      firstLoadAttempted: true
    })
  });
}

render() {
  const { classes, ...other } = this.props;
  const recurringInstructions = [];
  for (let recurringInstruction of this.state.recurringInstructions) {
    recurringInstructions.push(
      <TableRow key={recurringInstruction._id}>
        <TableCell >{recurringInstruction.name}</TableCell>
        <TableCell >{recurringInstruction.plan_ids + " "}</TableCell>
        <TableCell >{recurringInstruction.redirect_url}</TableCell>
        <TableCell >{moment(new Date(recurringInstruction.createdAt)).format('MM/DD/YYYY')}</TableCell>
        <TableCell >

        <Button size="small" variant="outlined" color="primary" href={`/chargeinstruction/recurring/snippet/${recurringInstruction._id}`}>Preview/Copy</Button>

        <Button size="small" variant="outlined" color="primary" href={`/chargeinstruction/update/recurring/${recurringInstruction._id}`} >
        Update
        </Button>
        </TableCell>
      </TableRow>
    )
  }

    return (
      <NavBar2 header="Recurring Embedded Checkout" {...other}>
      <Directions
      message="Your Recurring embedded checkouts are listed here. To change what happens when it's submitted click Update. To change the way it looks, click Preview/Copy."
      />
      {this.state.firstLoadAttempted ?
        <div>
        { recurringInstructions.length > 0 ?
          <Paper>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell >Name</TableCell>
                <TableCell >Plan(s)</TableCell>
                <TableCell >Redirect Url</TableCell>
                <TableCell >Created At</TableCell>
                <TableCell >View/Edit</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>

            {recurringInstructions}

            </TableBody>
            <TableFooter>
         <TableRow>
         <TableCell>
           Page {this.state.page}
         </TableCell>
         <TableCell/>
        <TableCell/>
        <TableCell/>
           <TableCell>
             <Button
             size="small"
             variant="outlined"
             color="primary"
             onClick={(event) => this.previousPage(event)}
             className={classes.paginationButton}
             disabled={this.state.page <= 1}>
             Previous
             </Button>
             <Button
             size="small"
             variant="outlined"
             color="primary"
             onClick={(event) => this.nextPage(event)}
             className={classes.paginationButton}
             disabled={this.state.recurringInstructions.length < 8}>
             Next
             </Button>
           </TableCell>
         </TableRow>
       </TableFooter>
          </Table>
        </Paper>
        :
        <div>
        <Grid container justify={"center"}>
        <Card className={classes.card}>
   <CardActionArea>
     <CardMedia
       className={classes.media}
       image="/static/images/cards/contemplative-reptile.jpg"
       title="Contemplative Reptile"
     />
     <CardContent>
       <Typography gutterBottom variant="headline" component="h2">
         You don't have any recurring Embedded Checkouts yet!
       </Typography>
       <Typography component="p">
         Embedded Checkouts remove the server side work when using a Stripe button. <a href="https://intercom.help/instapayments/feature-descriptions/stripe-post-urls" target="_blank" rel="noopener noreferrer">See this article for more info.</a>
       </Typography>
     </CardContent>
   </CardActionArea>
   <CardActions>
     <Button
     variant="contained"
     color="primary"
     href="/chargeinstruction/new"
     >
       Create an Embedded Checkout
     </Button>
   </CardActions>
 </Card>
 </Grid>
 </div>
        }
        </div>
        :
        <Grid container justify={"center"}>
        <div className={classes.loader}>
        <CircularProgress size={100} className={classes.progress} />
        </div>
        </Grid>
      }
        <Button
        variant="fab"
        color="primary"
        aria-label="Add"
        href="/chargeinstruction/new"
        className={classes.fab}
        >
          <AddIcon />
        </Button>
      </NavBar2>
    );
  }
}

export default withStyles(styles)(RecurringInstructions);
