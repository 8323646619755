import Paper from "@material-ui/core/Paper";
import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import NotFoundImage from "../../images/404.svg";
import { Typography } from "@material-ui/core";
const styles = (theme) => ({
  root: {
    marginTop: 15,
    width: "50%",
    marginLeft: "25%",
  },
  background: {
    //backgroundColor: "grey"
  },
  paper: theme.mixins.gutters({
    paddingTop: 20,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3,
  }),
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  textFieldHalf: {
    marginLeft: theme.spacing.unit * 0.5,
    marginRight: theme.spacing.unit * 0.5,
    width: "48%",
  },
  button: {
    //width:00,
    margin: theme.spacing.unit,
  },
  bigAvatar: {
    width: 100,
    height: 100,
  },
  menu: {
    width: 100,
  },
});

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "test",
      password: "",
      messageError: "",
      messageSuccess: "",
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClick(event) {
    let path = `/dashboard`;
    this.props.history.push(path);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.background}>
        <Grid container className={classes.root} justify={"center"}>
          <Grid container justify={"center"}>
            <img src={NotFoundImage} alt="404" />
          </Grid>
          <Grid container justify={"center"}>
            <Typography variant="h5">Page Not Found</Typography>
          </Grid>

          <Grid container justify={"center"}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              href="/dashboard"
            >
              Ride on back to the Dashboard
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(NotFound);
